import { Box, Flex, Text } from "@chakra-ui/react"
import { SiteTemplateProps } from "../component/comparison-template/comparison-template"
import { BarGraph } from "./component/bar-graph"
import { UaTable } from "./component/ua-table"

export const ComparisonInsulationPerformance = (props: SiteTemplateProps) => {
  const {
    param,
    data,
  } = props;
  if (!param?.insulation_performance.value) return <></>;
  return (
    <Box bg="#fff" p="0.5rem 1rem" pos="relative">
      <Box lineHeight="1.5rem" fontSize="large" fontWeight="bold" pos="absolute">
        <Text>断熱性能</Text>
        <Text>等級</Text>
      </Box>
      <Box pb="4rem">
        <Flex height="120px" gap="1rem" mt="0.5rem">
          <BarGraph index={1} grade={data?.grade ?? 0} />
          <BarGraph index={2} grade={data?.grade ?? 0} />
          <BarGraph index={3} grade={data?.grade ?? 0} />
          <BarGraph index={4} grade={data?.grade ?? 0} />
          <BarGraph index={5} grade={data?.grade ?? 0} />
          <BarGraph index={6} grade={data?.grade ?? 0} />
          <BarGraph index={7} grade={data?.grade ?? 0} />
        </Flex>
      </Box>
      {!!param?.insulation_performance.ua_value && <UaTable data={data} />}
    </Box>
  )
}