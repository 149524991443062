import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiErrorDialog } from '../../../../../hooks/utile/use-error-dialog';
import { Api } from "../../../../../module/api";
import RootStore from "../../../../../store/root.store";
import { useMsp } from "../../../../layout/hooks/use-msp";
import { FileDropDialog } from "../../../../ui/dialog/file-drop-dialog/file-drop-dialog";
import { TextAlign } from "../component/csv-table";

export const useMspConfig = () => {
  const { articleCode } = useParams();
  const errDialog = useApiErrorDialog();
  const { energyData } = RootStore.msp;
  const { getEnergy, getStatus } = useMsp();
  const subHeader = useMemo(() => {
    return [
      { label: 'MJ/戸' },
      { label: 'GJ/戸' },
      { label: 'MJ/戸' },
      { label: 'GJ/戸' },
      { label: '一般' },
      { label: '本住宅' },
    ]
  }, []);
  const header = useMemo(() => {
    return [
      { label: '階数', rowSpan: 2 },
      { label: '住戸タイプ', rowSpan: 2 },
      { label: '住戸番号', rowSpan: 2 },
      { label: '計算値(戸/年)', colSpan: 2 },
      { label: '一次エネ消費量(年)', colSpan: 2 },
      { label: 'CO2排出量', rowSpan: 2 },
      { label: '光熱費', colSpan: 2 },
      { label: 'BEI', rowSpan: 2 },
      { label: 'BELS', rowSpan: 2 },
    ]
  }, []);

  const body = useMemo(() => {
    return energyData?.map((v) => {
      const bel = Array.from({ length: (v.bels ?? 0) }, () => '☆').join('');
      return [
        { elm: v.floor_number ?? '', align: 'center' as TextAlign },
        { elm: v.dwelling_unit_type ?? '', align: 'center' as TextAlign },
        { elm: v.dwelling_unit_number?.toLocaleString() ?? '', align: 'right' as TextAlign },
        { elm: v.calculated_value_mj?.toLocaleString() ?? '', align: 'right' as TextAlign },
        { elm: v.calculated_value_gj?.toLocaleString() ?? '', align: 'right' as TextAlign },
        { elm: v.primary_energy_consumption_quantity_mj?.toLocaleString() ?? '', align: 'right' as TextAlign },
        { elm: v.primary_energy_consumption_quantity_gj?.toLocaleString() ?? '', align: 'right' as TextAlign },
        { elm: v.co2_discharge_quantity?.toLocaleString() ?? '', align: 'right' as TextAlign },
        { elm: v.utility_costs_general?.toLocaleString() ?? '', align: 'right' as TextAlign },
        { elm: v.utility_costs_primary?.toLocaleString() ?? '', align: 'right' as TextAlign },
        { elm: v.bei?.toLocaleString() ?? '', align: 'right' as TextAlign },
        { elm: bel, align: 'left' as TextAlign },
      ]
    }) ?? [];
  }, [energyData]);
  const [file, setFile] = useState<File | null>(null);
  const fileLabel = useMemo(() => {
    if (!RootStore.msp.energyData) return '基本設定ファイルを選択してください'
    return file?.name ?? '';
  }, [file]);
  const onClickFileLoad = useCallback(() => {
    RootStore.dialog.push({
      title: 'ファイル読み込み',
      children: (
        <FileDropDialog
          file={{ accept: '.csv' }}
          postCallback={async(file) => {
            if (!articleCode) return;
            try {
              await Api.connect().properties(articleCode).energy().add({ file });
              await getStatus(articleCode);
              await getEnergy(articleCode);
              setFile(file);
              RootStore.dialog.pushMessage({
                title: '完了',
                messages: ['基本設定ファイルの登録が完了しました'],
                buttons: [{ label: 'OK', callback: () => RootStore.dialog.clear() }],
              });
            } catch(e: any) {
              errDialog.push(e);
              return Promise.reject(e);
            }
          }}
          callback={() => {
            if (articleCode) {
              getEnergy(articleCode).catch((e) => errDialog.push(e));
            }
          }}
        />
      ),
    });
  }, [
    errDialog,
    articleCode,
    getEnergy,
    getStatus,
  ]);


  return {
    tableProps: {
      header,
      subHeader,
      body,
    },
    onClickFileLoad,
    fileLabel,
  }
}