import API from "../..";
import { fullUrl } from "../../utile/utile.api";

export type UserData = {
  company: {
    name: string,
    system_admin_flag: boolean,
  }
}

const path = '/is-login';

const isLogin = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    get: () => API.con.get<{}, UserData>({ url: full, data: {} }),
  })
}

export default isLogin;