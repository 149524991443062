import { AxiosError } from 'axios';
import { useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { Api } from '../../../module/api/index';
import { RoutingPath } from '../../../routes/routing-path';
import RootStore from '../../../store/root.store';
import { MspColorModule } from "../../pages/msp/color-setting/collection/template-color.collection";

export const useMsp = () => {
  const navigate = useNavigate();
  const { dialog, msp } = RootStore;
  
  const getStatus = useCallback((articleId: string) => new Promise<void>((resolve, reject) => {
    Api.connect().properties(articleId).getStatus().then((res) => {
      RootStore.msp.setStatus(res.data.status);
      resolve();
    }).catch((e) => {
      reject(e);
    })
  }), []);
  
  const getEnergy = useCallback((articleId: string): Promise<void> => new Promise((resolve, reject) => {
    Api.connect().properties(articleId).energy().get().then((res) => {
      RootStore.msp.setEnergy(res.data.energy_saving_data);
      resolve();
    }).catch((e) => {
      reject(e);
      resolve();
    });
  }), []);
  const getCage = useCallback((articleId: string): Promise<void> => new Promise((resolve, reject) => {
    Api.connect().properties(articleId).cageDrawingData().get().then((res) => {
      RootStore.msp.setCage(res.data);
      resolve();
    }).catch((e) => {
      if (!msp.status && e?.response?.status === 400) {
        resolve();
      } else {
        reject(e);
      }
      resolve();
    });
  }), []);
  const getTemplates = useCallback((articleId: string): Promise<void> => new Promise((resolve, reject) => {
    Api.connect().properties(articleId).templates().get().then((res) => {
      RootStore.msp.setTemplates({
        ...res.data.template,
        template_id: res.data.template.id ?? '',
      });
      resolve();
    }).catch((e) => {
      reject(e);
      resolve();
    });
  }), []);

  const getColors = useCallback((articleId: string): Promise<void> => new Promise((resolve, reject) => {
    Api.connect().properties(articleId).colors().get().then((res) => {
      const colors = MspColorModule.initialColors(res.data.color);
      RootStore.msp.setColors(colors);
      resolve();
    }).catch((e) => {
      console.error(e);
      // reject(e);
      resolve();
    });
  }), []);

  const getLabels = useCallback((articleId: string): Promise<void> => new Promise((resolve, reject) => {
    Api.connect().properties(articleId).energySavingLabels().get().then((res) => {
      if (typeof res.data !== 'string') {
        RootStore.msp.setLabels(res.data);
      }
      resolve();
    }).catch((e) => {
      console.error(e);
      resolve();
    });
  }), []);

  const getArticle = useCallback((articleId: string) => {
    return Api.connect().properties(articleId).get();
  }, []);
  const initialize = useCallback(async(articleId: string) => {
    try {
      const article = await getArticle(articleId);
      RootStore.msp.setArticle(article.data.property);
    } catch(e) {
      if (!(e instanceof AxiosError)) {
        dialog.pushMessage({
          title: `エラー(CODE: ${500})`,
          messages: ['ネットワークエラーが発生しました。'],
          contentsPadding: '1rem 0.5rem',
          headerProps: {
            css: {
              '.modal_header_wrapper': {
                fontSize: '24px'
              }
            }
          },
          buttons: [
            { label: 'OK', callback: () => {
              navigate(RoutingPath.articles);
            } },
          ]
        });   
      } else {
        console.log(e);
        if (
          (e instanceof AxiosError)
          && e.response?.status === 401  
        ) {
          return;
        }
        dialog.pushMessage({
          id: 'get-msp-info-error',
          title: `エラー(CODE: ${e.response?.status ?? 500})`,
          messages: ['物件情報の取得に失敗しました。', '物件一覧ページに戻ります。'],
          contentsPadding: '1rem 0.5rem',
          headerProps: {
            css: {
              '.modal_header_wrapper': {
                fontSize: '24px'
              }
            }
          },
          buttons: [
            { label: 'OK', callback: () => {
              navigate(RoutingPath.articles);
            } },
          ]
        });
      }
      return;
    }

    const err = () => {
      dialog.pushMessage({
        title: `確認`,
        messages: ['初期化処理に失敗しました。', '物件一覧ページに戻ります。'],
        contentsPadding: '1rem 0.5rem',
        headerProps: {
          css: {
            '.modal_header_wrapper': {
              fontSize: '24px'
            }
          }
        },
        buttons: [
          { label: 'OK', callback: () => {
            navigate(RoutingPath.articles);
          }},
        ]
      })          
    }
    getStatus(articleId).then(() => {
      Promise.all([
        getEnergy(articleId),
        getCage(articleId),
        getTemplates(articleId),
        getLabels(articleId),
        getColors(articleId),
      ]).then(() => {  
        RootStore.msp.initialize();
      }).catch(() => {
        err();
      })
    }).catch(() => err());
  }, [
    getArticle,
    getEnergy,
    getCage,
    getTemplates,
    getColors,
    getLabels,
    dialog, navigate,
    getStatus,
  ]);

  return {
    initialize,
    getEnergy,
    getStatus,
    getCage,
    getTemplates,
    getColors,
    getLabels,
  }
}