import { useCallback, useState } from "react";
import { useDidMount } from '../../../../../hooks/utile/use-didmount';
import { CopyLink } from "../../../../pages/articles/component/copy-link";
import { Api } from '../../../../../module/api/index';
import { ArticleDetailDataEntity } from '../../../../../module/api/routes/properties/index';
import { useApiErrorDialog } from '../../../../../hooks/utile/use-error-dialog';

const dummy = (num: number) => ({
  id: `${num}`,
  company_id: `${num}`,
  company_name: `企業_${num}`,
  no: num,
  name: `物件_${num}`,
  created_at: '2024-01-01',
  update_at: '2024-04-01',
  public_end_date: '2025-04-01',
  is_public: num % 6 === 0 ? 0 : 1,
  sub_domain_name: 'sample',
  site_url: 'https://google.com/sample',
  is_deleted: num % 4 === 0 ? 1 : 0,
})

export const useArticleDetail = (id: string) => {
  const [info, setInfo] = useState<ArticleDetailDataEntity | null>(null);
  const errDialog = useApiErrorDialog();
  const CopyLinkComponent = () => {
    if (!info) return <>-</>;
    return <CopyLink href={info.site_url} target="_blank" tooltipPos="left" isPublic={info?.site_release_flag} />
  }

  const getInfo = useCallback(() => {
    Api.connect().properties(id).get().then((res) => {
      setInfo(res.data.property);
    }).catch((e) => {
      errDialog.push(e);
    })
  }, [id, errDialog]);

  useDidMount(() => {
    getInfo();
  })
  return {
    article: info,
    CopyLinkComponent,
  }
}