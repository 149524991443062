export const RoutingPath = {
  login: '/',
  companies: '/companies',
  articles: '/articles',
  mspConfig: '/msp/:articleCode/config',
  mspBasket: '/msp/:articleCode/basket',
  mspLabel: '/msp/:articleCode/label',
  mspTemplate: '/msp/:articleCode/template',
  mspColor: '/msp/:articleCode/color',
} as const;

export type SidebarDataEntity = {
  label: string,
  isAdmin?: boolean,
  children: {
    label: string,
    path: string,
  }[];
}

export const SidebarList: SidebarDataEntity[] = [
  {
    label: '企業管理',
    isAdmin: true,
    children: [
      { label: '企業一覧', path: RoutingPath.companies },
    ],
  },
  {
    label: '物件管理',
    children: [
      { label: '物件一覧', path: RoutingPath.articles },
    ]
  }
]

export type MspSidebarDataEntity = {
  label: string,
  children: {
    label: string,
    path: (articleCode: string) => string,
    index: number,
  }[],
}

export const replaceArticlePath = (articleCode: string, url: string) => url.replace(':articleCode', articleCode);

export const MspSidebarList: MspSidebarDataEntity =   {
  label: 'MSPサイト作成',
  children: [
    { label: '基本設定', path: (articleCode) => replaceArticlePath(articleCode, RoutingPath.mspConfig), index: 1 },
    { label: 'カゴ図設定', path: (articleCode) => replaceArticlePath(articleCode, RoutingPath.mspBasket), index: 2 },
    { label: '省エネラベル', path: (articleCode) => replaceArticlePath(articleCode, RoutingPath.mspLabel), index: 3 },
    { label: 'テンプレート', path: (articleCode) => replaceArticlePath(articleCode, RoutingPath.mspTemplate), index: 4 },
    { label: 'カラー設定', path: (articleCode) => replaceArticlePath(articleCode, RoutingPath.mspColor), index: 5 },
  ]
}

export const SiteRoutingPath = {
  cage: '/',
  comparison: '/comparison',
}
