import API from "../../..";
import { CageType } from "../../../../../component/pages/msp/basket/model/cage.type";
import { FileDataRequest } from "../../../utile/type";
import { fullUrl, json2FormForObject } from '../../../utile/utile.api';

export type CageDataResponse = {
  cage_drawing_data: CageType['cage_drawing_data'] | null,
  cage_drawing_comment: string,
}

const path = '/cage-drawing-data';

const cageDrawingData = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    get: () => API.con.get<{}, CageDataResponse>({ url: full, data: {} }),
    add: (param: FileDataRequest) => API.con.post<FormData, {}>({ url: full, data: json2FormForObject(param) }),
    update: (param: CageDataResponse) => API.con.put<CageDataResponse, {}>({ url: full, data: param}),
  })
}

export default cageDrawingData;
