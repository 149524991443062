import { Box, Flex, Img } from "@chakra-ui/react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useApiErrorDialog } from "../../../../../../../../hooks/utile/use-error-dialog"
import { ColorDarken } from "../../../../../../../../lib/color-utile"
import RootStore from "../../../../../../../../store/root.store"
import { useMsp } from "../../../../../../../layout/hooks/use-msp"
import iconEdit from '../../../../../assets/icon/icon_edit.svg'
import { Basket, MergeRoomData, RoomDataEntity } from "../../../../module/basket"

export type UseCageReturnType = {
  onSelectRoom: (roomData: RoomDataEntity) => void,
  roomStyle: (roomData: RoomDataEntity) => React.SVGProps<SVGPathElement>,
  mergeRoomStyle: (roomData: MergeRoomData) => React.SVGProps<SVGPathElement>,
  model: Basket | null;
  selectedRooms: string[],
}

export const usePreviewCage = ({ model }: { model: Basket | null }): UseCageReturnType => {
  const [selectedRooms, setSelectedRooms] = useState<string[]>([]);

  const roomStyle = useCallback<(roomData: RoomDataEntity) => React.SVGProps<SVGPathElement>>((roomData: RoomDataEntity) => {
    if (roomData.editableData.disabled && !roomData.isNonUnitRoom) {
      const fill =  RootStore.msp.colors?.cage_drawing_sharing_department_display_color ?? '#D9D9D9';
      return {
        fill: selectedRooms.includes(roomData.parentKey ?? roomData.key) 
          ? ColorDarken(fill, 5)
          : fill,
        stroke: '#F7FAFC'
      }  
    }
    if (roomData.isMerge) {
      return {
        fill: 'transparent',
      }
    }
    if (roomData.isNonUnitRoom) {
      const fill =  RootStore.msp.colors?.cage_drawing_sharing_department_display_color ?? '#D9D9D9';
      return {
        fill: selectedRooms.includes(roomData.parentKey ?? roomData.key) 
          ? ColorDarken(fill, 5)
          : fill,
      stroke: '#F7FAFC'
      }
    }
    const fill = RootStore.msp.colors?.cage_drawing_dwelling_unit_display_color ?? '#D9D9D9';
    const selectFill = RootStore.msp.colors?.cage_drawing_dwelling_unit_selection_color ?? '#A6A6A6';
    return {
      fill: selectedRooms.includes(roomData.parentKey ?? roomData.key) ? selectFill : fill,
      stroke: '#F7FAFC'
    }
  }, [selectedRooms]);
  const mergeRoomStyle = useCallback<(roomData: MergeRoomData) => React.SVGProps<SVGPathElement>>((roomData: MergeRoomData) => {
    if (roomData.isNonUnitRoom) {
      const fill =  RootStore.msp.colors?.cage_drawing_sharing_department_display_color ?? '#D9D9D9';
      return {
        fill: selectedRooms.includes(roomData.key) 
          ? ColorDarken(fill, 5)
          : fill,
        stroke: '#F7FAFC'
      }
    }
    const fill = RootStore.msp.colors?.cage_drawing_dwelling_unit_display_color ?? '#D9D9D9';
    const selectFill = RootStore.msp.colors?.cage_drawing_dwelling_unit_selection_color ?? '#A6A6A6';

    return {
      fill: selectedRooms.includes(roomData.key) ? selectFill : fill,
      stroke: '#F7FAFC'
    }
  }, [selectedRooms]);

  const onSelectRoom = useCallback((roomData: RoomDataEntity) => {
    if (roomData.isNonUnitRoom) return;
    const findIndex = selectedRooms.findIndex((v) => v === (roomData.parentKey ?? roomData.key));
    if (findIndex > -1) {
      selectedRooms.splice(findIndex, 1);
    } else {
      selectedRooms.push(roomData.parentKey ?? roomData.key);
    }
    setSelectedRooms([...selectedRooms]);
  }, [selectedRooms]);

  return {
    onSelectRoom,
    roomStyle,
    mergeRoomStyle,
    model,
    selectedRooms,
  }
}