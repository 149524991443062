import { useCallback, useMemo, useState } from "react";
import { useApiErrorDialog } from "../../../../../hooks/utile/use-error-dialog";
import { useParamState } from "../../../../../hooks/utile/use-param-state";
import { useValidation } from "../../../../../hooks/utile/use-validation";
import { Api } from "../../../../../module/api";
import { CompanyDetailDataEntity, initialEditCompanyState } from "../../../../../module/api/routes/companies";
import { InputUtile } from "../../../../../module/utile/input-rurules";
import RootStore from "../../../../../store/root.store";
import { CustomButton } from "../../../button/button";
import { Radio } from "../../../radio/radio";
import { useDidMount } from '../../../../../hooks/utile/use-didmount';

const useCompanyRadioList = [
  { value: '1', label: '利用中' },
  { value: '2', label: '利用停止' },
]

type Props = {
  getList: () => void,
  id: string,
}

export const useEditCompany = (props: Props) => {
  const {
    getList,
    id,
  } = props;

  const { dialog } = RootStore;
  const [request, setRequest] = useParamState(initialEditCompanyState, {
    password: InputUtile.eisu,
    responsible_person_email_address: InputUtile.email,
    responsible_person_phone_number1: InputUtile.numHyphen,
    responsible_person_phone_number2: InputUtile.numHyphen,
  });
  const [info, setInfo] = useState<null | CompanyDetailDataEntity>(null);
  const [load, setLoad] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const errDialog = useApiErrorDialog();
  const { validAll } = useValidation();
    
  const validation = useMemo(() => {
    const {
      name,
      name_kana,
      password,
      industry,
      address1,
      address2,
      responsible_person_department_sign,
      responsible_person_name,
      responsible_person_name_kana,
      responsible_person_phone_number1,
      responsible_person_phone_number2,
      responsible_person_email_address,
    } = request;
    return {
      name: validAll(name, 'require', 'length50'),
      name_kana: validAll(name_kana, 'require', 'length50', 'kana'),
      password: (!editPassword || !request.password) ? [] : validAll(password, 'length256', 'lengthOver8', 'password'),
      industry: validAll(industry, 'length50'),
      address1: validAll(address1, 'length256'),
      address2: validAll(address2, 'length256'),
      responsible_person_department_sign: validAll(responsible_person_department_sign, 'length50'),
      responsible_person_name: validAll(responsible_person_name, 'length60'),
      responsible_person_name_kana: validAll(responsible_person_name_kana, 'length60', 'kana'),
      responsible_person_phone_number1: validAll(responsible_person_phone_number1, 'phoneNumber'),
      responsible_person_phone_number2: validAll(responsible_person_phone_number2, 'phoneNumber'),
      responsible_person_email_address: validAll(responsible_person_email_address, 'mail'),
    }
  }, [request, validAll, editPassword]);

  const onClickSubmit = useCallback(() => {
    setLoad(true);
    const req = {
      ...request,
    }
    if (!editPassword || !request.password) {
      delete req.password;
    }
    Api.connect().companies(id).update(req)
    .then(() => {
      RootStore.dialog.pushMessage({
        messages: ['更新が完了しました。'],
        buttons: [
          { label: 'OK', callback: () => {
            getList();
            dialog.clear();
          }}
        ],
      })
    }).catch((e) => errDialog.push(e))
    .finally(() => setLoad(false));

  }, [id, request, errDialog, dialog, getList, editPassword]);


  const isDisabled = useMemo(() => {
    return !!Object.values(validation).flat().length;
  }, [validation]);


  const onClickCancel = useCallback(() => {
    dialog.pop();
  }, [dialog]);
  const RadioArea = () => {
    return (
      <Radio
        value={request.suspend_flag ? '2' : '1'}
        option={useCompanyRadioList}
        onChange={(v) => {
          setRequest({ suspend_flag: v !== '1' })}}
      />
    )
  }
  
  const Footer = () => {
    return (
      <>
        <CustomButton
          colorType="secondary"
          onClick={onClickCancel}
          isDisabled={load}
        >
          キャンセル
        </CustomButton>
        <CustomButton
          onClick={onClickSubmit}
          isDisabled={isDisabled || load}
        >
          OK
        </CustomButton>
      </>
    )
  }

  useDidMount(() => {
    setLoad(true);
    Api.connect().companies(id).getDetail().then((res) => {
      const { company } = res.data;
      setInfo(company);
      setRequest({
        name: company.name,
        name_kana: company.name_kana,
        industry: company.industry,
        prefecture: company.prefecture,
        address1: company.address1,
        address2: company.address2,
        responsible_person_department_sign: company.responsible_person_department_sign,
        responsible_person_name: company.responsible_person_name,
        responsible_person_name_kana: company.responsible_person_name_kana,
        responsible_person_phone_number1: company.responsible_person_phone_number1,
        responsible_person_phone_number2: company.responsible_person_phone_number2,
        responsible_person_email_address: company.responsible_person_email_address,
        suspend_date: company.suspend_date,
        suspend_flag: company.suspend_flag,
      })
    }).catch((e) => errDialog.push(e)
    ).finally(() => setLoad(false));
  });

  return {
    Footer,
    RadioArea,
    request,
    setRequest,
    validation,
    editPassword,
    setEditPassword,
    info,
  }
}