import API from "../../..";
import { fullUrl } from '../../../utile/utile.api';

export type MSPColorSettingDataEntity = {
  /** ○	カゴ図文字色 */
  cage_drawing_text_color: string;
  /** ○	カゴ図住戸表示色 */
  cage_drawing_dwelling_unit_display_color: string;
  /** ○	カゴ図住戸選択色 */
  cage_drawing_dwelling_unit_selection_color: string;
  /** ○	カゴ図共用部表示色 */
  cage_drawing_sharing_department_display_color: string;
  /** ○	省エネ文字色 */
  energy_saving_text_color: string;
  /** ○	省エネ帯色 */
  energy_saving_band_color: string;
}

type MspColorSettingResponse = {
  color: MSPColorSettingDataEntity,
}

const path = '/colors';

const colors = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    get: () => API.con.get<{}, MspColorSettingResponse>({ url: full, data: {} }),
    update: (param: MSPColorSettingDataEntity) => API.con.put<MSPColorSettingDataEntity, {}>({ url: full, data: param }),
  })
}

export default colors;
