import { useCallback, useMemo, useState } from "react";
import { useParamState } from "../../../../../hooks/utile/use-param-state";
import { useDidMount } from '../../../../../hooks/utile/use-didmount';
import { CustomButton } from "../../../button/button";
import RootStore from "../../../../../store/root.store";
import { ArticleDetailDataEntity } from "../../../../../module/api/routes/properties";
import { Api } from "../../../../../module/api";
import { useApiErrorDialog } from '../../../../../hooks/utile/use-error-dialog';
import { ReleaseRadioArea } from "../component/release-radio-area";
import { useValidation } from "../../../../../hooks/utile/use-validation";
import { InputUtile } from "../../../../../module/utile/input-rurules";

const initialRequest = {
  name: '',
  site_release_flag: false,
  site_release_end_expected_date: '',
  subdomain: '',
}

export const useArticleEdit = (id: string, isDelete: boolean, getList: () => void) => {
  const { dialog } = RootStore;
  const errDialog = useApiErrorDialog();
  const [request, setRequest] = useParamState({...initialRequest}, {
    subdomain: InputUtile.subdomain,
  });
  const { system_admin_flag } = RootStore.user;
  const { validAll } = useValidation();

  const validation = useMemo(() => {
    return {
      name: validAll(request.name, 'require', 'length40'),
      subdomain: validAll(request.subdomain, 'require', 'length63', 'subdomain', 'subdomain_reservation'),
      site_release_end_expected_date: system_admin_flag ? validAll(request.site_release_end_expected_date, 'require') : [],
    };
  }, [request, validAll, system_admin_flag])

  const isDisabled = useMemo(() => {
    return !!Object.values(validation).flat().length;
  }, [validation]);

  const [info, setInfo] = useState<ArticleDetailDataEntity | null>(null);
  const [load, setLoad] = useState(false);
  const [mspStatus, setMspStatus] = useState(false);
  
  const getInfo = useCallback(() => {
    setLoad(true);
    Api.connect().properties(id).get().then(async(res) => {
      const {
        property,
      } = res.data;
      try {
        const status = await Api.connect().properties(id).getStatus();
        setMspStatus(status.data.status === 2);
      } catch(e: any) {
        errDialog.push(e); 
      }
      setInfo(property);
      setRequest({
        name: property.name,
        site_release_flag: property.site_release_flag,
        site_release_end_expected_date: property.site_release_end_expected_date,
        subdomain: property.subdomain,
      });
      setLoad(false);
    }).catch((e) => {
      errDialog.push(e);
    })
  }, [id, setRequest, errDialog]);

  const today = useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  const radioToolTipLabel = useMemo(() => {
    if (!request) return '';
    if (!!info?.delete_flag) return '';
    if (!mspStatus) return 'MSPサイト作成が完了していません';
    if (!request?.site_release_end_expected_date) return '';
    // 日付チェック
    const endDate = new Date(request.site_release_end_expected_date);
    endDate.setHours(0, 0, 0, 0);
    if (endDate <= today) return '公開終了日が今日以前に設定されています';
    return ''
  }, [today, request, mspStatus, info]);

  const RadioArea = () => {
    return (
      <ReleaseRadioArea
        value={request.site_release_flag ? 1 : 0}
        onChange={(v) => setRequest({ site_release_flag: v })}
        isDisabled={isDelete}
        label={radioToolTipLabel}
      />
    )
  }

  /* 日付変更ハンドラ */
  const onChangeEndDate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setRequest({ site_release_end_expected_date: e.target.value });
      return;
    }
    const inputDate = new Date(e.target.value);
    inputDate.setHours(0, 0, 0, 0);
    if (inputDate <= today) {
      setRequest({
        site_release_end_expected_date: e.target.value,
        site_release_flag: false,
      });
    } else {
      setRequest({ site_release_end_expected_date: e.target.value });
    }
  }, [today, setRequest]);


  const onClickSubmit = useCallback(() => {
    setLoad(true);
    Api.connect().properties(id).update(request).then(() => {
      RootStore.dialog.pushMessage({
        messages: ['更新が完了しました。'],
        buttons: [
          { label: 'OK', callback: () => {
            getList();
            dialog.clear();
          }}
        ],
      })
    }).catch((e) => errDialog.push(e))
    .finally(() => setLoad(false));
  }, [id, getList, request, errDialog, dialog]);

  const onClickCancel = useCallback(() => {
    dialog.pop();
  }, [dialog]);

  const Footer = () => {
    return (
      <>
        <CustomButton
          colorType="secondary"
          onClick={onClickCancel}
          isDisabled={load}
        >
          キャンセル
        </CustomButton>
        <CustomButton
          onClick={onClickSubmit}
          isDisabled={load || info?.delete_flag || isDisabled}
        >
          OK
        </CustomButton>
      </>
    )
  }

  useDidMount(() => {
    getInfo();
  })
  return {
    info,
    request,
    setRequest,
    Footer,
    RadioArea,
    mspStatus,
    validation,
    onChangeEndDate,
  }
}