import { CompaniesHeaderItemDataEntity } from './../module/api/routes/companies/items/index';
import { makeObservable, observable, action } from 'mobx';
import { Api } from '../module/api';
import { CompanyListDataEntity } from '../module/api/routes/companies/index';

export type CompanyHeaderDataEntity = {
  id: string,
  key: keyof CompanyListDataEntity,
  label: string,
  order: number,
  sort: number,
}

export class CommonStore {
  companyHeaders: CompanyHeaderDataEntity[] = [];
  displayCompanyHeaders: CompanyHeaderDataEntity[] = [];
  constructor() {
    makeObservable(this, {
      companyHeaders: observable,
      displayCompanyHeaders: observable,
      setDisplayHeader: action,
      setCompanyHeader: action,
      initialize: action,
    })
  }

  setDisplayHeader(payload: CompaniesHeaderItemDataEntity[]) {
    this.displayCompanyHeaders = payload.map((v) => ({
      id: v.id,
      label: v.name,
      order: v.order,
      ...this.searchProp(v.name)
    }));
  }
  setCompanyHeader(payload: CompaniesHeaderItemDataEntity[]) {
    this.companyHeaders = payload.map((v) => ({
      id: v.id,
      label: v.name,
      order: v.order,
      ...this.searchProp(v.name)
    }));
  }

  async initialize() {
    Promise.all([
      Api.connect().companies().items().get(),
      Api.connect().companies().displayItems().get(),
    ]).then(([items, displayItems]) => {
      this.setCompanyHeader(items.data.items);
      this.setDisplayHeader(displayItems.data.display_items);
    }).catch((e) => console.error(e));
  }
  destroy() {
    this.companyHeaders = [];
  }
  private searchProp(label: string): { key: keyof CompanyListDataEntity, sort: number} {
    switch(label) {
      case '企業ID':
        return { key: 'login_id', sort: 1 };
      case '企業名':
        return { key: 'name', sort: 2 };
      case '企業名(カナ)':
        return { key: 'name_kana', sort: 3 };
      case '業種':
        return { key: 'industry', sort: 4 };
      case '都道府県':
        return { key: 'prefecture', sort: 5 };
      case '企業住所1':
        return { key: 'address1', sort: 6 };
      case '企業住所2':
        return { key: 'address2', sort: 7 };
      case '担当者部署名':
        return { key: 'responsible_person_department_sign', sort: 8 };
      case '担当者名':
        return { key: 'responsible_person_name', sort: 9 };
      case '担当者名(カナ)':
        return { key: 'responsible_person_name_kana', sort: 10 };
      case '担当者電話番号1':
        return { key: 'responsible_person_phone_number1', sort: 11 };
      case '担当者電話番号2':
        return { key: 'responsible_person_phone_number2', sort: 12 };
      case '担当者メールアドレス':
        return { key: 'responsible_person_email_address', sort: 13 };
      case '登録日':
        return { key: 'registration_date', sort: 14 };
      case '利用期限':
        return { key: 'suspend_date', sort: 15 };
      case '利用設定':
        return { key: 'suspend_flag', sort: 16 };
      default:
        throw new Error('存在しない項目です');
    }
  }
}