import { Box, Flex } from "@chakra-ui/react"
import { observer } from "mobx-react-lite";
import { SiteEnergySavingBDataEntity } from "../../../../../module/api/routes/site/energy-saving";
import RootStore from '../../../../../store/root.store';
import { TemplateBComponent } from "../../comparison-insulation-performance/template-b-component/template-b-component";

export type TemplateBProps = {
  data: SiteEnergySavingBDataEntity | null,
}

export const ComparisonBCard = observer((props: TemplateBProps) => {
  const {
    data: templateBData,
  } = props;
  const {
    templateColors: colors,
  } = RootStore.site;
  const {
    energy_saving_band_color,
    energy_saving_text_color,
  } = colors ?? {};

  const LayoutComponent = () => {
    return <TemplateBComponent data={templateBData} />
  }
  return (
    <Flex
      w="fit-content"
      flexDirection="column"
    >
      <Flex
        bg={energy_saving_band_color}
        color={energy_saving_text_color}
        gap="1rem"
        px="1rem"
        justifyContent="center"
        fontWeight="bold"
      >
        <Box>
          {templateBData?.dwelling_unit_type ?? ''}
        </Box>
        <Box>
          {templateBData?.dwelling_unit_number ?? ''}号室
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        gap="1rem"
      >
        <LayoutComponent />
      </Flex>
    </Flex>
  )
});