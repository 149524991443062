import { Box, Flex, Img, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, Modifier, PointerSensor, UniqueIdentifier, useDndMonitor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { cloneDeep } from 'lodash';
import { useCallback, useMemo, useState } from "react";
import dndIcon from '../../../../assets/icon/icon_dnd.svg';
import rightArrowIcon from '../../../../assets/icon/icon_right-arrow.svg';
import { useApiErrorDialog } from '../../../../hooks/utile/use-error-dialog';
import { Api } from "../../../../module/api";
import RootStore from "../../../../store/root.store";
import { CustomButton } from "../../button/button";
import { DialogBody } from "../dialog";
import { DroppableArea } from "./component/droppable-area";
import { DraggableItem } from "./draggable-item/draggable-item";
import { SortableItem } from "./sortbale-item/sortbale-item";


const checkId = (id: UniqueIdentifier, mode: 'master' | 'setting' = 'master', ) => {
  const regex = new RegExp(mode === 'master' ? '^Master-' : '^Setting-');
  return regex.test(id.toString());
}

const ignoreUniquePrefix = (id: UniqueIdentifier) => {
  return id.toString().replace(/^(Master-|Setting-)/, '');
}

export const CompanyListSettingDialog = () => {
  const {
    displayCompanyHeaders,
    companyHeaders,
  } = RootStore.common;
  const errDialog = useApiErrorDialog();
  const masterTable = useMemo(() => cloneDeep(companyHeaders), [companyHeaders]);
  const [settingTable, setSettingTable] = useState(cloneDeep(displayCompanyHeaders));
  const [activeId, setActiveId] = useState<string | null>(null);
  const [load, setLoad] = useState(false);
  const [isHoverDroppableArea, setIsHoverDroppableArea] = useState(false);
  const findActive = useMemo(() => {
    if (!activeId) return null;
    return [
      ...masterTable,
      ...settingTable,
    ].find((v) => v.id === ignoreUniquePrefix(activeId)) ?? null;
  }, [activeId, masterTable, settingTable]);

  const sensors = useSensors(useSensor(PointerSensor));

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    // DragアイテムとOverアイテムのIDが異なる時
    if (over && active.id !== over.id) {
      // マスタ→設定に移動の時
      if (checkId(active.id) && checkId(over.id, 'setting') && isHoverDroppableArea) {
        /* 設定テーブルに同じデータがあるなら移動させない */
        const originalId = ignoreUniquePrefix(active.id);
        const originalData = masterTable.find((v) => v.id === originalId);
        if (settingTable.find((data) => data.id === originalId)) {
          setActiveId(null);
          return;
        }
        if (originalData) {
          // 設定リストへコピー
          setSettingTable(prev => [...prev, { ...originalData }]);
        }
      }
      // 設定→設定の時はソート処理を行う
      if (checkId(active.id, 'setting') && checkId(over.id, 'setting')) {
        const oldIndex = settingTable.findIndex((data) => data.id === ignoreUniquePrefix(active.id));
        const newIndex = settingTable.findIndex((data) => data.id === ignoreUniquePrefix(over.id));
        setSettingTable(prev => cloneDeep(arrayMove(prev, oldIndex, newIndex)));
      }
    }
    setActiveId(null);
  }

  const onDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  };
  const isUseSetting = useCallback((id: string) => {
    return !!settingTable.find((v) => v.id === id);
  }, [settingTable]);

  const onDeleteSetting = useCallback((id: string) => {
    setSettingTable(prev => {
      const targetIndex = prev.findIndex((data) => data.id === ignoreUniquePrefix(id));
      if (targetIndex > -1) {
        prev.splice(targetIndex, 1);
        return [...prev];
      }
      return prev;
    })
  }, []);

  const lockXCoordinateModifier: Modifier = ({ transform }) => {
    return {
      ...transform,
      x: 0, // X座標を固定
    };
  };

  const onSubmit = useCallback(() => {
    setLoad(true);
    Api.connect().companies().displayItems().put({
      display_items: settingTable.map((v, i) => ({ id: v.id, order: i + 1 })),
    }).then(() => {
      RootStore.common.initialize();
      RootStore.dialog.pushMessage({
        messages: ['更新が完了しました。'],
        buttons: [
          { label: 'OK', callback: () => {
            RootStore.dialog.clear();
          }}
        ],
      })
    }).catch((e) => errDialog.push(e))
    .finally(() => setLoad(false));
  }, [errDialog, settingTable]);

  const onClickCancel = useCallback(() => {
    RootStore.dialog.clear();
  }, []);

  const renderOverlay = () => {
    if (!activeId) return null;

    return (
      <Box
        p="0.5rem 1rem"
        display="flex"
        alignItems="center"
        border="1px solid"
        bg="#fff"
        borderRadius="5px"
        borderColor="blackAlpha.200"
        pointerEvents="none"
      >
        <Img src={dndIcon} mr="1rem" />
        {findActive?.label ?? ''}
      </Box>
    );
  };

  return (
    <DialogBody
      bodyProps={{ minW: '708px', overflow: 'auto', maxH: 'calc(100vh - 78px)', px: '5rem' }}
      footer={(
        <>
          <CustomButton isDisabled={load} onClick={onClickCancel} colorType="secondary">キャンセル</CustomButton>
          <CustomButton isDisabled={load} onClick={onSubmit}>OK</CustomButton>
        </>
      )}
    >
      <Box h="100%">
      <DndContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        sensors={sensors}
        modifiers={checkId(activeId ?? '') ? undefined : [lockXCoordinateModifier]}
      >
        <Flex
          w="100%"
          overflow="hidden"
          h="100%"
          justifyContent="center"
          gap="2rem"
        >
          <Box
            border="1px solid"
            borderColor="blackAlpha.200"
            overflow="hidden"
            borderRadius="5px"
            maxH="calc(100vh - 200px)"
          >
            <TableContainer
              h="100%"
              minW="280px"
              overflowY="auto"
            >
              <Table>
              <Thead pos="sticky" top="0" bg="#fff">
                  <Tr><Th pos="sticky" bg="#fff" top="0" textAlign="center">管理項目一覧</Th></Tr>
                </Thead>

                <Tbody>
                  {masterTable.map((rowData) => (
                    <DraggableItem
                      key={`master_${rowData.id}`}
                      id={`Master-${isUseSetting(rowData.id) ? `${rowData.id}/use` : rowData.id}`}
                      disabled={isUseSetting(rowData.id)}
                    >
                      { rowData.label }
                    </DraggableItem>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box mt="auto" mb="auto">
            <Img src={rightArrowIcon} />
          </Box>
          <DroppableArea id={'Setting-Table'} isHover={isHoverDroppableArea} onToggleHover={setIsHoverDroppableArea}>
            <SortableContext items={settingTable.map(item => `Setting-${item.id}`)} strategy={verticalListSortingStrategy}>
              <TableContainer minW="280px" borderRadius="5px" overflowY="auto" h="100%">
                <Table pos="relative" zIndex="5">
                  <Thead pos="sticky" zIndex="5" top="0"><Tr><Th pos="sticky" zIndex="5" top="0" bg="#fff" textAlign="center">表示項目一覧</Th></Tr></Thead>
                  <Tbody>
                    {settingTable.map((item) => (
                      <SortableItem
                        key={`Setting-${item.id}`}
                        id={`Setting-${item.id}`}
                        onDelete={onDeleteSetting}
                      >
                        {item.label}
                      </SortableItem>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </SortableContext>
          </DroppableArea>
          <DragOverlay style={{ pointerEvents: 'none' }}>
            {activeId ? (
              renderOverlay()
            ) : null}
          </DragOverlay>
        </Flex>
      </DndContext>
      </Box>
    </DialogBody>
  )
}
