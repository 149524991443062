import { useCallback } from "react";
import { Api, deleteToken, getSessionTokenFromLocalStorage } from '../../module/api/index';
import { useNavigate } from 'react-router-dom';
import { RoutingPath } from '../../routes/routing-path';
import { AxiosError } from 'axios';
import RootStore from '../../store/root.store';

export const DIALOG_401_ID = '401error';

export const useAuth = () => {
  const navigator = useNavigate();
  const checkLogin = useCallback(async(ignoreCheckToken?: boolean) => {
    if (ignoreCheckToken && RootStore.dialog.includesId(DIALOG_401_ID)) {
      return;
    }
    const token = getSessionTokenFromLocalStorage();
    if (!token) {
      navigator(RoutingPath.login);
      return;
    };
    try {
      const isLoginResponse = await Api.connect().isLogin().get();
      RootStore.user.setUser(isLoginResponse.data);
    } catch(e: any) {
      const error = e as AxiosError<any>;
      deleteToken();
      if (error?.response?.status !== 401) {
        navigator(RoutingPath.login)
      } else {
        RootStore.dialog.pushMessage({
          id: DIALOG_401_ID,
          messages: ['セッションが切断されました。', 'ログインページに戻ります。'],
          buttons: [
            { label: 'OK', callback: () => navigator(RoutingPath.login) }
          ]
        })
      }
    }
  }, [navigator]);

  return {
    checkLogin,
  }
}