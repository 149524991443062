import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDidMount } from '../../../hooks/utile/use-didmount';
import { SidebarList, MspSidebarList } from '../../../routes/routing-path';
import { NavItem } from './components/nav-item';
import RootStore from '../../../store/root.store';
import { observer } from 'mobx-react-lite';
type Props = {
  isMsp?: boolean,
}
export const Sidebar = observer((props: Props) => {
  const { isMsp } = props;
  const { articleCode } = useParams();

  const mspList = useMemo(() => {
    if (articleCode && isMsp) {
      return { ...MspSidebarList, children: MspSidebarList.children.map((data) => ({
        ...data,
        path: data.path(articleCode),
      })) }
    };
    return undefined;
  }, [articleCode, isMsp]);

  const list = useMemo(() => {
    const mainSide = SidebarList.filter((v) => {
      if (RootStore.user.system_admin_flag) return true;
      return !v.isAdmin;
    })
    if (!mspList) return mainSide;
    return [
      ...mainSide,
      mspList,
    ]
  }, [mspList, RootStore.user.system_admin_flag]);

  useDidMount(() => {
    if (!isMsp) {
      RootStore.msp.destroy();
    }
  });
  return (
    <>
      {list.map((item, i) => (
        <NavItem {...item} key={`sideItem_${i}`} />
      ))}
    </>
  )
});