import { Box, Center, Flex, Grid, GridItem, SimpleGrid } from "@chakra-ui/react";
import { Layout } from "../../../layout/layout"
import { MspLayout } from '../../../layout/msp-layout';
import { CustomButton } from "../../../ui/button/button";
import { CAGE_COLOR_LIST, CAGE_COMMON_COLOR_LIST, GIRDLE_COLOR_LIST, MspColorModule, TEXT_COLOR_LIST } from "./collection/template-color.collection";
import { useMspColors } from "./hooks/use-msp-colors";
import { COLOR } from '../../../../styles/const';
import { observer } from "mobx-react-lite";

const HEADER_COLOR = '#4A556A';
const HEADER_HEIGHT = '4rem';
const BODY_HEIGHT = '4rem';
const CELL_WIDTH = '5rem';
const selectStyles = (isSelect: boolean) => !isSelect ? {
  _hover: {
    opacity: '0.7',
  }
} : {
  cursor: 'default',
};

const SelectBorder = ({ isSelect }: { isSelect: boolean }) => {
  if (!isSelect) return <></>;
  return <Box pos="absolute" w="100%" h="100%" border="4px solid" borderColor={COLOR.bg.theme} />
}

export const MspColorSetting = observer(() => {
  const {
    param, setParam,
    onClickSaveData,
  } = useMspColors();
  return (
    <Layout
      isMsp
      bodyProps={{
        alignItems: 'start',
        overflow: "hidden",
      }}
    >
      <MspLayout>
        <Flex h="100%" overflowY="hidden" overflowX="visible" flexDirection="column" gap="1rem" maxH="fit-content" pl="2px">
        <Flex
          maxH="fit-content"
          w="fit-content"
          bg="#fff"
          overflowX="auto"
          overflowY="hidden"
          borderRadius="8px"
          filter="drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.1))"
          p="1.5rem 2rem"
          gap="3rem"
          whiteSpace="nowrap"
        >
          <Grid
            h="100%"
            border="1px solid"
            borderColor="gray.400"
            borderRadius="1rem"
            templateRows=" 3rem 1fr"
            p="1rem"
            gap="1rem"
            minW="fit-content"
          >
            <GridItem colSpan={3}>
              <Center h="100%" fontSize="2xl" fontWeight="bold" color="#5A5A5A">
                かご図ページ
              </Center>
            </GridItem>
            <Flex h="100%" overflow="auto" flexDirection="column" gap="0.5rem">
              <Center
                position="sticky"
                top="0"
                minH={HEADER_HEIGHT}
                minW={CELL_WIDTH}
                bg={HEADER_COLOR}
                color="#fff"
                fontWeight="bold"
                zIndex="2"
              >
                文字色
              </Center>
              { TEXT_COLOR_LIST.map((color) => (
                <Center
                  color={color.textColor ?? color.color}
                  fontSize="3xl"
                  cursor="pointer"
                  minH={BODY_HEIGHT}
                  key={`cage_text_${color.value}`}
                  onClick={() => setParam({ cageText: color.value })}
                  pos="relative"
                  {...selectStyles(param.cageText === color.value)}
                >
                  {color.label}
                  <SelectBorder isSelect={param.cageText === color.value} />
                </Center>
              )) }
            </Flex>
            <Flex h="100%" overflowX="auto" gap="0.5rem" flexDirection="column" px="4px" minW={`calc(${CELL_WIDTH} * 2)`}>
              <GridItem
                position="sticky"
                top="0"
                minH={HEADER_HEIGHT}
                minW={`calc(${CELL_WIDTH} * 2)`}
                color="#fff"
                flexDirection="column"
                fontWeight="bold"
                colSpan={2}
                bg="#fff"
                zIndex="2"
              >
                <Center bg={HEADER_COLOR} w="100%" h="calc(50% - 0.25rem)">
                  住戸部
                </Center>
                <Flex w="100%" h="calc(50% - 0.25rem)" gap="0.5rem" mt="0.5rem">
                  <Center w="100%" bg={HEADER_COLOR}>表示色</Center>
                  <Center w="100%" bg={HEADER_COLOR}>選択色</Center>
                </Flex>
              </GridItem>
              { CAGE_COLOR_LIST.map((colors) => (
                <Flex
                  key={colors.value} gap="0.5rem"
                  minH={BODY_HEIGHT}
                  onClick={() => setParam({ cageBox: colors.value })}
                  cursor="pointer"
                  pos="relative"
                  {...selectStyles(param.cageBox === colors.value)}
                  color={MspColorModule.FindTextColor(param.cageText)}
                >
                  <Center
                    flexDirection="column"
                    w="100%"
                    h="100%"
                    bg={colors.color}
                  >
                    <Box>
                      A
                    </Box>
                    <Box>101</Box>
                  </Center>
                  <Center flexDirection="column" w="100%" h="100%" bg={colors.selectColor}>
                    <Box>A</Box>
                    <Box>101</Box>
                  </Center>
                  <SelectBorder isSelect={param.cageBox === colors.value} />
                </Flex>                
              )) }
            </Flex>
            <Flex flexDirection="column" h="100%" overflow="auto" gap="0.5rem">
              <Center
                position="sticky"
                top="0"
                minH={HEADER_HEIGHT}
                minW={CELL_WIDTH}
                bg={HEADER_COLOR}
                color="#fff"
                fontWeight="bold"
                zIndex="2"
              >
                共用部<br />表示色
              </Center>
              { CAGE_COMMON_COLOR_LIST.map((color) => (
                <Center
                  cursor="pointer"
                  minH={BODY_HEIGHT}
                  key={`cage_text_${color.value}`}
                  bg={color.color}
                  fontSize="2xs"
                  flexDirection="column"
                  pos="relative"
                  onClick={() => setParam({ commonBox: color.value })}
                  color={MspColorModule.FindTextColor(param.cageText)}
                >
                  エントランス
                  <SelectBorder isSelect={param.commonBox === color.value} />
                </Center>
              )) }
            </Flex>
          </Grid>
          <Grid
            h="100%"
            border="1px solid"
            borderColor="gray.400"
            borderRadius="1rem"
            templateRows=" 3rem 1fr"
            p="1rem"
            gap="1rem"
          >
            <GridItem colSpan={2}>
              <Center h="100%" fontSize="2xl" fontWeight="bold" color="#5A5A5A">
                省エネページ
              </Center>
            </GridItem>
            <Flex h="100%" overflow="auto" flexDirection="column" gap="0.5rem" minW={CELL_WIDTH}>
              <Center
                position="sticky"
                top="0"
                minH={HEADER_HEIGHT}
                minW={CELL_WIDTH}
                bg={HEADER_COLOR}
                color="#fff"
                fontWeight="bold"
                zIndex="2"
              >
                文字色
              </Center>
              { TEXT_COLOR_LIST.map((color) => (
                <Center
                  color={color.textColor ?? color.color}
                  fontSize="3xl"
                  cursor="pointer"
                  minH={BODY_HEIGHT}
                  key={`cage_text_${color.value}`}
                  pos="relative"
                  onClick={() => setParam({ ecoText: color.value })}
                >
                  {color.label}
                  <SelectBorder isSelect={param.ecoText === color.value} />
                </Center>
              )) }
            </Flex>
            <Flex h="100%" overflow="auto" gap="0.5rem" flexDirection="column" minW={`calc(${CELL_WIDTH} * 2)`}>
              <Flex
                position="sticky"
                top="0"
                minH={HEADER_HEIGHT}
                minW={`calc(${CELL_WIDTH} * 2)`}
                color="#fff"
                flexDirection="column"
                fontWeight="bold"
                bg="#fff"
                zIndex="2"
              >
                <Center bg={HEADER_COLOR} w="100%" h="100%" textAlign="center">
                  帯色<br />(タイプ･部屋番号)
                </Center>
              </Flex>
              { GIRDLE_COLOR_LIST.map((colors, i) => (
                <Flex
                  key={`girdle_${i}`}
                  gap="0.5rem"
                  minH={BODY_HEIGHT}
                  color={MspColorModule.FindTextColor(param.ecoText)}
                >
                  <Center
                    flexDirection="column"
                    w="100%" h="100%"
                    bg={colors[0].color}
                    cursor="pointer"
                    pos="relative"
                    {...selectStyles(param.ecoBand === colors[0].value)}
                    onClick={() => setParam({ ecoBand: colors[0].value })}
                  >
                    <Box>A</Box>
                    <Box>101</Box>
                    <SelectBorder isSelect={param.ecoBand === colors[0].value} />
                  </Center>
                  <Center
                    flexDirection="column"
                    w="100%" h="100%"
                    bg={colors[1].color}
                    cursor="pointer"
                    pos="relative"
                    {...selectStyles(param.ecoBand === colors[1].value)}
                    onClick={() => setParam({ ecoBand: colors[1].value })}
                  >
                    <Box>A</Box>
                    <Box>101</Box>
                    <SelectBorder isSelect={param.ecoBand === colors[1].value} />
                  </Center>
                </Flex>
              )) }
            </Flex>
          </Grid>
        </Flex>
        <Center w="100%">
          <CustomButton
            onClick={onClickSaveData}
          >
            設定反映
          </CustomButton>
        </Center>
        </Flex>
      </MspLayout>
    </Layout>
  )
});