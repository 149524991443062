import { Box, Img, Td, Tr } from "@chakra-ui/react";
import { useDraggable } from "@dnd-kit/core";
import { ReactNode } from "react";
import dndIcon from '../../../../../assets/icon/icon_dnd.svg';

type Props = {
  id: string,
  children: ReactNode,
  disabled?: boolean,
}

export const DraggableItem = ({ id, children, disabled }: Props) => {
  const { attributes, listeners, setNodeRef } = useDraggable({ id, disabled });
  return (
    <Tr p="0" ref={setNodeRef} h="20px" bg={disabled ? 'blackAlpha.200' : undefined} {...listeners} {...attributes} cursor={disabled ? 'no-drop' : undefined}>
      <Td p="0.5rem 1rem" display="flex" alignItems="center" h="50px">
        <Box>
          <Img src={dndIcon} mr="1rem" />
        </Box>
        {children}
      </Td>
    </Tr>
  );
};
