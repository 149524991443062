import { observer } from 'mobx-react-lite';
import RootStore from '../../../store/root.store';
import { Dialog } from './dialog';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
export const Dialogs = observer(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    RootStore.dialog.clear();
  }, [pathname]);
  return (
    <>
      {RootStore.dialog.dialogs.map((dialog, i) => (
        <Dialog
          {...dialog}
          key={`dialog_${i}`}
        />
      ))}
    </>
  )
});