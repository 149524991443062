import { Grid, GridItem, StackProps } from "@chakra-ui/react";
import { useLocation } from "react-router-dom"
import { COLOR, HEADER_HEIGHT, SIDEBAR_WIDTH } from '../../styles/const';
import { Header } from "./header/header";
import { MainGrid } from "./main-grid/main-grid";
import { Sidebar } from "./sidebar/sidebar";
import { useDidMount } from '../../hooks/utile/use-didmount';
import { useAuth } from "../../hooks/auth/use-auth";
import RootStore from "../../store/root.store";

type Props = {
  children?: React.ReactNode,
  isMsp?: boolean,
  bodyProps?: StackProps,
}

const ignoreLayoutList = [
  '/',
]

export const Layout = (props: Props) => {
  const { children, isMsp } = props;
  const location = useLocation();
  const { checkLogin } = useAuth();
  
  useDidMount(() => {
    checkLogin();
    RootStore.common.initialize();
  });

  if (ignoreLayoutList.includes(location.pathname)) {
    return <>{children}</>
  }

  return (
    <Grid
      templateAreas={`
        "header header"
        "nav main"
      `}
      gridTemplateRows={`${HEADER_HEIGHT} 1fr`}
      gridTemplateColumns={`${SIDEBAR_WIDTH} 1fr`}
      h="100vh"
      w="100%"
    >
      <GridItem
        bg={COLOR.bg.theme}
        area="header"
      >
        <Header />
      </GridItem>
      <GridItem
        bg={COLOR.bg.sub}
        area="nav"
        borderRight={`1px solid ${COLOR.border.sideBar}`}
      >
        <Sidebar isMsp={isMsp} />
      </GridItem>
      <GridItem area="main"
        w="100%"
      >
        <MainGrid
          vStackProps={props.bodyProps}
        >
          {children}
        </MainGrid>
      </GridItem>
    </Grid>
  )
}