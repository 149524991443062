import { cloneDeep } from 'lodash'
import { useCallback, useState } from 'react';

type Props<T extends {}> = T;

type Rule<T extends {}> = Partial<{ [key in keyof T]: (v: T[key]) => T[key] }>;

export type ReturnUseParamState<T> = [
  T,
  (param: Partial<T>) => void,
];

export const useParamState = <T extends {}>(
  props: Props<T>,
  rules?: Rule<T>,
): ReturnUseParamState<T> => {
  const [param, setParam] = useState(props);
  const setState = useCallback((state: Partial<T>) => {
    const copyParam = cloneDeep(state) as T;
    if (rules) {
      const keys = Object.keys(copyParam) as (keyof T)[];
      for (const key of keys) {
        const formatter = rules[key];
        if (formatter) {
          copyParam[key] = formatter(copyParam[key]);
        }
      }
    }
    setParam({
      ...param,
      ...copyParam,
    });
  }, [param, rules]);

  return [param, setState];
};
