/* for useParamState module */
export class InputUtile {
  static onlyNumber <T extends (string | number | undefined)>(v: T): number | undefined {
    const value = `${v}`;
    if (!value || (typeof v === 'undefined')) return undefined;
    return Number(v);
  }

  static email(mail?: string) {
    if (!mail) return '';
    return mail.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, '');
  }

  static numHyphen(v?: string) {
    if (!v) return '';
    return FilterNumAndHyphen(v, true);
  }

  static eisuHyphen(v?: string) {
    if (!v) return '';
    const data = FilterEisuHyphen(v);
    return data;
  }
  static eisu(v?: string) {
    if (!v) return '';
    return v.replace(/[^0-9a-zA-Z]/g, '');
  }
  static subdomain(v: string) {
    return v.replace(/[^0-9a-zA-Z\-]/g, '').toLowerCase();
  }
}

/* Utile function */
/* eslint-disable no-useless-escape */
export const FilterNumAndHyphen = (value: string, isHyphen?: boolean) => {
  const repHyphen = value.replace(/[^0-9\-]/g, '');
  const rep = value.replace(/[^0-9]/g, '');
  return isHyphen ? repHyphen : rep;
};

export const FilterNumber = (value: string) => {
  if (!value) return undefined;
  return Number(FilterNumAndHyphen(value));
};

export const FilterEisuHyphen = (value: string) => {
  if (!value) return undefined;
  const repHyphen = value.replace(/[^0-9a-zA-Z\-]/g, '');
  return repHyphen;
};
