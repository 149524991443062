import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDidMount } from '../../../hooks/utile/use-didmount';
import { SiteRoutingPath } from '../../../routes/routing-path';
import RootStore from '../../../store/root.store';
import { NotFoundPage } from '../../ui/404/not-found';
import { Loading } from '../../ui/loading/loading';
import { GetSiteStoreData } from '../../../@types/global';

type Props = {
  children?: React.ReactNode,
}

export const SiteLayout = observer((props: Props) => {
  const {
    children,
  } = props;

  const location = useLocation();
  const isNotFound = useMemo(() => {
    return !Object.values(SiteRoutingPath).includes(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    if (!isNotFound) {
      RootStore.site.initialize();
    }
  }, [location.pathname]);

  if (RootStore.site.isNotFound || isNotFound) {
    return (
      <NotFoundPage />
    )
  }

  // ロード中
  if (!RootStore.site.isLoaded) {
    return (
      <Loading />
    );
  }

  // 正常表示
  return (
    <>{children}</>
  )
});