import { TableContainer, Tbody, Td, Th, Thead, Tr, Table as ChakraTable, Flex, Box, Alert, AlertIcon } from "@chakra-ui/react"
import { observer } from "mobx-react-lite";
import RootStore from "../../../../../store/root.store";
import { COLOR, DROP_SHADOW } from '../../../../../styles/const';
import { ObjectBase } from "../../../../../types/object";

export type TextAlign = 'center' | 'right' | 'left';

type TableBodyDataEntity = { elm: React.ReactNode, align?: TextAlign }[];

type SubHeaderType = {
  label: string,
}

type HeaderType = {
  label: string,
  colSpan?: number,
  rowSpan?: number,
}

type Props = {
  subHeader: SubHeaderType[],
  header: HeaderType[],
  body: TableBodyDataEntity[],
}

export const CsvTable = observer((props: Props) => {
  const {
    header,
    subHeader,
    body,
  } = props;
  const { energyData } = RootStore.msp;
  if (!energyData || !energyData?.length) {
    return (
      <Box>
        <Alert status="warning">
          <AlertIcon />
          基本設定ファイルが登録されていません
        </Alert>
      </Box>
    )
  }
  return (
    <TableContainer
      w="100%"
      h="100%"
      minH="210px"
      borderRadius="5px 5px 0 0"
      bg={COLOR.bg.sub}
      overflow="auto"
      overflowY="auto"
      filter={DROP_SHADOW}
      px="1rem"
    >
      <ChakraTable
        // pos="relative"
      >
        <Thead
          position="sticky"
          top="0"
          bg={COLOR.bg.sub}
          borderBottom="none"
          p="0"
          _after={{
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: 0,
            right: 0,
            borderBottom: '1px solid',
            borderColor: 'gray.200',
            PointerEvent: 'none',
          }}
        >
          <Tr borderBottom="none">
            { header.map((v, i) => (
              <Th
                textAlign="center"
                key={`th_${i}`}
                textTransform="none"
                position="sticky"
                top="0"
                rowSpan={v.rowSpan}
                colSpan={v.colSpan}
                borderBottom="none"
                p="0.1rem"
              >
                {v.label}
              </Th>
            ))}
          </Tr>
          <Tr borderBottom="none">
            { subHeader.map((v, i) => (
              <Th
              textAlign="center"
              key={`th_${i}`}
              textTransform="none"
              position="sticky"
              top="0"
              p="0.1rem"
              borderBottom="none"
            >
              {v.label}
            </Th>
            ))}
          </Tr>

        </Thead>
        <Tbody>
          {body.map((trData, i) => (
            <Tr
              key={`body_tr_${i}`}
              cursor="pointer"
              _hover={{
                bg: 'yellow.50'
              }}
              >
              {trData.map((tdData, _i) => (
                <Td
                  p="0.5rem"
                  key={`td_${i}_${_i}`}
                  textAlign={tdData.align}
                >
                  {tdData.elm}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
});