import { HashRouter } from "react-router-dom";
import { SiteLayout } from "../component/layout/site/site-layout";
import { Dialogs } from "../component/ui/dialog/dialogs";
import { RouteConfig } from "./route-config";
import { SiteRouteConfig } from "./site-route-config";

type Props = {
  isSubDomain: boolean | null,
}

export const SwitchRouter = (props: Props) => {
  const {
    isSubDomain,
  } = props;
  if (isSubDomain === null) {
    /* env読み込み中 */
    return (
      <></>
    )
  }
  
  if (!isSubDomain) {
    /* 管理画面 */
    return (
      <HashRouter>
        <RouteConfig />
        <Dialogs />
      </HashRouter>
    )
  }

  /* MSPサイト */
  return (
    <HashRouter>
      <SiteLayout>
        <SiteRouteConfig />
      </SiteLayout>
    </HashRouter>
  )
}