import API from "../..";
import { fullUrl, joinPath, requireId } from "../../utile/utile.api";
import cageDrawingData from "./cage-drawing-data";
import colors from "./colors";
import energySavingData from "./energy-saving-data";
import energySavingLabels from "./energy-saving-labels";
import templates from "./templates";

/* 物件一覧取得 */
export type ArticleListDataEntity = {
  /** ○	物件ID */
  id: string,
  /** 企業ログインID */
  company_login_id: string,
  /** 	企業名 */
  company_name: string,
    /** ○	物件No */
  number: string,
  /** ○	物件名 */
  name: string,
  /** ○	登録日 */
  registration_date: string,
  /** ○	最終更新日 */
  last_update_date: string,
  /** ○	サイト公開フラグ(false:非公開 true:公開) */
  site_release_flag: boolean,
  /** ○	サイト公開終了予定日 */
  site_release_end_expected_date: string,
  /** ○	サブドメイン */
  subdomain: string,
  /** ○	サイトURL */
  site_url: string,
  /** 削除フラグ */
  delete_flag: boolean,
}

type ArticleListResponse = {
  properties: ArticleListDataEntity[],
  total: number,
  current_page: number,
  last_page: number,
  per_page: number,
}

export type ArticleListRequestParam = {
  limit?: number,
  keyword?: string,
  delete_flag?: number,
  sort?: number,
  highlow?: number,
  page?: number,
}

/* 物件詳細取得 */
export type ArticleDetailDataEntity = {
  /** ○	物件ID */
  id: string,
  /** 企業ログインID */
  company_login_id: string,
  /** 	企業名 */
  company_name: string,
  /** ○	物件No */
  number: string,
  /** ○	物件名 */
  name: string,
  /** ○	登録日 */
  registration_date: string,
  /** ○	最終更新日 */
  last_update_date: string,
  /** ○	サイト公開フラグ(false:非公開 true:公開) */
  site_release_flag: boolean,
  /** ○	サイト公開終了予定日 */
  site_release_end_expected_date: string,
  /** ○	サブドメイン */
  subdomain: string,
  /** ○	サイトURL */
  site_url: string,
  /** 削除フラグ */
  delete_flag: boolean,
}

type ArticleDetailResponse = {
  property: ArticleDetailDataEntity,
}

/* 物件登録 */
export type ArticleAddRequestParam = {
  /** 企業ID
   * 企業ログインの場合はなし
   * 管理者ログインの場合は必須
  */
  company_id?: string,
  /** ○	物件名 */
  name: string,
  /** ○	サブドメイン */
  subdomain: string,
}
/* 物件更新 */
export type ArticleUpdateRequestParam = {
  /** ○	物件名 */
  name: string,
  /** ○	サイト公開フラグ(false:非公開 true:公開) */
  site_release_flag: boolean,
  /** ○	サイト公開終了予定日 */
  site_release_end_expected_date: string,
  /** ○	サブドメイン */
  subdomain: string,
}

/* 物件サイト作成ステータス取得 */
export type MspSiteStatus = {
  status: number,
}

const path = '/properties';
const restorePath = '/restore';
const getStatusPath = '/site-status';

const properties = (url: string) => (articleId?: string) => {
  const full = fullUrl(url, path);
  const idPath = `/${articleId}`;
  return ({
    getList: (
      param?: ArticleListRequestParam,
    ) => API.con.get<ArticleListRequestParam, ArticleListResponse>({ url: full, data: param }),
    get: () => requireId(() => API.con.get<{}, ArticleDetailResponse>({ url: joinPath(full, idPath), data: {} }), articleId),
    add: (param: ArticleAddRequestParam) => API.con.post<ArticleAddRequestParam, {}>({ url: full, data: param }),
    update: (param: ArticleUpdateRequestParam) => requireId(
      () => API.con.put<ArticleUpdateRequestParam, {}>({ url: joinPath(full, idPath), data: param }),
      articleId
    ),
    delete: () => requireId(() => API.con.delete({ url: joinPath(full, idPath) }), articleId),
    restore: () => requireId(() => API.con.put({ url: joinPath(full, idPath, restorePath) }), articleId),
    getStatus: () => requireId(() => API.con.get<{}, MspSiteStatus>({ url: joinPath(full, idPath, getStatusPath) }), articleId),
    energy: () => requireId(() => energySavingData(joinPath(full, idPath)), articleId)(),
    cageDrawingData: () => requireId(() => cageDrawingData(joinPath(full, idPath)), articleId)(),
    templates: () => requireId(() => templates(joinPath(full, idPath)), articleId)(),
    colors: () => requireId(() => colors(joinPath(full, idPath)), articleId)(),
    energySavingLabels: () => requireId(() => energySavingLabels(joinPath(full, idPath)), articleId)(),
  })
}

export default properties;

export const initialArticleState: ArticleAddRequestParam = {
  name: '',
  subdomain: '',
};

export const initialArticleEditState: ArticleUpdateRequestParam = {
  ...initialArticleState,
  site_release_flag: false,
  site_release_end_expected_date: '',
}