import { Button, ButtonProps, Img } from "@chakra-ui/react";
import settingIcon from '../../../assets/icon/icon_setting.svg';
type Props = {

} & Omit<ButtonProps, 'children'>;

export const SettingButton = (props: Props) => {
  return (
    <Button
      {...props}
      leftIcon={<Img src={settingIcon} alt="setting_icon" />}
      bg="transparent"
      _hover={{
        background: 'rgba(0, 0, 0, 0.1)',
      }}
      _active={{
        background: 'rgba(0, 0, 0, 0.2)',
      }}
      p="5px 7px"
    >
      設定
    </Button>
  )
}