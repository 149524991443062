import { action, makeObservable, observable } from 'mobx';
import { GetSiteStoreData } from '../@types/global';
import { Api } from '../module/api';
import { CageDataResponse } from '../module/api/routes/properties/cage-drawing-data';
import { TemplatesResponseData } from '../module/api/routes/properties/templates';
import { CageColorsData } from '../module/api/routes/site/cage-drawing';
import { SiteEnergySavingBDataEntity, SiteEnergySavingColorData, SiteEnergySavingData, SiteEnergySavingDataEntity } from '../module/api/routes/site/energy-saving';

export class SiteStore {
  subdomain: string = '';
  isInit = false;
  isNotFound = false;
  isLoaded = false;
  cageData: CageDataResponse | null = null;
  energyData: SiteEnergySavingDataEntity[] | null = null;
  energyBData: SiteEnergySavingBDataEntity[] | null = null;
  templates: TemplatesResponseData = {
    template_id: '',
    name: 'テンプレートA',
    setting_items: {
      template: 1,
      items: [],
    }
  };
  templateColors: SiteEnergySavingColorData | null = null;
  cageColors: CageColorsData | null = null;


  constructor() {
    makeObservable(this, {
      isInit: observable,
      subdomain: observable,
      isNotFound: observable,
      isLoaded: observable,
      cageData: observable,
      cageColors: observable,
      energyData: observable,
      energyBData: observable,
      templates: observable,
      templateColors: observable,
      initialize: action,
      setSubdomain: action,
      setEnergyData: action,
    });
  }

  async initialize() {
    try {
      const cageResponse = await Api.connect().site().cageDrawing().get();
      const {
        cage_drawing_text_color,
        cage_drawing_dwelling_unit_display_color,
        cage_drawing_dwelling_unit_selection_color,
        cage_drawing_sharing_department_display_color,
        ...cageData
      } = cageResponse.data;
      this.cageData = cageData;
      this.cageColors = {
        cage_drawing_text_color,
        cage_drawing_dwelling_unit_display_color,
        cage_drawing_dwelling_unit_selection_color,
        cage_drawing_sharing_department_display_color,
      };
      try {
        this.templates = {
          ...this.templates,
        };
      } catch(e) {
        console.error(e);
      }

      this.isInit = true;
      this.isLoaded = true;
    } catch(e) {
      this.isNotFound = true;
    }
  }

  setEnergyData(payload: SiteEnergySavingData) {
    this.energyData = payload.template_A_value;
    this.energyBData = payload.template_B_value;
    this.templateColors = payload.color;
    try {
      const jsonData = JSON.parse(payload.template.setting_items as any);
      this.templates = { 
        ...this.templates,
        setting_items: jsonData,
      };
    } catch(e) {
      console.error(e);
    }
  }

  setSubdomain(payload: string) {
    this.subdomain = payload;
  }

}