import { useCallback, useMemo, useState } from "react";
import { useParamState } from "../../../../../hooks/utile/use-param-state";
import { useValidation } from "../../../../../hooks/utile/use-validation";
import { initialCompanyState } from "../../../../../module/api/routes/companies";
import { InputUtile } from "../../../../../module/utile/input-rurules";
import RootStore from "../../../../../store/root.store";
import { CustomButton } from "../../../button/button"
import { useApiErrorDialog } from '../../../../../hooks/utile/use-error-dialog';
import { Api } from "../../../../../module/api";

type Props = {
  getList: () => void,
}

export const useAddCompany = (props: Props) => {
  const {
    getList,
  } = props;
  const { dialog } = RootStore;
  const [load, setLoad] = useState(false);
  const [request, setRequest] = useParamState(initialCompanyState, {
    password: InputUtile.eisu,
    responsible_person_email_address: InputUtile.email,
    responsible_person_phone_number1: InputUtile.numHyphen,
    responsible_person_phone_number2: InputUtile.numHyphen,
  });
  const errDialog = useApiErrorDialog();
  const { validAll } = useValidation();
  const validation = useMemo(() => {
    const {
      name,
      name_kana,
      password,
      industry,
      address1,
      address2,
      responsible_person_department_sign,
      responsible_person_name,
      responsible_person_name_kana,
      responsible_person_phone_number1,
      responsible_person_phone_number2,
      responsible_person_email_address,
    } = request;
    return {
      name: validAll(name, 'require', 'length50'),
      name_kana: validAll(name_kana, 'require', 'length50', 'kana'),
      password: validAll(password, 'require', 'length256', 'lengthOver8', 'password'),
      industry: validAll(industry, 'length50'),
      address1: validAll(address1, 'length256'),
      address2: validAll(address2, 'length256'),
      responsible_person_department_sign: validAll(responsible_person_department_sign, 'length50'),
      responsible_person_name: validAll(responsible_person_name, 'length60'),
      responsible_person_name_kana: validAll(responsible_person_name_kana, 'length60', 'kana'),
      responsible_person_phone_number1: validAll(responsible_person_phone_number1, 'phoneNumber'),
      responsible_person_phone_number2: validAll(responsible_person_phone_number2, 'phoneNumber'),
      responsible_person_email_address: validAll(responsible_person_email_address, 'mail'),
    }
  }, [request, validAll]);

  const isDisabled = useMemo(() => {
    return !!Object.values(validation).flat().length;
  }, [validation]);

  const onClickSubmit = useCallback(() => {
    setLoad(true);
    Api.connect().companies().add(request)
    .then(() => {
      RootStore.dialog.pushMessage({
        messages: ['登録が完了しました。'],
        buttons: [
          { label: 'OK', callback: () => {
            getList();
            dialog.clear();
          }}
        ],
      })
    }).catch((e) => errDialog.push(e))
    .finally(() => setLoad(false));
  }, [errDialog, request, getList, dialog]);

  const onClickCancel = useCallback(() => {
    dialog.pop();
  }, [dialog]);

  const Footer = () => {
    return (
      <>
        <CustomButton
          colorType="secondary"
          onClick={onClickCancel}
          isDisabled={load}
          >
          キャンセル
        </CustomButton>
        <CustomButton
          onClick={onClickSubmit}
          isDisabled={isDisabled || load}
        >
          OK
        </CustomButton>
      </>
    )
  }

  return {
    Footer,
    request,
    setRequest,
    validation,
  }
}