import { useSortable } from "@dnd-kit/sortable";
import { ReactNode, forwardRef } from "react";
import { CSS } from '@dnd-kit/utilities'
import { Box, Img, Td, Tr, ButtonProps, IconButton, CloseButton, Flex } from '@chakra-ui/react';
import dndIcon from '../../../../../assets/icon/icon_dnd.svg';


type Props = {
  id: string,
  children: ReactNode,
  onDelete: (id: string) => void,
}

export type SotableRefParam = {
  
}

const DeleteButton = (props: ButtonProps) => {
  return (
    <CloseButton
      size="1rem"
      {...props}
      borderRadius="100vh"
      aria-label="delete_button"
      w="2rem"
      h="2rem"
      bg="transparent"
      minW="none"
      p="0"
    />
  );
}

export const SortableItem = ({ id, children, onDelete }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Tr>
      <Td py="0.5rem" display="flex" alignItems="center" h="50px" style={style} ref={setNodeRef}>
        <Flex w="100%" h="100%" alignItems="center" {...listeners} {...attributes}>
          <Box>
            <Img src={dndIcon} mr="1rem" />
          </Box>
          {children}
        </Flex>
        <DeleteButton
          ml="auto"
          onDragStart={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete(id);
          }}
        />
      </Td>
    </Tr>
  );
};
