import { CommonStore } from './common-store';
import { DialogStore } from './dialog.store';
import { MspStore } from './msp-store';
import { SiteStore } from './site-store';
import { UserStore } from './user.store';
import { EnvStore } from './env-store';
class RootStoreClass {
  dialog: DialogStore;
  msp: MspStore;
  user: UserStore;
  common: CommonStore;
  site: SiteStore;
  env: EnvStore;
  constructor() {
    this.dialog = new DialogStore();
    this.msp = new MspStore();
    this.user = new UserStore();
    this.common = new CommonStore();
    this.site = new SiteStore();
    this.env = new EnvStore();
  }
}

const RootStore = new RootStoreClass();
export default RootStore;