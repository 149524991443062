import { Box, Flex, Text } from "@chakra-ui/react";
import { useMemo } from "react";

type Props = {
  grade: number,
  index: number,
}

const labelComment = [
  { index: 4, label: ['省エネ', '基準'] },
  { index: 5, label: ['ZEH', '水準'] },
]

export const BarGraph = (props: Props) => {
  const {
    index,
    grade,
  } = props;

  const activate = useMemo(() => index === grade, [grade])

  const style = useMemo(() => {
    const _height = (index / 7) * 100;
    const height = (_height > 100 ? 100 : _height) - 5;
    return {
      bg: activate ? '#C8863A' : '#BEBEBE',
      height: `${height}%`,
    }
  }, [activate, index]);

  const label = useMemo(() => {
    return labelComment.find((v) => v.index === index);
  }, [index]);
  return (
    <Flex h="100%" justifyContent="flex-end" flexDirection="column" w="54px">
      <Flex h="calc(100% - 34px)" alignItems="flex-end" w="100%">
        <Flex {...style} w="100%" />
      </Flex>
      <Flex
        color="#fff"
        bg={style.bg}
        h="34px"
        fontSize="lg"
        w="100%"
        alignItems="flex-end"
        justifyContent="center"
        pb="0.25rem"
        pos="relative"
      >
        <Text fontSize="xl" fontWeight="bold">
          {index}
        </Text>
        {!!label && (
          <Box
            textAlign="center"
            pos="absolute"
            fontSize="md"
            fontWeight="bold"
            color="#4b4b4b"
            top="2.3rem"
            whiteSpace="nowrap"
            lineHeight="1.25rem"
          >
            {label.label.map((v, i) => (
              <Box key={`label_${index}_${i}`}>{v}</Box>
            ))}
          </Box>
        )}
      </Flex>
    </Flex>
  )
}