import { InputGroup, InputProps, InputRightElement, Img, IconButton } from "@chakra-ui/react"
import { useCallback, KeyboardEvent, useState } from "react";
import searchIcon from '../../../assets/icon/icon_search.svg';
import { Input } from "./input";

type Props = {
  onSearch?: (v: string) => void,
} & InputProps;

export const SearchInput = (props: Props) => {
  const { value, onSearch, ...defaultProps } = props;
  const [_value, setValue] = useState(value);
  const handleSearch = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
      onSearch?.(`${_value ?? ''}`);
    }
  }, [_value, onSearch]);
  return (
    <InputGroup {...defaultProps}>
      <Input
        {...defaultProps}
        value={_value}
        onKeyDown={handleSearch}
        onChange={(e) => setValue(e.target.value)}
        bg="#fff"
      />
      <InputRightElement>
        <IconButton
          aria-label="search_button"
          bg="transparent"
          icon={<Img src={searchIcon} alt="search_icon" />}
          onClick={() => onSearch?.(`${_value ?? ''}`)}
        />
      </InputRightElement>
    </InputGroup>
  )
}