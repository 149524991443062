import { Box } from "@chakra-ui/react";
import { SiteEnergySavingDataEntity } from "../../../../../module/api/routes/site/energy-saving";
import { MspTemplateModule } from '../../../msp/color-setting/collection/template-color.collection';
import { EcoLabelTemplate } from "../../../msp/template/component/echo-label-template";

export type SiteTemplateProps = {
  param: ReturnType<typeof MspTemplateModule.format> | null,
  data: SiteEnergySavingDataEntity | null,
}

export const ComparisonTemplate = (props: SiteTemplateProps) => {
  const {
    param,
    data,
  } = props;
  console.log(param?.energy_saving_label);
  return (
    <Box
      display={param?.energy_saving_label === 3 ? 'none' : undefined}
    >
      {
        data?.label_url ? (
          <EcoLabelTemplate isNone={param?.energy_saving_label === 2} isSite src={data.label_url} display />
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="538" height="400">
            <rect width="538" height="400" fill="#BDBDBD" />
            <text fill="#fff" dominantBaseline="middle" textAnchor="middle" x="50%" y="50%" fontSize="48px" fontWeight="bold">No Image</text>
          </svg>            
        )
      }
    </Box>
  )
}