import { Box, Flex } from "@chakra-ui/react"
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { SiteEnergySavingDataEntity } from "../../../../../module/api/routes/site/energy-saving";
import RootStore from '../../../../../store/root.store';
import { MspTemplateModule } from "../../../msp/color-setting/collection/template-color.collection";
import { ComparisonCo2 } from "../../comparison-insulation-performance/comparison-co2/comparison-co2";
import { ComparisonInsulationPerformance } from "../../comparison-insulation-performance/comparison-insulation-performance";
import { TemplateBComponent } from "../../comparison-insulation-performance/template-b-component/template-b-component";
import { ComparisonConsumptionEnergy } from "../comparison-consumption-energy/comparison-consumption-energy";
import { ComparisonEcoGraph } from "../comparison-eco-graph/comparison-eco-graph";
import { ComparisonTemplate } from "../comparison-template/comparison-template";

type Props = {
  data: SiteEnergySavingDataEntity | null,
}

export const ComparisonCard = observer((props: Props) => {
  const {
    data: templateData,
  } = props;
  const {
    templates,
    templateColors: colors,
  } = RootStore.site;
  const {
    energy_saving_band_color,
    energy_saving_text_color,
  } = colors ?? {};
  const param = useMemo(() => {
    if (!templates.setting_items.items.length) {
      return null;
    }
    return MspTemplateModule.format(templates.setting_items.items);
  }, [templates]);

  const LayoutComponent = () => {
    return (
      <>
        <ComparisonTemplate param={param} data={templateData} />
        <ComparisonEcoGraph param={param} data={templateData} />
        <ComparisonConsumptionEnergy param={param} data={templateData} />
        <ComparisonInsulationPerformance param={param} data={templateData} />
        <ComparisonCo2 param={param} data={templateData} />
      </>
    )
  }
  return (
    <Flex
      w="fit-content"
      flexDirection="column"
    >
      <Flex
        bg={energy_saving_band_color}
        color={energy_saving_text_color}
        gap="1rem"
        px="1rem"
        justifyContent="center"
        fontWeight="bold"
      >
        <Box>
          {templateData?.dwelling_unit_type ?? ''}
        </Box>
        <Box>
          {templateData?.dwelling_unit_number ?? ''}号室
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        gap="1rem"
      >
        <LayoutComponent />
      </Flex>
    </Flex>
  )
});