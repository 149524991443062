import { Badge } from "@chakra-ui/react"
import { COLOR } from "../../../styles/const"

export const Require = () => {
  return (
    <Badge
      color="#fff"
      bg={COLOR.bg.warning}
      fontSize="md"
      fontWeight="bold"
      p="2px 8px"
      display="block"
    >
      必須
    </Badge>

  )
}