import { Box, Flex, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react"
import { SiteEnergySavingDataEntity } from "../../../../../module/api/routes/site/energy-saving"

type Props = {
  data: SiteEnergySavingDataEntity | null,
}

export const UaTable = (props: Props) => {
  const {
    data,
  } = props;

  return (
    <TableContainer>
      <Table variant="unstyled">
        <Tbody
          css={{
            td: {
              padding: '0.5rem',
              textAlign: 'center',
            }
          }}
        >
          <Tr>
            <Td rowSpan={2} fontWeight="bold" w="134px" bg="#F5E0C9" borderRight="2px" p="0.5rem 0.25rem !important">
              <Flex flexDirection="column" gap="0.25rem" justifyContent="center" alignItems="center">
                <Flex w="fit-content" fontSize="sm">外皮平均熱貫流率</Flex>
                <Flex w="fit-content" fontSize="lg">UA値</Flex>
                <Flex w="fit-content" fontWeight="normal">[W/(m2･K)]</Flex>
              </Flex>
            </Td>
            <Td fontWeight="bold" bg="#C0BEE2" borderTop="2px" borderRight="2px">省エネ基準</Td>
            <Td fontWeight="bold" bg="#C0E097" borderTop="2px" borderRight="2px">ZEH基準の家</Td>
            <Td fontWeight="bold" bg="#E4A53B" borderTop="2px" borderRight="2px">あなたの家</Td>
          </Tr>
          <Tr fontWeight="bold" bg="#E6E6E6">
            <Td
              borderBottom="2px"
              pos="relative"
              color={data?.ua ? undefined : 'transparent'}
              _after={{
                content: '">"',
                fontSize: '20px',
                pos: 'absolute',
                right: '-5px'
              }}
              borderColor="#000"
            >{data?.ua_energy_saving ?? 0}</Td>
            <Td
              borderBottom="2px"
              pos="relative"
              color={data?.ua ? undefined : 'transparent'}
              _after={{
                content: '">"',
                fontSize: '20px',
                pos: 'absolute',
                right: '-5px'
              }}
              borderColor="#000"
            >{data?.ua_zeh ?? 0}</Td>
            <Td
              borderBottom="2px"
              borderRight="2px"
              fontSize="lg"
              color={data?.ua ? '#5571C0' : 'transparent'}
              borderColor="#000"
            >{data?.ua ?? 0}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  )
}