import API from "../../..";
import { FileDataRequest } from "../../../utile/type";
import { fullUrl, joinPath, requireId, json2FormForObject } from '../../../utile/utile.api';

export type EnergyDataEntity = {
  /* ○ 階数 */
  floor_number: number;
  /* ○ 住戸タイプ */
  dwelling_unit_type: string;
  /* ○ 住戸番号 */
  dwelling_unit_number: string;
  /* ○	計算値 MJ 戸 */
  calculated_value_mj: number;
  /* ○	計算値 GJ 戸 */
  calculated_value_gj: number;
  /* ○	一次エネルギー消費量 MJ 戸 */
  primary_energy_consumption_quantity_mj: number;
  /* ○	一次エネルギー消費量 GJ 戸 */
  primary_energy_consumption_quantity_gj: number;
  /* ○ CO2排出量 */
  co2_discharge_quantity: number;
  /* ○	光熱費 一般 */
  utility_costs_general: number;
  /* ○	光熱費 本住宅 */
  utility_costs_primary: number;
  /* ○ BEI */
  bei: number;
  /* ○ BELS */
  bels: number;
}

type EnergyDataResponse = {
  energy_saving_data: EnergyDataEntity[] | null,
}

const path = '/energy-saving-data';

const energySavingData = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    get: () => API.con.get<{}, EnergyDataResponse>({ url: full, data: {} }),
    add: (param: FileDataRequest) => API.con.post<FormData, {}>({ url: full, data: json2FormForObject(param) }),
  })
}

export default energySavingData;
