import { Box, BoxProps, Center, VStack } from "@chakra-ui/react"
import { COLOR, DROP_SHADOW } from '../../../../styles/const';

export const LoginFrame = (props: BoxProps) => {
  return (
    <VStack
      {...props}
      bg={COLOR.bg.sub}
      py="50px"
      px="100px"
      gap="1.75rem"
      filter={DROP_SHADOW}
      borderRadius="15px"
      css={{
        '>button': {
          maxWidth: '604px'
        }
      }}
    >
      {props.children}
    </VStack>
  )
}