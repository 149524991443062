import { ReactNode, useMemo } from "react"
import { useDroppable } from '@dnd-kit/core';
import { Box } from "@chakra-ui/react";

type Props = {
  children: ReactNode,
  id: string,
  onToggleHover: (v: boolean) => void,
  isHover: boolean,
}

export const DroppableArea = ({ id, children, onToggleHover, isHover }: Props) => {
  const {
    setNodeRef,
    over,
    active,
  } = useDroppable({ id });

  const isOver = useMemo(() => {
    if (!active || !over) return false;
    return active.id.toString().includes('Master-')
      && over.id.toString().includes('Setting-')
      && isHover;
  }, [active, over, isHover]);

  return (
    <Box
      border="1px solid"
      borderColor="blackAlpha.200"
      borderRadius="5px"
      ref={setNodeRef}
      maxH="calc(100vh - 200px)"
      bg={isOver ? 'rgba(49,169,206,0.3)' : undefined}
      onMouseOver={() => onToggleHover(true)}
      onMouseLeave={() => onToggleHover(false)}
      pos="relative"
    >
      {children}
    </Box>
  )
}
