import { DeleteIcon, RepeatIcon } from "@chakra-ui/icons";
import { Img } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from 'react-router-dom';
import iconEdit from '../../../../assets/icon/icon_edit.svg';
import { useApiErrorDialog } from "../../../../hooks/utile/use-error-dialog";
import { useSort } from "../../../../hooks/utile/use-sort";
import { Api } from '../../../../module/api/index';
import { ArticleListDataEntity, ArticleListRequestParam } from '../../../../module/api/routes/properties/index';
import { replaceArticlePath, RoutingPath } from '../../../../routes/routing-path';
import RootStore from '../../../../store/root.store';
import { CustomButton } from "../../../ui/button/button";
import { ArticleAddDialog } from "../../../ui/dialog/article-add/article-add.dialog";
import { ArticleDetailDialog } from "../../../ui/dialog/article-detail/article-detail.dialog";
import { ArticleEditDialog } from "../../../ui/dialog/article-edit/article-edit.dialog";
import { CompanyListSettingDialog } from "../../../ui/dialog/company-list-setting.dialog/company-list-setting.dialog";
import { AlignData, HeaderType, TableBodyDataEntity } from '../../../ui/table/table';
import { CopyLink } from "../component/copy-link";
import { useLoadingDialog } from '../../../../hooks/utile/use-loading-dialog';

const TABLE_LIMIT_COUNT = 20;

const companyAlign: AlignData<ArticleListDataEntity>[] = [
  { key: 'id', align: 'center' },
  { key: 'company_login_id', align: 'center' },
  { key: 'number', align: 'center' },
  { key: 'registration_date', align: 'center' },
  { key: 'last_update_date', align: 'center' },
  { key: 'site_release_flag', align: 'center' },
  { key: 'site_release_end_expected_date', align: 'center' },
];

const companiesHeaderList: HeaderType<ArticleListDataEntity>[] = [
  { key: 'company_login_id', label: '企業ID', sort: 1, isAdmin: true, },
  { key: 'company_name', label: '企業名', sort: 2, isAdmin: true },
  { key: 'number', label: '物件No', sort: 3 },
  { key: 'name', label: '物件名', sort: 4 },
  { key: 'registration_date', label: '作成日', sort: 5 },
  { key: 'last_update_date', label: '最終作業日', sort: 6 },
  { key: 'site_release_flag', label: '公開設定', sort: 7 },
  { key: 'site_release_end_expected_date', label: '公開終了日', sort: 8 },
  { key: 'subdomain', label: 'サブドメイン名', sort: 9 },
  { key: 'site_url', label: 'サイトURL', sort: 10 },
];

type ListInfo = {
  total: number,
  current_page: number,
  last_page: number,
  per_page: number,
}

type DataCountInfo = {
  to: number,
  from: number,
}

export const useArticlesPage = () => {
  const { dialog } = RootStore;
  const [query, setQuery] = useSearchParams();
  const [companyList, setCompanyList] = useState<ArticleListDataEntity[]>([]);
  const [listInfo, setListInfo] = useState<ListInfo | null>(null);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const errDialog = useApiErrorDialog();

  const { sort, setSort } = useSort<ArticleListRequestParam>({
    initialSort: {
      sort: 6,
      keyword: '',
      delete_flag: 0,
      page: Number(query.get('p') || 1),
    }
  });

  const dataCountInfo = useMemo<DataCountInfo | null>(() => {
      if (!listInfo) return null;
      const currentPage = listInfo.current_page - 1 < 0 ? 0 : listInfo.current_page - 1;
      const start = currentPage * TABLE_LIMIT_COUNT;
      return {
        from: start + 1,
        to: start + companyList.length,
      }
  }, [listInfo, companyList]);

  const getList = useCallback((req?: typeof sort) => {
    setError(false);
    const request = req ?? sort;
    Api.connect().properties().getList(request).then((res) => {
      const {
        properties,
        total,
        current_page,
        last_page,
        per_page,
      } = res.data;

      setCompanyList(properties);
      setListInfo({
        total,
        current_page,
        last_page,
        per_page,
      });
      setLoad(true);
    }).catch((e) => {
      errDialog.push(e);
      setError(true);
    }).finally(() => {
      setLoad(true);
    })
  }, [sort, errDialog, dialog]);

  const setRequest = useCallback((req: Partial<typeof sort>, ignoreSetQuery?: boolean) => {
    const request = { ...sort, ...req };
    if (
      (req.page !== undefined)
      && !ignoreSetQuery
    ) {
      setQuery((prev) => {
        prev.set('p', `${req.page || '1'}`);
        return prev;
      });
    }
    setSort({ ...request });
    getList(request);
  }, [sort, getList, setSort, setQuery]);

  const TableModel = useMemo(() => {
    const headers = companiesHeaderList
    .filter((v) => {
      if (!v.isAdmin) return true;
      return RootStore.user.system_admin_flag;
    });
    const bodyList: TableBodyDataEntity<(typeof companyList)[0]>[] = companyList.map((companyData) => {
      const result = headers.map((headerData) => {
        const align = companyAlign.find((v) => v.key === headerData.key)?.align;
        switch(headerData.key) {
          case 'site_url':
            return {
              elm: <CopyLink
                  href={companyData[headerData.key]}
                  target="_blank"
                  isPublic={!!companyData.site_release_flag}
                  disabled={!!dialog.dialogs.length}
                />,
              align: align,
            }            
          case 'registration_date' :
          case 'last_update_date' :
          case 'site_release_end_expected_date' :
            return {
              elm: companyData[headerData.key]?.replaceAll('-', '/') ?? '-',
              align: align,
            };
          case 'site_release_flag':
            return {
              elm: companyData[headerData.key] ? '公開' : '非公開',
              align: align,
            };
          default: 
            return {
              elm: companyData[headerData.key],
              align: align,
            };
        }
      });
      return {
        data: companyData,
        list: result,
        deleted: !!companyData.delete_flag,
      };
    });
    return {
      body: bodyList,
      header: headers,
      isLoading: load,
      isError: error,
    };
  }, [companyList, dialog, load, error, RootStore.user.system_admin_flag]);

  const DetailHeaderElm = (param: {rowData: typeof companyList[0], openEdit: () => void }) => {
    const {
      rowData,
      openEdit,
    } = param;
    return (
      <>
        { !rowData.delete_flag && (
          <Link to={replaceArticlePath(rowData.id, RoutingPath.mspConfig)} tabIndex={-1}>
            <CustomButton
              minW="fit-content"
              tabIndex={-1}
              colorType="thirdly"            
            >
                MSPサイト作成
            </CustomButton>
          </Link>
        )}
        <CustomButton
          minW="fit-content"
          tabIndex={-1}
          onClick={openEdit}
          leftIcon={<Img src={iconEdit} alt="icon_edit" mb="2px" />}
        >
          編集
        </CustomButton>
      </>
    )
  }

  const onClickTableRow = useCallback((rowData: typeof companyList[0]) => {
    const { id } = rowData;
    const openEdit = () => {
      dialog.pop();
      dialog.push({
        title: '物件編集',
        headerElm: <EditHeaderElm
          rowData={rowData}
          callback={() => getList()}
        />,
        children: <ArticleEditDialog
          id={id}
          isDelete={!!rowData.delete_flag}
          callback={() => getList()}
        />
      })
    }
    dialog.push({
      title: '物件詳細',
      headerElm: <DetailHeaderElm rowData={rowData} openEdit={openEdit} />,
      children: <ArticleDetailDialog id={id} />
    })
  }, [dialog, getList]);

  const onClickAddArticle = useCallback(() => {
    dialog.push({
      title: '物件新規登録',
      children: <ArticleAddDialog getList={getList} />,
    })
  }, [dialog, getList]);

  const onClickListSetting = useCallback(() => {
    dialog.push({
      title: 'リスト表示設定',
      children: <CompanyListSettingDialog />
    })
  }, [dialog]);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    const queryPage = Number(query.get('p') || 1);
    if (queryPage !== sort.page) {
      setRequest({ page: queryPage }, true);
    }
  }, [query]);

  return {
    TableModel,
    listInfo,
    dataCountInfo,
    onClickAddArticle,
    onClickTableRow,
    onClickListSetting,
    sort, setRequest,
    getList,
  }
}

const EditHeaderElm = (param: {rowData: ArticleListDataEntity, callback: () => void }) => {
  const {
    rowData,
    callback,
  } = param;
  const [load, setLoad] = useState(false);
  const errMessage = useApiErrorDialog();
  const loading = useLoadingDialog();
  const onClickButton = useCallback(async() => {
    const message = rowData.delete_flag ? '復元' : '削除';
    const confirm = () => new Promise<void>((resolve) => {
      RootStore.dialog.pushMessage({
        title: '確認',
        messages: [`[${rowData.name}]を${message}します。`, 'よろしいですか？'],
        buttons: [
          { label: 'キャンセル', color: 'secondary', callback: () => RootStore.dialog.pop() },
          { label: 'OK', callback: () => {
            RootStore.dialog.pop();
            resolve();
          }},
        ]
      })
    });
    await confirm();
    setLoad(true);
    loading.push();
    const api = rowData.delete_flag
      ? Api.connect().properties(rowData.id).restore
      : Api.connect().properties(rowData.id).delete;
    api().then(() => {
      callback();
      RootStore.dialog.pushMessage({
        messages: [`${message}が完了しました。`],
        buttons: [
          { label: 'OK', callback: () => {
            RootStore.dialog.clear();
          }}
        ],
      })  
    }).catch((e) => {
      setLoad(false);
      errMessage.push(e);
    }).finally(() => {
      loading.pop();
    })
  }, [callback, rowData, errMessage, loading]);
  return (
    <>
      { !rowData.delete_flag ? (
        <CustomButton
          minW="fit-content"
          tabIndex={-1}
          colorType="warning"
          leftIcon={<DeleteIcon w="20px" h="20px" mb="2px" />}
          isDisabled={load}
          onClick={() => onClickButton()}
        >
          削除
        </CustomButton>
      ) : (
        <CustomButton
          minW="fit-content"
          tabIndex={-1}
          colorType="fourthly"
          onClick={() => onClickButton()}
          leftIcon={<RepeatIcon w="20px" h="20px" mb="2px" />}
        >
          復元
        </CustomButton>
      )}
    </>
  )
}
