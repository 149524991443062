import { Box, Center, Heading, Modal, ModalBody, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import RootStore from '../../store/root.store';
import { COLOR } from '../../styles/const';
import { Radio } from '../ui/radio/radio';
import { useMsp } from './hooks/use-msp';

type Props = {
  children?: React.ReactNode;
}
export const MspLayout = observer((props: Props) => {
  const {
    children,
  } = props;
  const { articleCode } = useParams();
  const {
    initialize,
  } = useMsp();

  const isDev = useMemo(() => {
    const host = window.location.host;
    const split = host.split('.');
    return split[split.length - 1] === 'dev' || host.includes('localhost:');
  }, []);
  const [dev, setDev] = useState(false);

  useEffect(() => {
    if (articleCode) {
      initialize(articleCode);
    }
  }, [articleCode]);
  return (
    <>
      <Heading
        w="100%"
        fontSize="3xl"
        fontWeight="normal"
        pb="1rem"
        borderBottom="1px solid"
        borderColor="gray.400"
        mb="0.5rem"
      >
        {RootStore.msp.article?.name ?? ''}
      </Heading>
      {children}
      {!RootStore.msp.isInit && 
        (
          <Modal isOpen onClose={() => {}} isCentered>
            <ModalOverlay></ModalOverlay>
            <ModalContent>
              <ModalBody px="2.5rem" autoFocus={false}>
                <Center py="3rem">
                  <Spinner color={COLOR.bg.theme} size="lg" />
                </Center>
              </ModalBody>
          
            </ModalContent>
          </Modal>
        )
      }
    </>
  )
})