import { Box, Flex, Img, Table, TableContainer, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { lineHeight } from "@mui/system";
import React from "react";
import { useMemo } from "react";
import womanImg from '../../../../../assets/template/woman-img.png'
import conversionImg from '../../../../../assets/template/conversion-img.png'
import { TemplateBProps } from "../../component/comparison-card/comparison-b-card";

const TEXT1 = `計算条件：『省エネ基準の家』国が定める基準により算出した設計二次エネルギー消費量に設計一次エネルギー消費量に対する基準一次エネルギー消費量の割合を乗じて基準二次エネルギー消費量を算出し、燃料単価、換算係数を乗じて算定。『あなたの家』国が定める基準により算出した設計二次エネルギー消費量に燃料単価、換算係数を乗じて算定。実際の年間光熱費を保証するものではありません。設備の新設維持更新に要する費用は考慮しておりません。燃料単価、換算係数は「総合資源エネルギー調査会省エネルギー・新エネルギー分科会　省エネルギー小委員会小売事業者判断基準ワーキンググループ取りまとめ」を参照。`;
const TEXT2 = `計算条件：計算条件：CO2排出係数は電気「電気事業者別排出係数一覧 」、ガス「CASBEE建築評価マニュアル表Ⅳ.2.7評価に用いたエネルギー種別のCO2排出係数」を参照、杉の木1本当たりのCO2吸収量8.8kg/本（林野庁HP森林吸収量計算方法を参照）。`

export const TemplateBUtilityCosts = (props: TemplateBProps) => {
  const {
    data,
  } = props;
  const tableData = useMemo(() => {
    return [
      {
        label: '燃料単価',
        data: [
          { label: '電気', value: data?.fuel_price_electricity ?? 0, unit: '円/kWh' },
          { label: 'ガス(基準)', value: data?.fuel_price_gas_standard ?? 0, unit: '円/m3' },
          { label: 'ガス(設計)', value: data?.fuel_price_gas_design ?? 0, unit: '円/m3' },
          { label: '灯油', value: data?.fuel_price_kerosene ?? 0, unit: '円/ℓ' },
          { label: '売電(コージェネレーション)', value: data?.fuel_price_selling_cogeneration ?? 0, unit: '円/kWh' },
          { label: '売電(太陽光発電)', value: data?.fuel_price_selling_solar ?? 0, unit: '円/kWh' },
        ],
      },
      {
        label: '設計二次エネルギー消費量',
        data: [
          { label: '電気', value: data?.design_secondary_energy_electricity ?? 0, unit: 'kWh' },
          { label: 'ガス', value: data?.design_secondary_energy_gas ?? 0, unit: 'm3' },
          { label: '灯油', value: data?.design_secondary_energy_kerosene ?? 0, unit: 'ℓ' },
          { label: '自家消費', value: data?.design_secondary_energy_in_house_power ?? 0, unit: 'kWh' },
          { label: '売電(コージェネレーション)', value: data?.design_secondary_energy_power_selling_cogeneration ?? 0, unit: 'kWh' },
          { label: '売電(太陽光発電)', value: data?.design_secondary_energy_power_selling_solar_power ?? 0, unit: 'kWh' },
        ],
      },
    ]
    // return {
    //   tdData: [
    //     { label: '電気', value: 27, unit: '円/kWh' },
    //     { label: '電気', value: 27, unit: '円/kWh' },
    //     { label: '電気', value: 27, unit: '円/kWh' },
    //     { label: '電気', value: 27, unit: '円/kWh' },
    //   ],
    //   sekkeiniji: [
    //   ],
  }, []);
  return (
    <Flex w="100%">
      <Box>
        <Box fontSize="lg" fontWeight="bold" mt="0.5rem">あなたの家の光熱費(参考)</Box>
        <Box pl="0.5rem" w="352px">
          <TableContainer w="345px">
            <Table variant="unstyle">
              <Tbody sx={{ td: { textAlign: 'center', py: '0.5rem', borderRight: '2px solid #000' } }}>
                <Tr sx={{ '>td': { fontSize: '12px', pb: '0 !important' } }}>
                  <Td/>
                  <Td >省エネ基準の家</Td>
                  <Td >あなたの家</Td>
                </Tr>
                <Tr sx={{ '>td': { fontWeight: 'bold' } }}>
                  <Td px="0.5rem" color="#fff" fontSize="12px" bg="#A2A2A2">合計/年</Td>
                  <Td bg="#E6E6E6">
                    {`${(data?.utility_costs_general ?? 0).toLocaleString()}　`}
                    円
                  </Td>
                  <Td bg="#E4A53B">
                    {`${(data?.utility_costs_primary ?? 0).toLocaleString()}　`}
                    円
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <Box w="100%" py="1rem">
            <Flex fontSize="18px" fontWeight="bold" color="#DD372C" w="100%" justifyContent="center" alignItems="flex-end">
              <Text>年間&ensp;</Text>
              <Text>{(data?.utility_costs_annual_difference ?? 0).toLocaleString()}</Text>
              <Text>&ensp;円&ensp;</Text>
              <Text fontSize="12px">
                (毎月平均&ensp;
                {(Math.floor(((data?.utility_costs_annual_difference ?? 0) / 12) * 100) / 100).toLocaleString()}
                &ensp;円)
              </Text>
              <Text>&ensp;お得！</Text>
            </Flex>
          </Box>
          <Flex fontSize="5.5px">{TEXT1}</Flex>
        </Box>
      </Box>
      <Box mx="1rem">
        <TableContainer>
          <Table
            variant="unstyle"
            fontSize="9px"
            minH="0"
            sx={{
              tr: {
                borderBottom: '1px solid'
              },
              td: {
                p: '2px',
                lineHeight: '12px',
              }
            }}
          >
            <Tbody>
              {tableData.map((info, i) => (
                <React.Fragment key={i}>
                  <Tr>
                    <Td pt={!i ? '2px' : '6px !important'} fontWeight="bold" colSpan={3}>
                      {info.label}
                    </Td>
                  </Tr>
                  {info.data.map((detailInfo, _i) => (
                    <Tr key={`${i}_${_i}`}>
                      <Td>{detailInfo.label}</Td>
                      <Td textAlign="right">
                        {detailInfo.value.toLocaleString()}
                      </Td>
                      <Td fontSize="8px">{detailInfo.unit}</Td>
                    </Tr>
                  ))}
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Flex w="100%" p="0.25rem">
        <Box w="175px">
          <Img src={womanImg} alt="woman" h="203px" mt="5px" />
        </Box>
        <Box w="calc(100% - 175px)" mt="1rem">
          <Flex>
            <Box fontWeight="bold" fontSize="14px" ml="1rem">
              <Box>
                １戸あたり年間約
                {(data?.kg ?? 0).toLocaleString()}
                kgの
              </Box>
              <Box>CO₂量を削減</Box>
            </Box>
            <Box fontWeight="bold" fontSize="14px" textAlign="center" ml="6rem">
              <Box>
                杉の木
                {(data?.count ?? 0).toLocaleString()}
                本分
              </Box>
              <Box fontSize="11px">(ひと月で吸収するCO₂量)</Box>
            </Box>
          </Flex>
          <Flex w="100%" justifyContent="center" mt="0.5rem">
            <Img w="85%" src={conversionImg} alt="conversion" />
          </Flex>
          <Flex w="100%" justifyContent="center" mt="0.5rem">
            <Box fontSize="5.5px" w="80%">{TEXT2}</Box>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}