import { cloneDeep } from "lodash";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApiErrorDialog } from "../../../../../hooks/utile/use-error-dialog";
import { useLoadingDialog } from "../../../../../hooks/utile/use-loading-dialog";
import { useParamState } from "../../../../../hooks/utile/use-param-state";
import { Api } from "../../../../../module/api";
import RootStore from "../../../../../store/root.store";
import { useMsp } from "../../../../layout/hooks/use-msp";
import { MspColorModule } from '../collection/template-color.collection';

export const useMspColors = () => {
  const { colors } = RootStore.msp;
  const load = useLoadingDialog();
  const errDialog = useApiErrorDialog();
  const { articleCode } = useParams();
  const { getColors } = useMsp();
  const [param, setParam] = useParamState(cloneDeep(MspColorModule.INITIAL_STATE));

  const onClickSaveData = useCallback(async() => {
    if (!colors || !articleCode) return;
    load.push();
    try {
      await Api.connect().properties(articleCode).colors().update(MspColorModule.createRequest(param));
      await getColors(articleCode);
      
      RootStore.dialog.pushMessage({
        title: '完了',
        messages: ['カラー設定の更新が完了しました'],
        buttons: [{ label: 'OK', callback: () => RootStore.dialog.clear() }],
      });
    } catch(e: any) {
      errDialog.push(e);
    } finally {
      load.pop();
    }
  }, [
    errDialog, load,
    param,
    colors,
    articleCode,
    getColors,
  ])

  useEffect(() => {
    if (colors) {
      setParam(MspColorModule.createColor(colors));
    }
  }, [colors]);

  return {
    param, setParam,
    onClickSaveData,
  }
}