import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { IconButton, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { prefectureList } from '../../../../model/prefecture-list';
import { Input } from '../../input/input';
import { LabeledComponentGroup, LabeledComponent } from '../../labeled-component/labeled-component';
import { Select } from '../../select/select';
import { DialogBody } from '../dialog';
import { useAddCompany } from './hooks/use-add-company';

type Props = {
  getList: () => void,
}

export const AddCompanyDialog = (props: Props) => {
  const {
    getList,
  } = props;

  const {
    Footer,
    request,
    setRequest,
    validation,
  } = useAddCompany({ getList });
  const [passwordView, setPasswordView] = useState(false);
  const onClickPasswordView = useCallback(() => {
    setPasswordView(!passwordView);
  }, [passwordView]);
  return (
    <DialogBody
      bodyProps={{ w: '620px', maxW: '100%', minW: '200px' }}
      footer={<Footer />}
    >
      <LabeledComponentGroup labelWidth='210px' minW="calc(350px + 1rem)">
        <LabeledComponent label="企業名" require>
          <Input
            value={request.name}
            onChange={(e) => setRequest({ name: e.target.value })}
            validations={validation.name}
          />
        </LabeledComponent>
        <LabeledComponent label="企業名(カナ)" require>
          <Input
            value={request.name_kana}
            onChange={(e) => setRequest({ name_kana: e.target.value })}
            validations={validation.name_kana}
          />
      </LabeledComponent>
        <LabeledComponent label="パスワード" require>
          <InputGroup>
            <Input
              type={passwordView ? 'text' : 'password'}
              value={request.password}
              onChange={(e) => setRequest({ password: e.target.value })}
              validations={validation.password}
            />
            <InputRightElement>
              <IconButton
                aria-label='password_preview'
                onClick={onClickPasswordView}
                bg="transparent"
                icon={
                  !passwordView ? <ViewIcon /> : <ViewOffIcon />
                }
              />
            </InputRightElement>
          </InputGroup>
        </LabeledComponent>
        <LabeledComponent label="業種">
          <Input
            w="50%"
            minW="200px"
            value={request.industry}
            onChange={(e) => setRequest({ industry: e.target.value })}
            validations={validation.industry}
          />
        </LabeledComponent>
        <LabeledComponent label="都道府県">
          <Select
            value={request.prefecture}
            option={prefectureList}
            onChange={(v) => setRequest({ prefecture: v })}
            w="50%"
            minW="200px"
          />
        </LabeledComponent>
        <LabeledComponent label="企業住所1">
          <Input
            value={request.address1}
            onChange={(e) => setRequest({ address1: e.target.value })}
            validations={validation.address1}
          />
        </LabeledComponent>
        <LabeledComponent label="企業住所2">
          <Input
            value={request.address2}
            onChange={(e) => setRequest({ address2: e.target.value })}
            validations={validation.address2}
          />
        </LabeledComponent>
        <LabeledComponent label="担当部署名">
          <Input
            value={request.responsible_person_department_sign}
            onChange={(e) => setRequest({ responsible_person_department_sign: e.target.value })}
            validations={validation.responsible_person_department_sign}
          />
        </LabeledComponent>
        <LabeledComponent label="担当者名">
          <Input
            value={request.responsible_person_name}
            onChange={(e) => setRequest({ responsible_person_name: e.target.value })}
            validations={validation.responsible_person_name}
          />
        </LabeledComponent>
        <LabeledComponent label="担当者名(カナ)">
          <Input
            value={request.responsible_person_name_kana}
            onChange={(e) => setRequest({ responsible_person_name_kana: e.target.value })}
            validations={validation.responsible_person_name_kana}
          />
        </LabeledComponent>
        <LabeledComponent label="担当者電話番号1">
          <Input
            type="tel"
            value={request.responsible_person_phone_number1}
            onChange={(e) => setRequest({ responsible_person_phone_number1: e.target.value })}
            validations={validation.responsible_person_phone_number1}
          />
        </LabeledComponent>
        <LabeledComponent label="担当者電話番号2">
          <Input
            type="tel"
            value={request.responsible_person_phone_number2}
            onChange={(e) => setRequest({ responsible_person_phone_number2: e.target.value })}
            validations={validation.responsible_person_phone_number2}
          />
        </LabeledComponent>
        <LabeledComponent label="担当者メールアドレス">
          <Input
            type="email"
            value={request.responsible_person_email_address}
            onChange={(e) => setRequest({ responsible_person_email_address: e.target.value })}
            validations={validation.responsible_person_email_address}
          />
        </LabeledComponent>
      </LabeledComponentGroup>
    </DialogBody>
  )  
}