import { Radio, RadioGroup } from "@chakra-ui/react"
import { findPrefectureLabel } from "../../../../model/prefecture-list";
import { LabeledComponent, LabeledComponentGroup } from "../../labeled-component/labeled-component"
import { DialogBody } from "../dialog"
import { useCompanyDetail } from './use-company-detail';
type Props = {
  id: string,
}

export const DetailCompanyDialog = (props: Props) => {
  const {
    id,
  } = props;

  const { info } = useCompanyDetail({ id });
  return (
    <DialogBody
      bodyProps={{ w: '600px', maxW: '100%', minW: '200px' }}
    >
      <LabeledComponentGroup labelWidth='175px' minW="calc(350px + 1rem)">
        <LabeledComponent label="企業ID">
          {info?.login_id ?? ''}
        </LabeledComponent>
        <LabeledComponent label="企業名">
          {info?.name ?? ''}
        </LabeledComponent>
        <LabeledComponent label="企業名(カナ)">
          {info?.name_kana ?? ''}
        </LabeledComponent>
        <LabeledComponent label="業種">
          {info?.industry ?? ''}          
        </LabeledComponent>
        <LabeledComponent label="都道府県">
          {findPrefectureLabel(info?.prefecture)} 
        </LabeledComponent>
        <LabeledComponent label="企業住所1">
          {info?.address1 ?? ''}
        </LabeledComponent>
        <LabeledComponent label="企業住所2">
          {info?.address2 ?? ''}
        </LabeledComponent>
        <LabeledComponent label="担当部署名">
          {info?.responsible_person_department_sign ?? ''}
        </LabeledComponent>
        <LabeledComponent label="担当者名">
          {info?.responsible_person_name ?? ''}
        </LabeledComponent>
        <LabeledComponent label="担当者名(カナ)">
          {info?.responsible_person_name_kana ?? ''}
        </LabeledComponent>
        <LabeledComponent label="担当者電話番号1">
          {info?.responsible_person_phone_number1 ?? ''}
        </LabeledComponent>
        <LabeledComponent label="担当者電話番号2">
          {info?.responsible_person_phone_number2 ?? ''}
        </LabeledComponent>
        <LabeledComponent label="担当者メールアドレス">
          {info?.responsible_person_email_address ?? ''}
        </LabeledComponent>
        <LabeledComponent label="登録日">
          {info?.registration_date.replaceAll('-', '/') ?? ''}
        </LabeledComponent>
        <LabeledComponent label="利用期限">
          {info?.suspend_date.replaceAll('-', '/') ?? ''}
        </LabeledComponent>
        <LabeledComponent label="利用設定">
          {!info?.suspend_flag ? '利用中' : '利用停止'}
        </LabeledComponent>
      </LabeledComponentGroup>
    </DialogBody>
  )
}