import { TableContainer, Tbody, Td, Tr, Table as ChakraTable, Box} from "@chakra-ui/react"
import { COLOR, DROP_SHADOW } from '../../../../../styles/const';
import { ObjectBase } from "../../../../../types/object";

export type TextAlign = 'center' | 'right' | 'left';

type TableData = {
  id: string,
} | null;

export type EcoTableBodyDataEntity = {
  elm: string,
  align?: TextAlign,
  data: TableData,
}

export type AlignData<T extends ObjectBase> = {
  key: keyof T,
  align?: TextAlign,
};

type Props = {
  body: EcoTableBodyDataEntity[][],
  dataClick?: (data: TableData, e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => void,
  selectedList: string[],
}

export function EcoLabelTable(props: Props) {
  const {
    body,
    dataClick,
    selectedList,
  } = props;
  return (
    <TableContainer
      w="100%"
      h="100%"
      minH="210px"
      // h="calc(100% + 90px)"
      borderRadius="5px 5px 0 0"
      bg={COLOR.bg.sub}
      overflow="auto"
      overflowY="auto"
      filter={DROP_SHADOW}
      px="1rem"
      pb="110px"
    >
      <ChakraTable
        pos="relative"
        variant="simple"
      >
        <Tbody>
          {body.map((trData, i) => (
            <Tr
              key={`body_tr_${i}`}
              cursor="pointer"
            >
              {trData.map((tdData, _i) => (
                <Td
                  key={`td_${i}_${_i}`}
                  textAlign={tdData.align}
                  border="1px"
                  borderTop={!i ? 'none' : undefined}
                  borderColor="gray.200"
                  bg={selectedList.includes(tdData.data?.id ?? '') ? 'yellow.50' : undefined}
                  _hover={{
                    bg: 'yellow.50',
                    opacity: '0.7'
                  }}
                  onClick={(e) => tdData.data && dataClick?.(tdData.data, e)}
                >
                  <Box as="span" userSelect="none">{tdData.elm}</Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}