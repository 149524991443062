import { useCallback, useState } from 'react';
import { ObjectBase } from '../../types/object';

type SortDefaultType = {
  sort: number,
  highlow: number,
}

export type InitialSortType<T extends ObjectBase = ObjectBase> = T & Partial<SortDefaultType>

type Props<T extends ObjectBase = ObjectBase> = {
  initialSort?: InitialSortType<T>,
}

const initialSortState: SortDefaultType = {
  sort: 1,
  highlow: 1,
}



export function useSort <T extends ObjectBase = ObjectBase>(props: Props<T>){
  const {
    initialSort = {},
  } = props;
  const [sort, _setSort] = useState<Partial<T> & SortDefaultType>(() => ({
    ...initialSortState,
    ...(initialSort as any),
  }));

  const setSort = useCallback((param: Partial<T & SortDefaultType>) => {
    const result = {
      ...sort,
      ...param,
    }
    _setSort(result);
    return result;
  }, [sort]);

  return {
    sort,
    setSort,
  }
}

