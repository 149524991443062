import { useCallback, useState } from "react";
import RootStore from '../../../../store/root.store';

export const useNavItem = (articleCode?: string) => {
  const isDisabled = useCallback((index?: number) => {
    switch(index) {
      case undefined:
        return false;
      case 1:
        return false;
      case 2:
        return RootStore.msp.status < 1;
        default:
        return RootStore.msp.status < 2;
    }
  }, [articleCode, RootStore.msp.status]);

  return {
    isDisabled,
  }
}