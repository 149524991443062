import { Flex, FlexProps, Img } from "@chakra-ui/react"
import template4 from '../../../../../assets/template/template_04.png';

type Props = {
  isUa?: boolean,
  display: FlexProps['display'],
}

export const InsulationTemplate = (props: Props) => {
  return (
    <Flex
      w="300px"
      display={props.display}
      h={!props.isUa ? '120px' : undefined}
      overflow="hidden"
      pos="relative"
    >
      <Img
        w="300px"
        h="187px"
        src={template4}
      />
      <Img
        pos="absolute"
        display={!props.isUa ? 'block' : 'none'}
        w="300px"
        h="187px"
        src={template4}
        top="110px"
      />
    </Flex>
  )
}