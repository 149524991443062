import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { TableContainer, Tbody, Td, Th, Thead, Tr, Table as ChakraTable, Flex, Box, Alert, AlertIcon } from "@chakra-ui/react"
import { useCallback } from "react";
import { isUndefined } from '../../../lib/is-undefined';
import { COLOR, DROP_SHADOW } from '../../../styles/const';
import { ObjectBase } from "../../../types/object";

export type TextAlign = 'center' | 'right' | 'left';

export type TableBodyDataEntity<T extends ObjectBase> = {
  data: T,
  list: { elm: React.ReactNode, align?: TextAlign }[],
  deleted?: boolean,
}

export type AlignData<T extends ObjectBase> = {
  key: keyof T,
  align?: TextAlign,
};

export type HeaderType<T extends ObjectBase> = {
  key: keyof T,
  label: string,
  sort?: number,
  align?: TextAlign,
  isAdmin?: boolean,
}

type SortType = {
  sort: number,
  highlow: number,
}


type Props<T extends ObjectBase = {}> = {
  header: HeaderType<T>[],
  body: TableBodyDataEntity<T>[],
  sort?: SortType,
  isLoading?: boolean,
  isError?: boolean,
  rowDbClick?: (data: T, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void,
  onClickSort?: (sort: SortType) => void,
}

const Triangle = (props: {
  highlow?: number,
}) => {
  const { highlow } = props;
  if (typeof highlow === 'undefined') return <Box px="6px" />;
  return (
    <Box>
      {!highlow
        ? <TriangleUpIcon />
        : <TriangleDownIcon />
      }
    </Box>
  )
}

export function Table <T extends ObjectBase = {}>(props: Props<T>) {
  const {
    header,
    body,
    isLoading = true,
    isError,
    rowDbClick,
    onClickSort,
    sort,
  } = props;
  const onSort = useCallback((sort_by?: number) => {
    if (!onClickSort || (typeof sort_by === 'undefined') || !sort) return;
    const sortData = {
      sort: sort_by,
      highlow: (sort_by === sort.sort) && !sort.highlow ? 1 : 0,
    }
    onClickSort(sortData);
  }, [onClickSort, sort]);

  const isSelectedSort = useCallback((sort?: SortType, sort_by?: number) => {
    if (!sort || (typeof sort_by === 'undefined')) return undefined;
    if (sort.sort === sort_by) {
      return sort.highlow;
    } else {
      return undefined;
    }
  }, []);

  if (isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          項目の取得でエラーが発生しました。
        </Alert>
      </Box>
    )
  }
  if (isLoading && !body.length)
  return (
    <Box>
      <Alert status="warning">
        <AlertIcon />
        該当する項目が見つかりませんでした
      </Alert>
    </Box>
  )

  return (
    <TableContainer
      w="100%"
      h="100%"
      minH="210px"
      borderRadius="5px 5px 0 0"
      bg={COLOR.bg.sub}
      overflow="auto"
      overflowY="auto"
      filter={DROP_SHADOW}
      px="1rem"
      pb="0.5rem"
    >
      <ChakraTable
        pos="relative"
      >
        <Thead
          position="sticky"
          top="0"
          bg={COLOR.bg.sub}
          borderBottom="none"
          _after={{
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: 0,
            right: 0,
            borderBottom: '1px solid',
            borderColor: 'gray.200',
            PointerEvent: 'none',
          }}

        >
          <Tr>
            { header.map((v, i) => (
              <Th
                _hover={{ bg: 'gray.200' }}
                bg={typeof isSelectedSort(sort, v.sort) !== 'undefined' ? 'gray.200' : undefined}
                textAlign="center"
                key={`th_${i}`}
                textTransform="none"
                position="sticky"
                cursor={isUndefined(v.sort) ? undefined : 'pointer'}
                top="0"
                onClick={() => onSort(v.sort)}
              >
                <Flex justifyContent="space-between">
                  <Box
                    w="100%"
                    textAlign="center"
                    ml="16px"
                  >
                    {v.label}
                  </Box>
                  <Triangle highlow={isSelectedSort(sort, v.sort)} />
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {body.map((trData, i) => (
            <Tr
              key={`body_tr_${i}`}
              onDoubleClick={(e) => rowDbClick?.(trData.data, e)}
              bg={trData.deleted ? 'blackAlpha.100' : undefined}
              cursor="pointer"
              _hover={{
                bg: 'yellow.50'
              }}
            >
              {trData.list.map((tdData, _i) => (
                <Td
                  key={`td_${i}_${_i}`}
                  borderColor={trData.deleted ? 'gray.200' : undefined}
                  textAlign={tdData.align}
                >
                  {tdData.elm}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}