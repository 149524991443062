import { useCallback, useEffect, useMemo, useState } from "react";
import { useParamState } from "../../../../../hooks/utile/use-param-state"
import RootStore from "../../../../../store/root.store"
import { TemplateList } from "../model/template.collection";
import { useLoadingDialog } from '../../../../../hooks/utile/use-loading-dialog';
import { cloneDeep } from 'lodash';
import { useParams } from "react-router-dom";
import { Api } from "../../../../../module/api";
import { useApiErrorDialog } from "../../../../../hooks/utile/use-error-dialog";
import { useMsp } from '../../../../layout/hooks/use-msp';
import { MspTemplateModule } from "../../color-setting/collection/template-color.collection";

export const useMspTemplates = () => {
  const { templates } = RootStore.msp;
  const { getTemplates } = useMsp();
  const load = useLoadingDialog();
  const errDialog = useApiErrorDialog();
  const { articleCode } = useParams();
  const [param, setParam] = useParamState(cloneDeep(MspTemplateModule.format(templates.setting_items.items)));
  const [selectTemplate, setSelectTemplate] = useState(1);

  // 初期化処理
  useEffect(() => {
    setParam(cloneDeep(MspTemplateModule.format(templates.setting_items.items)));
    setSelectTemplate(templates.setting_items.template);
  }, [templates]);

  const onClickSaveData = useCallback(async() => {
    if (!articleCode) return;
    load.push();
    try {
      const result = {
        template_id: templates.template_id,
        setting_items: {
          items: MspTemplateModule.createRequest(param, templates.setting_items.items),
          template: selectTemplate,
        },
      };
      await Api.connect().properties(articleCode).templates().update(result);
      await getTemplates(articleCode);
      RootStore.dialog.pushMessage({
        title: '完了',
        messages: ['テンプレートの設定が完了しました。'],
        buttons: [{ label: 'OK', callback: () => RootStore.dialog.clear() }],
      });
    } catch(e: any) {
      console.error(e);
      errDialog.push(e);
    } finally {
      load.pop();
    }
  }, [param, selectTemplate, templates, articleCode, errDialog, load, getTemplates]);

  return {
    templateParam: param,
    setRequest: setParam,
    selectTemplate, setSelectTemplate,
    onClickSaveData,
  }
}