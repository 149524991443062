import { DialogBody } from '../dialog';
import { LabeledComponentGroup, LabeledComponent } from '../../labeled-component/labeled-component';
import { Input } from '@chakra-ui/react';
import { Radio } from '../../radio/radio';
import { useParamState } from '../../../../hooks/utile/use-param-state';
import { CustomButton } from '../../button/button';
import RootStore from '../../../../store/root.store';
import { useCallback } from 'react';
import { Basket, EditableCageParam, RoomDataEntity } from '../../../pages/msp/basket/module/basket';
import { useDidMount } from '../../../../hooks/utile/use-didmount';
const initialState = {
  type: '',
  label: '',
  disabled: false,
}

type Props = {
  rooms: RoomDataEntity[],
  isNonUnitRoom: boolean,
  callback: (param: Partial<EditableCageParam>) => void,
}

export const EditCageDialog = (props: Props) => {
  const {
    rooms,
    isNonUnitRoom,
    callback,
  } = props;

  const { dialog } = RootStore;
  const [param, setParam] = useParamState(initialState);


  const onClickSubmit = useCallback(() => {
    callback(param);
  }, [callback, param]);

  const onClickCancel = useCallback(() => {
    dialog.pop();
  }, [dialog]);


  const Footer = () => {
    return (
      <>
        <CustomButton
          colorType="secondary"
          onClick={onClickCancel}
        >
          キャンセル
        </CustomButton>
        <CustomButton
          onClick={onClickSubmit}
        >
          OK
        </CustomButton>
      </>
    );
  }

  useDidMount(() => {
    if (rooms.length === 1) {
      const room = rooms[0];
      setParam({...room.editableData});
    }
  })

  return (
    <DialogBody footer={<Footer />}>
      <LabeledComponentGroup labelWidth="100px">
        {!isNonUnitRoom && (
          <LabeledComponent label="タイプ名">
            <Input value={param.type} onChange={(e) => setParam({ type: e.target.value })} />
          </LabeledComponent>
        )}
        <LabeledComponent label={isNonUnitRoom ? '表示名' : '部屋番号名'}>
          <Input value={param.label} onChange={(e) => setParam({ label: e.target.value })} />
        </LabeledComponent>
        {!isNonUnitRoom && (
          <LabeledComponent label="ステータス">
            <Radio
              value={param.disabled ? 0 : 1}
              onChange={(v) => setParam({ disabled: !v })}
              option={[
                { value: 1, label: '販売中' },
                { value: 0, label: '売約済' },
              ]}
            />
          </LabeledComponent>
        )}
      </LabeledComponentGroup>
    </DialogBody>
  )
}