import API from "../../..";
import { fullUrl } from '../../../utile/utile.api';
import { CageDataResponse } from "../../properties/cage-drawing-data";

const path = '/cage-drawing';

export type CageColorsData = {
  /** ○	カゴ図文字色 */
  cage_drawing_text_color: string,
  /** ○	カゴ図住戸表示色 */
  cage_drawing_dwelling_unit_display_color: string,
  /** ○	カゴ図住戸選択色 */
  cage_drawing_dwelling_unit_selection_color: string,
  /** ○	カゴ図共用部表示色 */
  cage_drawing_sharing_department_display_color: string,
}

export type SiteCageDataEntity = CageColorsData & CageDataResponse;

const cageDrawing = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    get: () => API.con.get<{}, SiteCageDataEntity>({ url: full, data: {} }),
  });
}

export default cageDrawing;
