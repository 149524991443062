import { Alert, AlertDescription, AlertIcon, AlertTitle, Center } from "@chakra-ui/react"

export const NotFoundPage = () => {
  return (
    <Center h="100vh" flexDirection="column" gap="1rem">
      <Alert
        status='error'
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        w="fit-content"
        px="10rem"
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            404 NotFound
          </AlertTitle>
        <AlertDescription maxWidth='sm'>
          指定されたページは存在しません
        </AlertDescription>
      </Alert>
    </Center>
  )
}