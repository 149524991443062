import { Flex } from "@chakra-ui/react"

type Props = {
  children: React.ReactNode,
}

export const RightBox = (props: Props) => {
  return (
    <Flex
      justifyContent="flex-end"
      w="100%"
    >
      {props.children}
    </Flex>
  )
}