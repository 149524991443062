import { RadioGroupProps, Radio as ChakraRadio, RadioGroup, RadioProps } from "@chakra-ui/react"
import { Tooltip } from "@mui/material";
import { forwardRef, useCallback } from "react";
import { COLOR } from "../../../../../styles/const";

type RadioButtonProps = {
  value: number,
  label: string,
  isTooltip: boolean,
  toolTipLabel?: string,
} & Omit<RadioProps, 'value' | 'label'>;

const Radio = forwardRef<HTMLInputElement, RadioButtonProps>((props, ref) => {
  const {
    value,
    label,
    isTooltip,
    isDisabled,
    toolTipLabel,
  } = props;
  return (
    <Tooltip
      arrow
      title={toolTipLabel}
      placement="top"
      open={!isTooltip ? false : undefined}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                // offset: [8, -8],
              }
            }
          ]
        }
      }}
      componentsProps={{
        tooltip: {
          sx: {
            background: '#fff',
            border: '1px solid',
            borderColor: COLOR.bg.warning,
            color: COLOR.bg.warning,
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
        arrow: {
          sx: {
            ':before': {
              background: '#fff',
              border: '1px solid',
              borderColor: COLOR.bg.warning,
            },
          },
        },
      }}
    >
      <div>
        <ChakraRadio
          ref={ref}
          id="test"
          value={`${value}`}
          isDisabled={isDisabled}
          key={`radio_${value}`}
          size="lg"
          css={{
            '>span': {
              '&__control': {
                borderColor: 'red',
              }
            }
          }}
          >
         {label}
        </ChakraRadio>
      </div>
    </Tooltip>
  )
});

type Props = {
  onChange?: (value: boolean) => void,
  value: number,
  label: string,
} & Omit<RadioGroupProps, 'children' | 'value' | 'onChange'>;

export function ReleaseRadioArea(
  props: Props,
) {
  const {
    onChange,
    value,
    label,
    ...defaultProps
  } = props;
  const onClick = useCallback((selectValue: string) => {
    onChange?.(!!Number(selectValue))
  }, [onChange]);
  return (
    <RadioGroup
      display="flex"
      gap="1rem"
      value={`${value}`}
      onChange={(v) => onClick(v)}
      css={{
        'label': {
          'span': {
            '&.chakra-radio__control': {
              width: '24px',
              height: '24px',
              '&[data-checked]': {
                ':before': {
                  width: '70%',
                  height: '70%',
                }
              }
            }
          }
        }
      }}
      {...defaultProps}
    >
      <Radio
        value={1}
        size="lg"
        isTooltip={!!label}
        isDisabled={!!label || defaultProps.isDisabled}
        toolTipLabel={label}
        label="公開"
        css={{
          '>span': {
            '&__control': {
              borderColor: 'red',
            }
          }
        }}
      />
      <Radio
        value={0}
        size="lg"
        isTooltip={false}
        label="非公開"
        css={{
          '>span': {
            '&__control': {
              borderColor: 'red',
            }
          }
        }}
      />
    </RadioGroup>
  )
}
