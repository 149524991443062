import { extendTheme } from '@chakra-ui/react';

const Button = {
  baseStyle: {
    bg: 'blue.500',
    color: '#fff',
    borderRadius: '6px',
  },
  variants: {
    solid: {
      _hover: {
        bg: '',
        opacity: '0.7',
        _disabled: {
          bg: '',
          opacity: '0.3',
        }
      },
    }
  }
}

const Checkbox = {
  baseStyle: {
    control: {
      width: '28px',
      height: '28px',
      bg: '#fff',
    },
  },
};

const components = {
  Button,
  Checkbox,
}

const body = {
  backgroundColor: 'gray.50',
};

const global = {
  body,
}

const styles = {
  global,
}

const fonts = {
  heading: "'Noto Sans', sans-serif",
  body: "'Noto Sans', sans-serif",
}

/* チャクラUIのテーマにExtendsする */
export const chakraTheme = extendTheme({ styles, components, fonts });