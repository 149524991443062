import { ViewIcon } from "@chakra-ui/icons";
import { Button, ButtonProps, Img } from "@chakra-ui/react";
import { CustomButton } from "./button";
type Props = {

} & Omit<ButtonProps, 'children'>;

export const PreviewButton = (props: Props) => {
  return (
    <CustomButton
      {...props}
      leftIcon={<ViewIcon />}
      colorType="fifthly"
      p="5px 8px"
      minW="0"
    >
      プレビュー
    </CustomButton>
  )
}