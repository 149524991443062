import { Box, FormControl, Input, InputGroup, InputProps } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Tooltip } from '@mui/material';
import { COLOR } from '../../../styles/const';

type Props = {
  disabledDelete?: boolean,
  validations?: string[],
} & Omit<InputProps, 'type'>;

export const InputDate = (props: Props) => {

  const {
    validations,
    onMouseOver,
    onMouseLeave,
    onFocus,
    onBlur,
  } = props;

  const inputCnt = useRef<HTMLInputElement>(null);

  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inViewport, setInViewPort] = useState(false);
  const viewValidation = useMemo(() => {
    if (!validations?.length || !inViewport) return false;
    return hover || focus;
  }, [focus, hover, inViewport, validations]);
  const focusVisible = useMemo(() => {
    if (validations?.length) {
      return {
        borderColor: COLOR.bg.warning,
        boxShadow: `0 0 0 1px ${COLOR.bg.warning}`,
      }
    } else {
      return undefined
    }
  }, [validations]);

  const _onMouseOver = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    onMouseOver?.(e);
    setHover(true);
  }, [onMouseOver]);
  const _onMouseLeave = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    onMouseOver?.(e);
    setHover(false);
  }, [onMouseLeave]);

  const _onFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(e);
    setFocus(true);
  }, [onFocus]);
  const _onBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(e);
    setFocus(false);
  }, [onBlur]);

  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      if (e.some((v) => v.isIntersecting)) {
        setInViewPort(true);
      } else {
        setInViewPort(false);
      }
    });
    if (inputCnt.current) {
      observer.observe(inputCnt.current);
    }
    return (() => {
      observer.disconnect();
    })
  }, [inputCnt.current]);



  return (
    <FormControl  isInvalid={!!focusVisible} pos="relative">
      <Tooltip
        arrow
        title={(
          <>
            {validations?.map((text, i) => (
              <Box key={`validation_label_${i}`}>{text}</Box>
            ))}
          </>

        )}
        placement="top"
        open={viewValidation}
        // open
        componentsProps={{
          tooltip: {
            sx: {
              background: '#fff',
              border: '1px solid',
              borderColor: COLOR.bg.warning,
              color: COLOR.bg.warning,
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          arrow: {
            sx: {
              ':before': {
                background: '#fff',
                border: '1px solid',
                borderColor: COLOR.bg.warning,
              },
            },
          },
        }}
      >
        <InputGroup w="fit-content" pos="relative">
          <CalendarIcon w="20px" h="20px" pos="absolute" right="21px" top="10px" zIndex={1} cursor="pointer" />
          <Input
            ref={inputCnt}
            type="date"
            placeholder="日付を選択"
            zIndex={2}
            onMouseOver={_onMouseOver}
            onMouseLeave={_onMouseLeave}
            onFocus={_onFocus}
            onBlur={_onBlur}
            sx={{
              "&::-webkit-calendar-picker-indicator": {
                opacity: 0, // 元のアイコンを透明に
                position: "relative",
                backgroundColor: "transparent", // 透明な背景
                width: "25px",
                height: "25px",
                zIndex: 2,
                cursor: "pointer",
              },
            }}
            {...props}
            pos="relative"
          />
        </InputGroup>
      </Tooltip>
    </FormControl>
  );
}
