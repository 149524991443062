import { Box, Center, Spinner } from "@chakra-ui/react"
import { COLOR } from "../../../styles/const"

export const Loading = () => {
  return (
    <Center w="100%" h="100vh" flexDirection="column" gap="1rem">
      <Spinner size="xl" color={COLOR.bg.theme} />
      <Box fontSize="xx-large" color="gray.500">Loading...</Box>
    </Center>
  )
}