import { Box } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { Layout } from "../../../layout/layout"
import { MspLayout } from '../../../layout/msp-layout'
import { CustomButton } from "../../../ui/button/button"
import { LeftBox } from "../../../ui/left-box/left-box"
import { CsvTable } from "./component/csv-table"
import { useMspConfig } from './hooks/use-msp-config'

export const MspConfig = observer(() => {
  const {
    tableProps,
    onClickFileLoad,
    fileLabel,
  } = useMspConfig();
  
  return (
    <Layout
      isMsp
    >
      <MspLayout>
        <LeftBox gap="1rem">
          <CustomButton onClick={onClickFileLoad}>ファイル読み込み</CustomButton>
          <Box fontSize="md">
            {fileLabel}
          </Box>
        </LeftBox>
      </MspLayout>
      <Box
        h="fit-content"
        overflow="hidden"
        borderRadius="8px"
        w="100%"
        filter="drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.1))"
      >
        <CsvTable
          {...tableProps}
        />
      </Box>
    </Layout>
  )
});