import { FlexProps } from "@chakra-ui/react";
import { useCallback, useMemo, useState, DragEvent, ChangeEvent } from "react";
import { useLoadingDialog } from "../../../../../hooks/utile/use-loading-dialog";
import RootStore from "../../../../../store/root.store";
import { CustomButton } from "../../../button/button"

type Props = {
  file: {
    accept?: string,
    isar4h?: boolean,
  },
  postCallback: (file: File) => Promise<void>,
  callback?: () => void,
}

export const useFileDrop = (props: Props) => {
  const {
    file: fileData,
    postCallback,
    callback,
  } = props;
  const { dialog } = RootStore;
  const load = useLoadingDialog();

  const validation = useCallback((name: string) => new Promise<void>((resolve) => {
    const splitName = name.split('.');
    const extension = splitName[splitName.length - 1];
    const acceptExtensions = (fileData.accept?.split(',') ?? [])
      .map((v) => v.replace('.', ''));
    // if (`${extension}` === fileData.accept?.replace('.', '')) {
    if (acceptExtensions.includes(`${extension}`)) {
      resolve();
    } else {
      const messages = fileData?.isar4h
        ? ['isar4hファイルを指定してください']
        : [`${acceptExtensions.join('ファイルか')}ファイルを指定してください`];
      RootStore.dialog.pushMessage({
        title: '確認',
        messages,
        buttons: [
          { label: 'OK', callback: () => RootStore.dialog.pop() }
        ]
      })
    }
  }), [fileData]);

  const [file, setFile] = useState<File | null>(null);
  const [over, setOver] = useState(false);
  const fileName = useMemo(() => {
    if (!file) return 'ファイルを選択してください'
    return file.name;
  }, [file]);


  const fileDragEnter = useCallback((e: DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer?.items.length) setOver(true);
  }, []);

  const fileDragLeave = useCallback((e: DragEvent<HTMLDivElement>) => {
    setOver(false);
  }, []);

  const formatFile = useCallback((file: File) => {
    if (fileData.isar4h) {
      const name = (() => {
        const splitName = file.name.split('.');
        const extension = splitName[splitName.length - 1];
        if (extension === 'zip') {
          splitName.splice(splitName.length - 1, 1);
          return splitName.join('.');
        } else {
          return file.name;
        }
      })();
      console.log(name);
      return new File([file], name, {
        type: file.type || 'application/zip',
        lastModified: file.lastModified,
      })
    } else {
      return file;
    }
  }, [fileData]);

  const fileDrop = useCallback(async(e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const dropFiles = e.dataTransfer.files;
    setOver(false);
    if (!dropFiles || !dropFiles.length) return;
    if (fileData.accept) {
      await validation(dropFiles[0].name);
    }
    setFile(formatFile(dropFiles[0]));
    e.dataTransfer.clearData();
  }, [fileData, validation, formatFile]);

  const onClickLeadFile = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    if (fileData.accept) {
      input.accept = fileData.accept;
    }
      input.onchange = async(event) => {
      const e = event as any as ChangeEvent<HTMLInputElement>;
      const files = e.target.files;
      if (!files ||  !files.length) {
        return;
      }
      const file = files[0];
      if (fileData.accept) {
        await validation(file.name);
      }
      console.log(formatFile(file));
      setFile(formatFile(file));
    }
    input.click();
    input.remove();
  }, [fileData, validation, formatFile]);

  const fileZoneProps: FlexProps = useMemo(() => {
    return {
      bg: !over ? 'transparent' : 'rgba(49,169,206,0.3)',
      onDragOver: (e) => e.preventDefault(),
      onDragEnter: fileDragEnter,
      onDragLeave: fileDragLeave,
      onDrop: fileDrop,
    }
  }, [over, fileDragEnter, fileDragLeave, fileDrop]);

  const onClickSubmit = useCallback(async() => {
    if (file) {
      try {
        load.push();
        await postCallback(file);
        callback?.();
      } catch(e) {
        console.error(e);
      } finally {
        load.pop();
      }
    }
  }, [postCallback, callback, file, load]);

  const onClickCancel = useCallback(() => {
    dialog.pop();
  }, [dialog]);


  const Footer = () => {
    return (
      <>
        <CustomButton
          colorType="secondary"
          onClick={onClickCancel}
        >
          キャンセル
        </CustomButton>
        <CustomButton
          onClick={onClickSubmit}
          isDisabled={!file}
        >
          OK
        </CustomButton>
      </>
    )
  }
  return {
    Footer,
    fileZoneProps,
    fileName,
    onClickLeadFile,
  }
}