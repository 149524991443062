import { Box, Flex, Img } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import plusIcon from '../../../assets/icon/icon_plus.svg';
import { Layout } from "../../layout/layout";
import { CustomButton } from "../../ui/button/button";
import { SettingButton } from "../../ui/button/setting-button";
import { Checkbox } from "../../ui/checkbox/checkbox";
import { SearchInput } from "../../ui/input/search-input";
import { LeftBox } from "../../ui/left-box/left-box";
import { RightBox } from "../../ui/right-box/right-box";
import { DataCount } from "../../ui/table/data-count";
import { Table } from "../../ui/table/table";
import { useCompaniesPage } from './hooks/use-companies';
export const Companies = observer(() => {
  const {
    TableModel,
    totalCount,
    onClickAddCompany,
    onClickTableRow,
    onClickListSetting,
    sort, setRequest,
  }  = useCompaniesPage();
  
  return (
    <Layout>
      <RightBox>
        <CustomButton
         leftIcon={<Img src={plusIcon} />}
         onClick={onClickAddCompany}
        >
         企業新規登録 
        </CustomButton>
      </RightBox>
      <LeftBox gap="1.5rem">
        <SearchInput
          placeholder="企業ID、企業名、企業名カナ"
          maxW="380px"
          onSearch={(v) => setRequest({ keyword: v ?? '' })}
        />
        <Checkbox
          size="xl"
          isChecked={!!sort.suspend_flag}
          onChange={(v) => setRequest({ suspend_flag: sort.suspend_flag ? 0 : 1 })}
        >
          利用停止企業を表示
        </Checkbox>
      </LeftBox>
      <Flex justifyContent="space-between" w="100%" alignItems="flex-end">
        <DataCount count={totalCount} />
        <SettingButton onClick={onClickListSetting} />
      </Flex>
      <Box
        w="100%"
        h="fit-content"
        overflow="hidden"
        borderRadius="8px"
        filter="drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.1))"
      >
        <Table
          {...TableModel}
          rowDbClick={onClickTableRow}
          onClickSort={setRequest}
          sort={sort}
        />
      </Box>
    </Layout>
  )
});