import { makeObservable, observable, action } from 'mobx';
import { EnvData } from '../component/function/initializer';
import { UserData } from '../module/api/routes/is-login/is-login';

export const DEFAULT_LINKS = {
  MANUAL: '/manual.pdf',
  SUPPORT: '/support.pdf',
  SUPPORT_FROM: '/MSPシステム_サポートフォーム（記入用）.pdf',
  RULES: '/rules.pdf'
} as const;

export class EnvStore {
  manual: string = DEFAULT_LINKS.MANUAL;
  support: string = DEFAULT_LINKS.SUPPORT;
  rules: string = DEFAULT_LINKS.RULES;
  support_form: string = DEFAULT_LINKS.SUPPORT_FROM;

  constructor() {
    makeObservable(this, {
      manual: observable,
      support: observable,
      support_form: observable,
      rules: observable,
      setLink: action,
    })
  }

  setLink(data: EnvData['link']) {
    const {
      manual,
      support,
      support_form,
      rules,
    } = data ?? {};
    manual && (this.manual = manual);
    support && (this.support = support);
    rules && (this.rules = rules);
    support_form && (this.support_form = support_form);
  }
  destroy() {
  }

}