import { CheckboxProps, FlexProps } from '@chakra-ui/react';
import { useCallback, useEffect, DragEvent, useState, useMemo, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useApiErrorDialog } from "../../../../../hooks/utile/use-error-dialog";
import { useLoadingDialog } from "../../../../../hooks/utile/use-loading-dialog";
import { Api } from "../../../../../module/api";
import RootStore from "../../../../../store/root.store";
import { COLOR } from '../../../../../styles/const';
import { useMsp } from "../../../../layout/hooks/use-msp";
import { EcoTableBodyDataEntity } from '../component/eco-label-table';

const fileAccept = ['image/jpg', 'image/jpeg'];

export const useEcoLabel = () => {
  const { articleCode } = useParams();
  const {
    getLabels
  } = useMsp();
  const errDialog = useApiErrorDialog();
  const loading = useLoadingDialog();

  const [selectedList, setSelectedList] = useState<string[]>([]);

  // 省エネラベル配列データ整形
  const ecoLabelData = useMemo(() => {
    const data = RootStore.msp.labels?.energy_saving_labels ?? [];
    return data.map((detail) => ({
      id: detail.id,
      label: detail.file_name,
    }));
  }, [RootStore.msp.labels]);

  // テーブルデータ生成
  const tableData = useMemo(() => {
    const result: EcoTableBodyDataEntity[][] = [];
    for (let i = 0; i < ecoLabelData.length; i++) {
      if (!i) result.push([]);
      result[result.length - 1].push({
        data: ecoLabelData[i],
        elm: ecoLabelData[i].label,
      });
      if (i && ((i + 1) % 6 === 0)) result.push([]);
    }
    return result;  
  }, [ecoLabelData]);

  // 一括選択ハンドラ データ
  const allCheck = useMemo(() => {
    return ecoLabelData.every((info) => !!selectedList.find((selectedId) => info.id === selectedId));
  }, [selectedList, ecoLabelData]);
  const onChangeAllCheck = useCallback(() => {
    if (!allCheck) {
      setSelectedList(ecoLabelData.map((v) => v.id));
    } else {
      setSelectedList([]);
    }
  }, [allCheck, ecoLabelData]);

  const checkboxProps: CheckboxProps = useMemo(() => {
    return {
      onChange: onChangeAllCheck,
      isChecked: allCheck,
    }
  }, [
    onChangeAllCheck,
    allCheck,
  ]);

  const prevId = useRef('');
  // 画像選択ハンドラ
  const onSelect = useCallback((id: string, e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
    // 通常クリック時
    if (!e.shiftKey) {
      const findIndex = selectedList.findIndex((v) => v === id);
      if (findIndex > -1) {
        selectedList.splice(findIndex, 1);
      } else {
        selectedList.push(id);
      }
      setSelectedList([...selectedList]);
      prevId.current = id;
      return;
    }
    // シフトクリック時
    const indexes: [number, number] = (() => {
      const prevIndex = ecoLabelData.findIndex((v) => v.id === prevId.current);
      const currentIndex = ecoLabelData.findIndex((v) => v.id === id);
      const resultData: [number, number] = [prevIndex > -1 ? prevIndex : 0, currentIndex];
      resultData.sort((a, b) => a > b ? 1 : -1);
      return resultData;
    })();
    const [
      prevIndex,
      nextIndex,
    ] = indexes;
    const areaIds = ecoLabelData.slice(prevIndex, nextIndex + 1).map((v) => v?.id).filter((v) => v);
    areaIds.forEach((v) => {
      const target = selectedList.find((current) => current === v);
      if (!target) {
        selectedList.push(v);
      }
    });
    setSelectedList([...selectedList]);
    prevId.current = id;
  }, [selectedList, ecoLabelData]);

  const tableProps = useMemo(() => {
    return {
      body: tableData,
      dataClick: (
        data: { id: string } | null,
        e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>
      ) => data && onSelect(data.id, e),
      selectedList,
    }
  }, [onSelect, tableData, selectedList]);

  // ファイル保存処理
  const postImages = useCallback((files: File[]) => {
    if (!articleCode) {
      console.error('article code is not found !!');
      return;
    }
    const checkImage = files.every((img) => fileAccept.includes(img.type));
    if (!checkImage) {
      RootStore.dialog.pushMessage({
        title: '確認',
        messages: ['異なる形式のファイルが含まれています。', 'ファイルは以下形式で送信してください。', '・jpg'],
        buttons: [
          { label: 'OK', callback: () => RootStore.dialog.pop() }
        ]
      })
      return;
    }
    loading.push();
    Api.connect().properties(articleCode).energySavingLabels().post({
      files,
    }).then(async() => {
      await getLabels(articleCode);
      RootStore.dialog.pushMessage({
        title: '完了',
        messages: ['省エネラベルの保存が完了しました。'],
        buttons: [
          { label: 'OK', callback: () => RootStore.dialog.clear() },
        ],
      });
    }).catch((e) => {
      errDialog.push(e);
    }).finally(() => {
      loading.pop();
    })
  }, [articleCode, errDialog, loading, getLabels]);

  // ファイル保存：クリックハンドラ
  const onClickSendFiles = useCallback<() => void>(() => {
    const input = document.createElement('input');
    input.accept = fileAccept.join(',');
    input.type = 'file';
    input.multiple = true;
    input.onchange = (event: any) => {
      const e = event as React.ChangeEvent<HTMLInputElement>;
      const files = Array.from(e.target.files ?? []);
      if (!files.length) return;
      postImages(files);
    }
    input.click();
    input.remove();
  }, [postImages]);

  // ファイル保存：DnDハンドラ
  const onDropSendFiles = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    setOver(false);
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files ?? []);
    if (!files.length) return;
    postImages(files);
  }, [postImages]);

  // 演出・制御用ハンドラ
  const [over, setOver] = useState(false);
  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);
  const onDragEnter = useCallback(() => {
    setOver(true);
  }, []);
  const onDragLeave = useCallback(() => {
    setOver(false);
  }, []);

  // DnD用要素に設定するprops
  const fileZoneProps = useMemo<FlexProps>(() => {
    return {
      bg: !over ? COLOR.bg.sub : 'rgba(49,169,206,0.3)',
      onDragOver,
      onDragEnter,
      onDragLeave,
      onDrop: onDropSendFiles,
    }
  }, [
    over,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDropSendFiles,
  ]);

  // 削除ハンドラ
  const onClickDelete = useCallback(() => {
    if (!selectedList.length || !articleCode) return;
    const deleteRequest = () => {
      loading.push();
      return Api.connect().properties(articleCode).energySavingLabels().delete({
        label_ids: selectedList,
      }).then(async() => {
        setSelectedList([]);
        RootStore.dialog.clear();
        await getLabels(articleCode);
        RootStore.dialog.pushMessage({
          title: '完了',
          messages: [
            'ファイルの削除が完了しました。'
          ],
          buttons: [
            { label: 'OK', callback: () => RootStore.dialog.clear() },
          ]
        });
      }).catch((e) => {
        RootStore.dialog.clear();
        errDialog.push(e);
      }).finally(() => loading.pop());
    }
    RootStore.dialog.pushMessage({
      title: '確認',
      messages: [
        '選択されたファイルを削除します。',
        'よろしいですか？',
      ],
      buttons: [
        { label: 'キャンセル', callback: () => RootStore.dialog.pop(), color: 'secondary' },
        { label: 'OK', callback: () => deleteRequest() },
      ]
    })
  }, [selectedList, articleCode, getLabels, errDialog, loading]);

  const deleteButtonProps = useMemo(() => {
    return {
      onClick: onClickDelete,
      isDisabled: !selectedList.length,
    }
  }, [onClickDelete, selectedList]);

  useEffect(() => {
    if (articleCode) {
      getLabels(articleCode);
    }
  }, [articleCode]);

  return {
    onClickSendFiles,
    fileZoneProps,
    checkboxProps,
    tableProps,
    deleteButtonProps,
  }
}