import { Button, ButtonProps } from "@chakra-ui/react";
import { useEffect, useMemo, } from "react";

export type ButtonColor = 'primary' | 'secondary' | 'thirdly' | 'fourthly' | 'fifthly' | 'warning';

type Props = {
  colorType?: ButtonColor,
} & Omit<ButtonProps, 'bg' | 'background' | 'color'>;

type ColorSetParam = Pick<ButtonProps, 'bg' | 'color'>;

export const CustomButton = (props: Props) => {
  const {
    colorType = 'primary',
    children,
    minW = '150px',
    ...defaultProps
  } = props;
  const colorSet: ColorSetParam = useMemo(() => {
    switch (colorType) {
      case 'primary': 
        return {
          bg: 'blue.500',
          color: '#fff',
        };
      case 'secondary':
        return {
          bg: 'blackAlpha.400',
          color: '#fff',
        }
      case 'thirdly':
        return {
          bg: 'orange.300',
          color: '#fff',
        }
      case 'fourthly':
        return {
          bg: 'green.400',
          color: '#fff',
        }
      case 'fifthly':
        return {
          bg: 'cyan.500',
          color: '#fff',
        }
      case 'warning': 
        return {
          bg: 'red.500',
          color: '#fff',  
        }
    }
  }, [colorType]);
  return (
    <Button
      {...defaultProps}
      { ...colorSet }
      minW={minW}
      autoFocus={false}
    >
      {children}
    </Button>
  )
}