import { MSPColorSettingDataEntity } from "../../../../../module/api/routes/properties/colors";
import { TemplateKeys, TemplatesResponseData } from "../../../../../module/api/routes/properties/templates";

export const TEXT_COLOR_LIST = [
  { value: 1,label: '黒', color: '#000' },
  { value: 2,label: '白', color: '#fff', textColor: '#000' },
  { value: 3,label: '青', color: '#0000cc' },
  { value: 4,label: '緑', color: '#00b050' },
  { value: 5,label: '黄色', color: '#ffff00' },
  { value: 6,label: '橙色', color: '#ed7d31' },
  { value: 7,label: '赤', color: '#ff0000' },
];

export const CAGE_COLOR_LIST = [
  { value: 1, color: '#D9D9D9', selectColor: '#A6A6A6' },
  { value: 2, color: '#E8D9F3', selectColor: '#C9A6E4' },
  { value: 3, color: '#D6DCE5', selectColor: '#8497B0' },
  { value: 4, color: '#DEEBF7', selectColor: '#9DC3E6' },
  { value: 5, color: '#E2F0D9', selectColor: '#A9D18E' },
  { value: 6, color: '#FFF2CC', selectColor: '#FFE699' },
  { value: 7, color: '#FBE5D6', selectColor: '#F4B183' },
  { value: 8, color: '#FFDDDD', selectColor: '#FF0000' },
];

export const CAGE_COMMON_COLOR_LIST = [
  { value: 1, color: '#D9D9D9' },
  { value: 2, color: '#CBCBCB' },
  { value: 3, color: '#DAE3F3' },
  { value: 4, color: '#B4C7E7' },
  { value: 5, color: '#FFF2CC' },
  { value: 6, color: '#FFE699' },
  { value: 7, color: '#E2F0D9' },
  { value: 8, color: '#C5E0B4' },
];

export const GIRDLE_COLOR_LIST = [
  [{ value: 1, color: '#D9D9D9' }, { value: 9, color: '#000000' } ],
  [{ value: 2, color: '#E8D9F3' }, { value: 10, color: '#7030A0' } ],
  [{ value: 3, color: '#D6DCE5' }, { value: 11, color: '#333F50' } ],
  [{ value: 4, color: '#DEEBF7' }, { value: 12, color: '#2E75B6' } ],
  [{ value: 5, color: '#E2F0D9' }, { value: 13, color: '#00B050' } ],
  [{ value: 6, color: '#FFF2CC' }, { value: 14, color: '#DCCA1E' } ],
  [{ value: 7, color: '#FBE5D6' }, { value: 15, color: '#C55A11' } ],
  [{ value: 8, color: '#FFDDDD' }, { value: 16, color: '#FF0000' } ],
];

export class MspColorModule {
  static readonly INITIAL_STATE = {
    cageText: 1,
    cageBox: 1,
    commonBox: 1,
    ecoText: 2,
    ecoBand: 1,
  }
  static createColor(data: MSPColorSettingDataEntity) {
    const {
      cage_drawing_text_color,
      cage_drawing_dwelling_unit_display_color,
      cage_drawing_dwelling_unit_selection_color,
      cage_drawing_sharing_department_display_color,
      energy_saving_text_color,
      energy_saving_band_color,
    } = data;
    const cageText = TEXT_COLOR_LIST.find((v) => v.color === cage_drawing_text_color)?.value ?? 1;
    const cageBox = CAGE_COLOR_LIST.find((v) => (
      v.color === cage_drawing_dwelling_unit_display_color
      && v.selectColor === cage_drawing_dwelling_unit_selection_color
    ))?.value ?? 1;
    const commonBox = CAGE_COMMON_COLOR_LIST.find((v) => v.color === cage_drawing_sharing_department_display_color)?.value ?? 1;
    const ecoText = TEXT_COLOR_LIST.find((v) => v.color === energy_saving_text_color)?.value ?? 2;
    const ecoBand = GIRDLE_COLOR_LIST.flat().find((v) => v.color === energy_saving_band_color)?.value ?? 1;
    return {
      cageText,
      cageBox,
      commonBox,
      ecoText,
      ecoBand,
    }
  }
  static FindTextColor = (value: number, initialIndex: number = 0) => {
    const find = TEXT_COLOR_LIST.find((v) => v.value === value);
    return find?.color ?? TEXT_COLOR_LIST[initialIndex].color;
  }
  static createRequest = (param: ReturnType<typeof MspColorModule['createColor']>) => {
    const {
      cageText,
      cageBox,
      commonBox,
      ecoText,
      ecoBand,
    } = param;
    const cage_drawing_text_color = this.FindTextColor(cageText);
    const cageColorParam = CAGE_COLOR_LIST.find((v) => v.value === cageBox) ?? CAGE_COLOR_LIST[0];
    const cage_drawing_dwelling_unit_display_color = cageColorParam.color;
    const cage_drawing_dwelling_unit_selection_color = cageColorParam.selectColor;
    const commonColorParam = CAGE_COMMON_COLOR_LIST.find((v) => v.value === commonBox) ?? CAGE_COMMON_COLOR_LIST[0];
    const cage_drawing_sharing_department_display_color = commonColorParam.color;
    const energy_saving_text_color = this.FindTextColor(ecoText, 1);
    const ecoBandColor = GIRDLE_COLOR_LIST.flat().find((v) => v.value === ecoBand) ?? GIRDLE_COLOR_LIST.flat()[0];
    const energy_saving_band_color = ecoBandColor.color;
    return {
      cage_drawing_text_color,
      cage_drawing_dwelling_unit_display_color,
      cage_drawing_dwelling_unit_selection_color,
      cage_drawing_sharing_department_display_color,
      energy_saving_text_color,
      energy_saving_band_color,
    }
  }
  static initialColors = (param: MSPColorSettingDataEntity) => {
    const cage_drawing_text_color = TEXT_COLOR_LIST.find((v) => v.color === param.cage_drawing_text_color)?.color
      ?? TEXT_COLOR_LIST[0].color;
    const cageGroup = CAGE_COLOR_LIST.find((v) => v.color === param.cage_drawing_dwelling_unit_display_color)
      ?? CAGE_COLOR_LIST[0];
      const cage_drawing_dwelling_unit_display_color = cageGroup.color;
      const cage_drawing_dwelling_unit_selection_color = cageGroup.selectColor;
    const cage_drawing_sharing_department_display_color = CAGE_COMMON_COLOR_LIST.find((v) => v.color === param.cage_drawing_sharing_department_display_color)?.color
      ?? CAGE_COMMON_COLOR_LIST[0].color;
      const energy_saving_text_color = TEXT_COLOR_LIST.find((v) => v.color === param.energy_saving_text_color)?.color
      ?? TEXT_COLOR_LIST[1].color;
      const energy_saving_band_color: string = GIRDLE_COLOR_LIST.flat().find((v) => v.color === param.energy_saving_band_color)?.color
        ?? GIRDLE_COLOR_LIST.flat()[0].color;
    return {
      cage_drawing_text_color,
      cage_drawing_dwelling_unit_display_color,
      cage_drawing_dwelling_unit_selection_color,
      cage_drawing_sharing_department_display_color,
      energy_saving_text_color,
      energy_saving_band_color,
    }
  }
}

export type MspFormatTemplateData = {
  energy_saving_label: number,
  utility_costs: boolean,
  energy_consumption_rate: boolean,
  insulation_performance: {
    value: boolean,
    ua_value: boolean,
  },
  co2_reduction_rate: boolean,
}
export class MspTemplateModule {
  private static findKey (key: TemplateKeys, param: TemplatesResponseData['setting_items']['items']) {
    return param.find((v) => v.key === key);
  }
  private static bool2number = (data: boolean) => data ? 1 : 0;
  static format = (param: TemplatesResponseData['setting_items']['items']): MspFormatTemplateData => {
    try {
      return {
        energy_saving_label: this.findKey('energy_saving_label', param)?.value ?? 1,
        utility_costs: !!this.findKey('utility_costs', param)?.value,
        energy_consumption_rate: !!this.findKey('energy_consumption_rate', param)?.value,
        insulation_performance: {
          value: !!this.findKey('insulation_performance', param)?.value,
          ua_value: !!this.findKey('insulation_performance', param)?.children?.find((v) => v.key === 'ua_value')?.value,
        },
        co2_reduction_rate: !!this.findKey('co2_reduction_rate', param)?.value,
      }
    } catch(e) {
      throw new Error('テンプレートデータのフォーマットでエラー');
    }
  }
  static createRequest(
    param: MspFormatTemplateData,
    original: TemplatesResponseData['setting_items']['items'],
  ): TemplatesResponseData['setting_items']['items'] {
    console.log(param);
    return original.map((v) => {
      if (v.key === 'insulation_performance') {
        const data = param[v.key];
        const childData = this.bool2number(data.ua_value);
        return {
          ...v,
          value: this.bool2number(data.value),
          children: [
            { key: 'ua_value', value: childData },
          ]
        }
      } else {
        if (v.key === 'energy_saving_label') {
          return {
            ...v,
            value: param[v.key],
          }
        } else {
          return {
            ...v,
            value: this.bool2number(param[v.key]),
          }
        }
      }
    })
  }
}