import { SelectProps, Select as ChakraSelect } from "@chakra-ui/react"
import { ChangeEvent, useCallback } from "react";

export type SelectOptionData<T extends (string | number)> = {
  label: string,
  value: T,
}

type Props<T extends (string | number), K extends (never | true)> = {
  unRequire?: K,
  onChange?: (v: K extends never ? T | undefined : T) => void,
  option?: SelectOptionData<T>[],
  value?: T | undefined,
} & Omit<SelectProps, 'onChange' | 'value'>

export function Select<T extends (string | number), K extends (never | true)>(
  props: Props<T, K>
) {
  const {
    onChange,
    option,
    unRequire,
    ...defaultProps
  } = props;
  const _onChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    if (!option) return;
    const target = option.find((v) => `${v.value}` === e.target.value);
    if (!target || !e.target.value) {
      if (unRequire) {
        onChange?.(undefined as any);
      } else {
        throw new Error('不正な値です。');
      }
    } else {
      onChange?.(target.value);
    }
  }, [option, onChange, unRequire]);
  return (
    <ChakraSelect
      {...defaultProps}
      onChange={_onChange}
    >
      {(option ?? []).map((data, i) => (
        <option value={data.value} key={`option_${i}`}>
          {data.label}
        </option>
      ))}
    </ChakraSelect>
  )
}
