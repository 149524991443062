import API from "../..";
import { fullUrl } from '../../utile/utile.api';
import cageDrawing from './cage-drawing/index';
import energySaving from './energy-saving/index';

const path = '/site';

const site = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    cageDrawing: cageDrawing(full),
    energySaving: energySaving(full),
  });
}

export default site;
