import { useMemo } from "react";
import { SiteEnergySavingBDataEntity, TemplateBImgFirstEnergyKey, TemplateBImgOutWallKey } from "../../../../../../module/api/routes/site/energy-saving";
type OutWallData = {
  title?: string,
  info1?: string,
  thickness1?: number,
  unit1?: string,
  info2?: string,
  thickness2?: number,
  unit2?: string,
}

type FirstEnergyData = {
  name?: string,
  title?: string,
}

export type TemplateBImgFormatData = {
  outWall: { [key in TemplateBImgOutWallKey]: OutWallData },
  firstEnergy: { [key in TemplateBImgFirstEnergyKey]: FirstEnergyData },
} | null;

export const useTemplateBImg = (props: { data: SiteEnergySavingBDataEntity | null, }) => {
  const { data } = props;
  const info = useMemo(() => {
    if (!data) return null;
    const getOutWallData = (key: TemplateBImgOutWallKey) => {
      return {
        title: data[`${key}_title`],
        info1: data[`${key}_insulating_material_info_1`],
        thickness1: data[`${key}_thickness_1`],
        unit1: data[`${key}_unit_1`],
        info2: data[`${key}_insulating_material_info_2`],
        thickness2: data[`${key}_thickness_2`],
        unit2: data[`${key}_unit_2`],
      }
    }
    const outWallKeys = ['outer_wall', 'roof', 'ceiling', 'window', 'floor'] as const;
    const outWallFormat = outWallKeys.reduce((acc, item) => {
      acc[item] = getOutWallData(item);
      return acc;
    }, {} as { [key in TemplateBImgOutWallKey]: OutWallData })

    const getFirstEnergyData = (key: TemplateBImgFirstEnergyKey) => {
      return {
        title: data[`${key}_title`],
        name: data[`${key}_name`],
      }
    }
    const firstEnergyKeys = [
      'heat_eqp',
      'vent_eqp',
      'hot_water_eqp_bath_shower_faucet',
      'lighting_eqp_non_living',
      'cool_eqp',
      'hot_water_eqp_kitchen_faucet',
      'hot_water_eqp_face_wash_faucet',
      'hot_water_eqp_insulated_bathtub',
      'hot_water_eqp_heat_source',
    ] as const;
    const firstEnergyFormat = firstEnergyKeys.reduce((acc, item) => {
      acc[item] = getFirstEnergyData(item);
      return acc;
    }, {} as { [key in TemplateBImgFirstEnergyKey]: FirstEnergyData });
    return {
      outWall: outWallFormat,
      firstEnergy: firstEnergyFormat,
    }
  }, [data]);
  return {
    info
  }
}