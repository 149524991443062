import { Box, Button, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { DialogBody } from '../dialog';
import { useFileDrop } from './hooks/use-file-drop';

type Props = {
  file: {
    accept?: string,
    isar4h?: boolean,
  },
  postCallback: (file: File) => Promise<void>,
  callback?: () => void,
}

export const FileDropDialog = (props: Props) => {
  const {
    Footer,
    fileZoneProps,
    fileName,
    onClickLeadFile,
  } = useFileDrop(props);
  return (
    <DialogBody
      footer={<Footer />}
    >
      <Flex gap="0.5rem" alignItems="center">
        <Button
          border="1px
          solid"
          bg="blackAlpha.50"
          onClick={onClickLeadFile}
        >
          ファイル選択
        </Button>
        <Box
          maxW="340px"
          overflowX="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {fileName}
        </Box>
      </Flex>
      <Box
        py="0.25rem"
        w="100%"
      >
        <Flex
          border="1px dashed"
          justifyContent="center"
          alignItems="center"
          {...fileZoneProps}
          w="30rem"
          maxW="100%"
          h="10rem"
        >
          ファイルをドラッグ＆ドロップしてください
        </Flex>

      </Box>
    </DialogBody>
  )
}