import { Alert, AlertIcon, Box, Center, Flex, Textarea } from "@chakra-ui/react"
import RootStore from '../../../../store/root.store';
import { Layout } from "../../../layout/layout"
import { MspLayout } from "../../../layout/msp-layout"
import { CustomButton } from "../../../ui/button/button";
import { Input } from "../../../ui/input/input";
import { Cage } from "./component/cage/cage";
import { useMspCage } from './hooks/use-msp-basket';
import { COLOR, SIDEBAR_WIDTH } from '../../../../styles/const';
import { observer } from "mobx-react-lite";
import { PreviewCage } from "./component/cage/preview-cage/preview-cage";
import { useCallback, useState } from "react";

export const MspCage = observer(() => {
  const {
    TopComponent,
    onChangeInput,
    onClickSaveData,
    preview,
    ...cageProps
  } = useMspCage();
  const { cageData } = RootStore.msp;
  return (
    <>
      <Layout
        isMsp
      >
        <MspLayout>
          <TopComponent />
          {cageData.cage_drawing_data ? (
            <Box mx="auto" w="100%" maxH="100%" overflowX="auto">
              <Box maxH="calc(100% - 136px)" overflow="auto" whiteSpace="nowrap" h="fit-content" pos="relative" w="fit-content" mx="auto" maxW={`calc(100%)`}>
                <Box h="2px" bg={COLOR.bg.primary} pos="sticky" top="0" w={`calc(${cageProps.model?.floorWidth}pt + 60px)`} />
                <Flex mb="0.25rem" pl="60px" pos="sticky" top="2px" bg={COLOR.bg.primary} whiteSpace="nowrap">
                  {cageProps.model?.viewData[0]?.rooms.map((room) => (
                    <Box key={`room_input_${room.key}`} pl="2pt" bg={COLOR.bg.primary}>
                      {room.width ? (
                        <Input
                          bg="#fff"
                          w={`${room.width - 2}pt`}
                          value={room.headerText}
                          onChange={(e) => onChangeInput?.(room, e)}
                        />
                      ) : (
                        <Box w={`${room.width - 2}pt`} />
                      )}
                    </Box>
                  ))}
                </Flex>
                <Cage {...cageProps}/>
              </Box>
              <Box w="100%" mt="0.5rem">
                <Flex w="100%" justifyContent="center">
                  <Textarea
                    w="800px"
                    maxW="800px"
                    resize="none"
                    bg="#fff"
                    value={RootStore.msp.cageData.cage_drawing_comment ?? ''}
                    onChange={(e) => RootStore.msp.setCageComment(e.target.value)}
                  />
                </Flex>
              </Box>
              <Box textAlign="center">
                <CustomButton onClick={() => onClickSaveData?.()} mt="0.5rem">
                  設定反映
                </CustomButton>
              </Box>
            </Box>
          ) : (
            <Box w="100%">
              <Alert status="warning">
                <AlertIcon />
                かご図データファイルが登録されていません
              </Alert>
            </Box>
          )}
        </MspLayout>
      </Layout>
      <PreviewCage
        isOpen={!!preview?.value}
        onClose={(v) => preview?.set(v)}
        model={cageProps.model}
      />
    </>
  )
});