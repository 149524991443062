import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartData, ChartOptions } from 'chart.js';
import { Chart } from 'chart.js';
import { Box } from '@chakra-ui/react';
import { TemplateBProps } from '../../component/comparison-card/comparison-b-card';

Chart.register(ChartDataLabels);

export const PieGraph = (props: TemplateBProps) => {
  const {
    data: templateBData,
  } = props;

  const data: ChartData<'pie'> = {
    datasets: [
      {
        label: '# of Votes',
        data: [
          Math.round(templateBData?.heating_percentage ?? 0),
          Math.round(templateBData?.cooling_percentage ?? 0),
          Math.round(templateBData?.ventilation_percentage ?? 0),
          Math.round(templateBData?.hot_water_percentage ?? 0),
          Math.round(templateBData?.lighting_percentage ?? 0),
          Math.round(templateBData?.other_percentage ?? 0),
        ],
        backgroundColor: [
          '#D87D7C',
          '#8CCCF2',
          '#8FB559',
          '#EDB7DD',
          '#F4D55E',
          '#BEBEBE',
        ],
        borderColor: [
          '#D87D7C',
          '#8CCCF2',
          '#8FB559',
          '#EDB7DD',
          '#F4D55E',
          '#BEBEBE',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'pie'> = {
    layout: {
      padding: 15,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: true,
        formatter: (value: number, context) => {
          const data = context.chart.data.datasets[0].data;
          const total = (data as number[]).reduce((acc, curr) => acc + curr, 0);
          const percentage = Math.round((value / total) * 100) + '%';
          return percentage;
        },
        color: '#000',
        font: {
          size: 11,
        },
        anchor: 'end',
        align: 'end',
        offset: (ctx) => {
          const value = Number(ctx.dataset.data[ctx.dataIndex] ?? 0);
          const pos = value.toString().length > 1 ? -32 : -27;
          return Number(ctx.dataset.data[ctx.dataIndex] ?? 0) < 4
            ? -6
            : pos;
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <Pie data={data} options={options} />
  );
}