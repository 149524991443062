import { Box } from "@chakra-ui/react";
import RootStore from "../../../../store/root.store";
import { CopyLink } from "../../../pages/articles/component/copy-link";
import { LabeledComponent, LabeledComponentGroup } from "../../labeled-component/labeled-component"
import { DialogBody } from "../dialog"
import { useArticleDetail } from './hooks/use-article-detail';

type Props = {
  id: string,
}

export const ArticleDetailDialog = ({ id }: Props) => {
  const {
    article,
    CopyLinkComponent,
  } = useArticleDetail(id);
  const {
    user,
  } = RootStore;
  return (
    <DialogBody
      bodyProps={{ w: 'fit-content', maxW: '100%', minW: '200px' }}
    >
      <LabeledComponentGroup labelWidth='210px' minW="calc(350px + 1rem)">
        <LabeledComponent label="企業ID" invisible={!user.system_admin_flag}>
          {article?.company_login_id ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="企業名" invisible={!user.system_admin_flag}>
          {article?.company_name ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="物件No">
          {article?.number ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="物件名">
          {article?.name ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="作成日">
          {article?.registration_date?.replaceAll('-', '/') ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="最終作業日">
          {article?.last_update_date?.replaceAll('-', '/') ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="公開設定">
          {!article ? '-' : article.site_release_flag ? '公開' : '非公開'}
        </LabeledComponent>
        <LabeledComponent label="公開終了日">
          {article?.site_release_end_expected_date?.replaceAll('-', '/') ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="サブドメイン名">
          {article?.subdomain ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="サイトURL">
          <CopyLinkComponent />
        </LabeledComponent>
      </LabeledComponentGroup>
    </DialogBody>

  )
}