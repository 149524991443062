import { CheckboxProps, Checkbox as ChakraCheckbox } from "@chakra-ui/react"
type Props = {

} & CheckboxProps;
export const Checkbox = (props: Props) => {
  const {
    children,
    size = 'xl',
    fontSize = 'lg',
    ...defaultProps
  } = props;
  return (
    <ChakraCheckbox
      {...defaultProps}
      size={size}
       fontSize={fontSize}
       sx={{
        'svg': {
          strokeWidth: '1 !important',
        }
       }}
    >
      {children}
    </ChakraCheckbox>
  )
}