import { Box, Flex, FlexProps, Image, Text } from "@chakra-ui/react";
import { Link, useLocation, useParams } from 'react-router-dom';
import { SidebarDataEntity } from "../../../../routes/routing-path";
import navIcon from '../../../../assets/icon/icon_nav.svg';
import { useMemo } from "react";
import { useNavItem } from "../hooks/use-nav-item";
import { observer } from "mobx-react-lite";

type Props = {
} & SidebarDataEntity;

const ParentLabel = (props: { label: string }) => {
  return (
    <Box
      p="10px 24px"
    >
      {props.label}
    </Box>
  );
}

type NavLinkProps = {
  index?: number,
} & SidebarDataEntity['children'][0];

const NavLink = observer((props: NavLinkProps) => {
  const {
    path, label, index,
  } = props;
  const { pathname } = useLocation();
  const bg = useMemo(() => {
    if (pathname === path) return 'gray.200';
    return undefined;
  }, [pathname, path]);

  const { articleCode } = useParams();
  const { isDisabled } = useNavItem(articleCode);
  const extendStyle: FlexProps = useMemo(() => {
    const disabled = isDisabled(index);
    if (disabled) {
      return {
        opacity: '0.3',
        pointerEvents: 'none',
        userSelect: 'none',
      }
    } else {
      return {};
    }
  }, [isDisabled, index]);

  return (
    <Box
      fontSize="md"
      bg={bg}
      h="44px"
      fontWeight={bg ? 'bold' : 'normal'}
      _hover={{
        bg: 'gray.200',
      }}
      {...extendStyle}
    >
      <Link to={path}>
        <Flex w="100%" h="100%" p="8px 26px">
          <Image src={navIcon} alt="nav_icon" />
          <Text>
            {label}
          </Text>
        </Flex>
      </Link>
    </Box>
  )
});

export const NavItem = (props: Props) => {
  const { 
    label,
    isAdmin,
    children,
   } = props;
  // Admin表示専用表示かつ、Adminログインでなければ空で返す
  //  if (!isAdmin && ) {
  //   return <></>
  //  }
  return (
    <Box>
      <ParentLabel label={label} />
      <Box fontSize="large">
        {children.map((data) => (
          <NavLink
            key={`link_to_${data.path}`}
            {...data}
          />
        ))}
      </Box>
    </Box>
  )
}