import { Text, Flex, Table, TableContainer, Tbody, Td, Tr, Box, Thead, Th } from "@chakra-ui/react"
import { useMemo } from "react"
import { TemplateBProps } from "../../component/comparison-card/comparison-b-card"

export const UaTableB = (props: TemplateBProps) => {
  const {
    data,
  } = props;
  return (
    <TableContainer>
      <Table variant="unstyled" h="84px">
        <Tbody
          css={{
            td: {
              padding: '0.5rem',
              textAlign: 'center',
            }
          }}
        >
          <Tr>
            <Td rowSpan={2} fontWeight="bold" w="134px" bg="#F5E0C9" borderRight="2px" p="0.5rem 0.25rem !important">
              <Flex flexDirection="column" gap="0.25rem" justifyContent="center" alignItems="center">
                <Flex w="fit-content" fontSize="sm">外皮平均熱貫流率</Flex>
                <Flex w="fit-content" fontSize="sm">
                  <Text as="span" fontWeight="bold">UA値</Text>
                  <Text as="span">[W/(m2･K)]</Text>
                </Flex>
              </Flex>
            </Td>
            <Td fontWeight="bold" bg="#C0BEE2" borderRight="2px">省エネ基準</Td>
            <Td fontWeight="bold" bg="#C0E097" borderRight="2px">ZEH基準の家</Td>
            <Td fontWeight="bold" bg="#E4A53B" borderRight="2px">あなたの家</Td>
          </Tr>
          <Tr
            fontWeight="bold"
            bg="#E6E6E6"
            css={{
              '>td': {
                fontSize: '18px'
              },
            }}
          >
            <Td
              pos="relative"
              _after={{
                content: '">"',
                fontSize: '20px',
                pos: 'absolute',
                right: '-5px',
                color: '#000',
              }}
              h="42px"
            >
              {data?.ua_energy_saving ?? 0}
            </Td>
            <Td
              pos="relative"
              _after={{
                content: '">"',
                fontSize: '20px',
                pos: 'absolute',
                right: '-5px',
                color: '#000',
              }}
              h="42px"
            >
              {data?.ua_zeh ?? 0}
            </Td>
            <Td
              borderRight="2px"
              fontSize="20px !important"
              color="#5571C0"
              borderColor="#000"
              h="42px"
            >
              {data?.ua ?? 0}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export const EnergyTableB = (props: TemplateBProps) => {
  const {
    data,
  } = props;
  return (
    <TableContainer>
      <Table variant="unstyled" w="100%" h="84px">
        <Tbody
          css={{
            td: {
              padding: '0.5rem',
              textAlign: 'center',
              width: '172px'
            }
          }}
        >
          <Tr>
            <Td fontWeight="bold" borderRight="1px" lineHeight="1rem" borderLeft="1px">
              <Text fontSize="10px">基準一時エネルギー消費量</Text>
              <Text fontSize="12px">省エネ基準の家 [MJ]</Text>
            </Td>
            <Td fontWeight="bold" borderRight="1px" lineHeight="1rem">
              <Text fontSize="10px">設計一時エネルギー消費量</Text>
              <Text fontSize="12px">あなたの家 [MJ]</Text>
            </Td>
            <Td fontWeight="bold" borderRight="1px" fontSize="13px">
              削減率(再エネ除く)
            </Td>
            <Td fontWeight="bold" borderRight="1px" fontSize="13px">
              削減率(再エネ含む)
            </Td>
          </Tr>
          <Tr fontWeight="bold" bg="#E6E6E6">
            <Td
              borderRight="1px"
              fontWeight="bold"
              fontSize="18px"
              borderLeft="1px"
            >
              {(data?.primary_energy_standard ?? 0).toLocaleString()}
              </Td>
            <Td
              borderRight="1px"
              fontWeight="bold"
              fontSize="18px"
            >
              {(data?.primary_energy_design ?? 0).toLocaleString()}
            </Td>
            <Td
              borderRight="1px"
              fontSize="lg"
              color="#fff"
              borderColor="#000"
              bg="#D56425"
              fontWeight="bold"
            >
              <Box>
                <Text fontSize="20px">
                  {data?.energy_reduction_no_renewable ?? 0} % 削減
                </Text>
              </Box>
            </Td>
            <Td
              borderRight="1px"
              fontSize="lg"
              color="#fff"
              borderColor="#000"
              bg="#D56425"
              fontWeight="bold"
            >
              <Box>
                <Text fontSize="20px">
                  {data?.energy_reduction_with_renewable ?? 0} % 削減
                </Text>
              </Box>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export const EnergyDataTable = (props: TemplateBProps) => {
  const {
    data: templateBData,
  } = props;
  const data = useMemo(() => {
    return [
      { color: '#D87D7C', per: Math.round(templateBData?.heating_percentage ?? 0), mj: templateBData?.heating_equipment ?? 0, label: '暖房設備' },
      { color: '#8CCCF2', per: Math.round(templateBData?.cooling_percentage ?? 0), mj: templateBData?.cooling_equipment ?? 0, label: '冷房設備' },
      { color: '#8FB559', per: Math.round(templateBData?.ventilation_percentage ?? 0), mj: templateBData?.ventilation_equipment ?? 0, label: '換気設備' },
      { color: '#EDB7DD', per: Math.round(templateBData?.hot_water_percentage ?? 0), mj: templateBData?.hot_water_supply_equipment ?? 0, label: '給湯設備' },
      { color: '#F4D55E', per: Math.round(templateBData?.lighting_percentage ?? 0), mj: templateBData?.lighting_equipment ?? 0, label: '照明設備' },
      { color: '#BEBEBE', per: Math.round(templateBData?.other_percentage ?? 0), mj: templateBData?.other_equipment ?? 0, label: 'その他の設備' },
    ]
  }, []);
  return (
    <TableContainer>
      <Table
        variant="unstyled"
        css={{
          tr: {
            borderBottom: '1px solid',
          },
          td: { padding: '4px' },
          th: { padding: '4px' },
        }}
      >
        <Thead>
          <Tr>
            <Th colSpan={4} fontWeight="bold" color="#000">
              設備別設計一時エネルギー消費量
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((v, i) => (
            <Tr
              key={`key_${i}`}
              css={{
                '>td': {
                  fontSize: '14px',
                }
              }}
            >
              <Td>
                <Box w="18px" h="18px" bg={v.color} />
              </Td>
              <Td fontWeight="bold">
                {v.label}
              </Td>
              <Td textAlign="right" w="100%">
                {`${v.per} `}%
              </Td>
              <Td>（</Td>
              <Td textAlign="right">
                {`${v.mj.toLocaleString()}`}&nbsp;MJ/年 )
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
