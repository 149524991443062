import { Chart, ChartData, ChartOptions } from "chart.js"
import { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { TemplateBProps } from "../../component/comparison-card/comparison-b-card";
Chart.register(ChartDataLabels);

export const BarChart = (props: TemplateBProps) => {
  const {
    data: templateBData,
  } = props;

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        max: 1.00,
        ticks: {
          stepSize: 0.10,
          callback: (v) => {
            if (typeof v === 'string') return v;
            return v.toFixed(2);
          }
        },
        grid: {
          display: true,
        },
      },
      y: {
        ticks: {
          font: {
            weight: 'bold',
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: true,
        color: (context) => {
          // 棒グラフの値の色を設定（特定のインデックスのみに適用）
          return !context.dataIndex ? 'red' : 'black';
        },
        anchor: 'end',
        align: 'end',
        formatter: (value) => {
          return value.toFixed(2);
        },
        font: (ctx: any) => {
          return {
            size: '16px',
            weight: !ctx.dataIndex ? 'bold' : 'normal',
          } as any
        },
      }
    },
    maintainAspectRatio: false,
  };

  const data: ChartData<'bar'> = useMemo(() => {
    return {
      labels: ['あなたの家', 'ZEH基準の家', '省エネ基準の家'],
      datasets: [
        {
          label: '',
          data: [
            templateBData?.ua ?? 0,
            templateBData?.ua_zeh ?? 0,
            templateBData?.ua_energy_saving ?? 0,
          ],
          backgroundColor: ['#EFBF2D', '#B4D091', '#BEBFDB'],
          barThickness: 25,
        },
      ],  
    }
  }, []);
  return (
    <Bar
      data={data}
      options={options}
    />
  )
}