import API from "../..";
import { ListDataWrap } from "../../../../styles/api-types";
import { fullUrl, requireId } from "../../utile/utile.api";
import displayItems from "./display-items";
import items from "./items";

// 一覧取得
export type CompanyListDataEntity = {
  /** ○	企業ID */
  id: string,
  /** ○	ログインID */
  login_id: string,
  /** ○	企業名 */
  name: string,
  /** ○	企業名カナ */
  name_kana: string,
  /** ○	業種 */
  industry: string,
  /** ○	都道府県 */
  prefecture: number,
  /** ○	住所1 */
  address1: string,
  /** ○	住所2 */
  address2: string,
  /** ○	担当者部署名 */
  responsible_person_department_sign: string,
  /** ○	担当者名 */
  responsible_person_name: string,
  /** ○	担当者名カナ */
  responsible_person_name_kana: string,
  /** ○	担当者電話番号1 */
  responsible_person_phone_number1: string,
  /** ○	担当者電話番号2 */
  responsible_person_phone_number2: string,
  /** ○	担当者メールアドレス */
  responsible_person_email_address: string,
  /** ○	登録日 */
  registration_date: string,
  /** ○	利用期限 */
  suspend_date: string,
  /** ○	利用停止フラグ(false:利用中 true:利用停止) */
  suspend_flag: boolean,
  /** 管理者フラグ */
  system_admin_flag: boolean,
}

type CompanyListResponse = ListDataWrap<{
  companies: CompanyListDataEntity[],
}>
export type CompanyListRequestParam = {
  /** ログインID、企業名、企業名カナの検索 */
  keyword?: string,
  /** 利用停止フラグ 0:利用停止企業を非表示 1:利用停止企業を表示 デフォルト1 */
  suspend_flag?: number,
  /** 並替基準列 1:企業ID 2:企業名 3:業種 4:都道府県 5:企業住所1 6:企業住所2 7:担当者部署名 8:担当者名 9:担当者名(カナ) 10:担当者電話番号1 11:担当者電話番号2 12:担当者メールアドレス 13:登録日 14:利用期限 15:利用設定 デフォルト13 */
  sort?: number,
  /** 並替方法 0:昇順 1:降順 デフォルト0} */
  highlow?: number,
};
// 詳細取得
export type CompanyDetailDataEntity = {
  /** ○	企業ID */
  id: string,
  /** 	○	ログインID */
  login_id: string,
  /** ○	企業名 */
  name: string,
  /** ○	企業名カナ */
  name_kana: string,
  /** ○	業種 */
  industry: string,
  /** ○	都道府県 */
  prefecture: number,
  /** ○	住所1 */
  address1: string,
  /** ○	住所2 */
  address2: string,
  /** ○	担当者部署名 */
  responsible_person_department_sign: string,
  /** ○	担当者名 */
  responsible_person_name: string,
  /** ○	担当者名カナ */
  responsible_person_name_kana: string,
  /** ○	担当者電話番号1 */
  responsible_person_phone_number1: string,
  /** ○	担当者電話番号2 */
  responsible_person_phone_number2: string,
  /** ○	担当者メールアドレス */
  responsible_person_email_address: string,
  /** ○	登録日 */
  registration_date: string,
  /** ○	利用期限 */
  suspend_date: string,
  /** ○	利用停止フラグ(false:利用中 true:利用停止) */
  suspend_flag: boolean,
  /** 管理者フラグ */
  system_admin_flag: boolean,
}

type CompanyDetailResponse = {
  company: CompanyDetailDataEntity,
}

// 新規登録
export type CompanyAddRequestParam = {
  /** ○	企業名 */
  name: string,
  /** ○	企業名カナ */
  name_kana: string,
  /** ○	パスワード */
  password: string,
  /** 業種 */
  industry?: string,
  /** 	都道府県 */
  prefecture?: number,
  /** 	住所1 */
  address1?: string,
  /** 	住所2 */
  address2?: string,
  /** 	担当者部署名 */
  responsible_person_department_sign?: string,
  /** 	担当者名 */
  responsible_person_name?: string,
  /** 	担当者名カナ */
  responsible_person_name_kana?: string,
  /** 	担当者電話番号1 */
  responsible_person_phone_number1?: string,
  /** 	担当者電話番号2 */
  responsible_person_phone_number2?: string,
  /** 	担当者メールアドレス */
  responsible_person_email_address?: string,
}
// 更新
export type CompanyUpdateRequestParam = Omit<CompanyAddRequestParam, 'password'> & {
  password?: string,
  /** ○	利用期限 */
  suspend_date: string,
  /**	○	利用停止フラグ(false:利用中 true:利用停止) */
  suspend_flag: boolean,
}

const path = '/companies';

const companies = (url: string) => (companyId?: string) => {
  const full = fullUrl(url, path);
  return ({
    getList: (param: CompanyListRequestParam) => API.con.get<CompanyListRequestParam, CompanyListResponse>({ url: full, data: param }),
    getDetail: () => requireId(
      () => API.con.get<{}, CompanyDetailResponse>({ url: `${full}/${companyId}`, data: {} }),
      companyId,
    ),
    add: (param: CompanyAddRequestParam) => API.con.post<CompanyAddRequestParam, {}>({ url: full, data: param }),
    update: (param: CompanyUpdateRequestParam) => API.con.put<CompanyUpdateRequestParam, {}>({ url: `${full}/${companyId}`, data: param }),
    /* ヘッダー一覧系 */
    items: items(full),
    displayItems: displayItems(full),
    
  })
}

export default companies;

export const initialCompanyState: CompanyAddRequestParam = {
  name: '',
  name_kana: '',
  password: '',
  industry: '',
  prefecture: 1,
  address1: '',
  address2: '',
  responsible_person_department_sign: '',
  responsible_person_name: '',
  responsible_person_name_kana: '',
  responsible_person_phone_number1: '',
  responsible_person_phone_number2: '',
  responsible_person_email_address: '',
}

export const initialEditCompanyState: CompanyUpdateRequestParam = {
  ...initialCompanyState,
  suspend_date: '',
  suspend_flag: false,
}