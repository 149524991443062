import { useCallback, useMemo, useState } from "react";
import { useParamState } from "../../../../../hooks/utile/use-param-state";
import RootStore from "../../../../../store/root.store";
import { CustomButton } from "../../../button/button"
import { initialArticleState } from '../../../../../module/api/routes/properties/index';
import { InputUtile } from "../../../../../module/utile/input-rurules";
import { useValidation } from "../../../../../hooks/utile/use-validation";
import { CompanyListDataEntity } from '../../../../../module/api/routes/companies/index';
import { useDidMount } from "../../../../../hooks/utile/use-didmount";
import { Api } from "../../../../../module/api";
import { useApiErrorDialog } from "../../../../../hooks/utile/use-error-dialog";

type Props = {
  getList: () => void,
}

export const useAddArticleDialog = (props: Props) => {
  const {
    getList,
  } = props;

  const { dialog } = RootStore;
  const { validAll } = useValidation();
  const errDialog = useApiErrorDialog();
  const { system_admin_flag } = RootStore.user;
  const [load, setLoad] = useState(false);
  const [companies, setCompanies] = useState<CompanyListDataEntity[]>([]);
  const companyOptions = useMemo(() => {
    return companies.map((v) => ({
      label: v.name,
      value: v.id,
    }))
  }, [companies]);
  const [request, setRequest] = useParamState(initialArticleState, {
    subdomain: InputUtile.subdomain,
  });

  const validation = useMemo(() => {
    return {
      companies: system_admin_flag ? validAll(request.name, 'require') : [],
      name: validAll(request.name, 'require', 'length40'),
      subdomain: validAll(request.subdomain, 'require', 'length63', 'subdomain', 'subdomain_reservation'),
    };
  }, [request, validAll, system_admin_flag])

  const isDisabled = useMemo(() => {
    return !!Object.values(validation).flat().length;
  }, [validation]);

  const onClickSubmit = useCallback(() => {
    setLoad(true);
    Api.connect().properties().add(request)
    .then(() => {
      RootStore.dialog.pushMessage({
        messages: ['登録が完了しました。'],
        buttons: [
          { label: 'OK', callback: () => {
            getList();
            dialog.clear();
          }}
        ],
      })  
    }).catch((e) => errDialog.push(e))
    .finally(() => setLoad(false));
  }, [dialog, request, errDialog, getList]);

  const onClickCancel = useCallback(() => {
    dialog.pop();
  }, [dialog]);


  const Footer = () => {
    return (
      <>
        <CustomButton
          colorType="secondary"
          onClick={onClickCancel}
          isDisabled={load}
        >
          キャンセル
        </CustomButton>
        <CustomButton
          onClick={onClickSubmit}
          isDisabled={isDisabled || load}
        >
          OK
        </CustomButton>
      </>
    )
  }

  const getCompanyList = useCallback((isInit?: boolean) => {
    Api.connect().companies().getList({ suspend_flag: 0 })
      .then((res) => {
        setCompanies(res.data.companies);
        isInit && setRequest({ company_id: res.data.companies[0]?.id ?? '' });
      }).catch(() => {});
  }, [setRequest]);

  useDidMount(() => {
    if (system_admin_flag) {
      getCompanyList(true);
    }
  })
  return {
    Footer,
    validation,
    request,
    setRequest,
    companyOptions,
  }
}