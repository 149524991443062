import { Route, Routes, Navigate } from "react-router-dom"
import { Articles } from "../component/pages/articles/articles.page";
import { Companies } from "../component/pages/companies/companies.page";
import { Login } from "../component/pages/login/login.page";
import { MspCage } from "../component/pages/msp/basket/msp-basket";
import { MspColorSetting } from "../component/pages/msp/color-setting/color-setting";
import { MspConfig } from "../component/pages/msp/config/msp-config";
import { EcoLabel } from "../component/pages/msp/eco-label/eco-label";
import { MspTemplate } from "../component/pages/msp/template/msp-template";
import { RoutingPath } from './routing-path';

export const RouteConfig = () => {
  return (
    <Routes>
      <Route path={RoutingPath.login} element={<Login />} />
      <Route path={RoutingPath.companies} element={<Companies />} />
      <Route path={RoutingPath.articles} element={<Articles />} />
      <Route path={RoutingPath.mspConfig} element={<MspConfig />} />
      <Route path={RoutingPath.mspBasket} element={<MspCage />} />
      <Route path={RoutingPath.mspLabel} element={<EcoLabel />} />
      <Route path={RoutingPath.mspTemplate} element={<MspTemplate />} />
      <Route path={RoutingPath.mspColor} element={<MspColorSetting />} />
      <Route path="*" element={<Navigate to={RoutingPath.login} />} />
    </Routes>
  )
}