import { observer } from "mobx-react-lite"
import RootStore from "../../../../store/root.store"
import { Input } from "../../input/input"
import { LabeledComponent, LabeledComponentGroup } from "../../labeled-component/labeled-component"
import { Select } from "../../select/select"
import { DialogBody } from "../dialog"
import { useAddArticleDialog } from "./hooks/use-add-article"

type Props = {
  getList: () => void,
}

export const ArticleAddDialog = observer((props: Props) => {
  const {
    getList,
  } = props;
  const {
    Footer,
    validation,
    request,
    setRequest,
    companyOptions,
  } = useAddArticleDialog({ getList });

  const { system_admin_flag } = RootStore.user;

  return (
    <DialogBody
      bodyProps={{ w: '600px', maxW: '100%', minW: '200px' }}
      footer={<Footer />}
    >
      <LabeledComponentGroup labelWidth='210px' minW="calc(350px + 1rem)">
        {system_admin_flag && (
          <LabeledComponent label="企業名" require>
            <Select
              value={request.company_id ?? ''}
              onChange={(v) => setRequest({ company_id: v })}
              option={companyOptions}
            />
          </LabeledComponent>
        )}
        <LabeledComponent label="物件名" require>
          <Input
            value={request.name}
            onChange={(e) => setRequest({ name: e.target.value })}
            validations={validation.name}
          />
        </LabeledComponent>
        <LabeledComponent label="サブドメイン名" require>
          <Input
            value={request.subdomain}
            onChange={(e) => setRequest({ subdomain: e.target.value })}
            validations={validation.subdomain}
          />
        </LabeledComponent>
      </LabeledComponentGroup>
    </DialogBody>

  )
});