import { AxiosResponse, CancelTokenSource } from 'axios';

export type ConnectionStatus = 'ready' | 'connecting';

export type ConnectionEvent = {
  'on-change:status': (status: ConnectionStatus) => void,
  'on-change:count': (count: number) => void,
};

export type ConnectionRequest<T = any> = {
  url: string,
  data?: T,
  headers?: { [key: string]: string },
};

export type ConnectionOption = {
  priority?: number,
  directParam?: boolean,
  isBlob?: boolean,
  cancelable?: CancelTokenSource,
};

export interface IConnection {
  get<TRequest, TResponse>(request: ConnectionRequest<TRequest>, option?: ConnectionOption): Promise<AxiosResponse<TResponse>>;
  post<TRequest, TResponse>(request: ConnectionRequest<TRequest>, option?: ConnectionOption): Promise<AxiosResponse<TResponse>>;
  put<TRequest, TResponse>(request: ConnectionRequest<TRequest>, option?: ConnectionOption): Promise<AxiosResponse<TResponse>>;
  delete<TRequest, TResponse>(request: ConnectionRequest<TRequest>, option?: ConnectionOption): Promise<AxiosResponse<TResponse>>;
  stop: () => void;
}

export const DEFAULT_CONNECTION_OPTION: ConnectionOption = {
  priority: 0,
};

export const CreateQueryParams = (param: { [key: string]: any }) => {
  const encoder = encodeURIComponent;
  const keys = Object.keys(param);
  // TODO : 値のバリデーション処理を実装
  // keys.forEach(key => {});
  return keys.map(key => {
    const data = param[key];
    if (Array.isArray(data)) {
      return data.map((v, i) => {
        return `${encoder(`${key}[${i}]`)}=${encoder(v)}`;
      }).join('&')
    }
    return `${encoder(key)}=${encoder(param[key])}`;
  }).join('&');
}
