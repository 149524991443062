import { Box, VStack, StackProps } from '@chakra-ui/react';
import { HEADER_HEIGHT, SIDEBAR_WIDTH } from "../../../styles/const"

type Props = {
  children: React.ReactNode,
  vStackProps?: StackProps,
}

export const MainGrid = (props: Props) => {
  return (
    <Box
      overflow="hidden"
      h={`calc(100vh - ${HEADER_HEIGHT})`}
      maxW={`calc(100vw - ${SIDEBAR_WIDTH})`}
      w="100%"
      m="0"
    >
      <VStack
        gap="1rem"
        width="100%"
        height="100%"
        overflow="auto"
        p="2.25rem"
        pb="20px"
        {...props.vStackProps}
      >
        {props.children}
      </VStack>
    </Box>
  )
}