import { Box, Flex, Img } from "@chakra-ui/react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import iconEdit from '../../../../../assets/icon/icon_edit.svg'
import { useApiErrorDialog } from "../../../../../hooks/utile/use-error-dialog"
import { useLoadingDialog } from "../../../../../hooks/utile/use-loading-dialog"
import { Api } from "../../../../../module/api"
import RootStore from "../../../../../store/root.store"
import { useMsp } from "../../../../layout/hooks/use-msp"
import { CustomButton } from "../../../../ui/button/button"
import { PreviewButton } from "../../../../ui/button/preview-button"
import { EditCageDialog } from "../../../../ui/dialog/edit-cage/edit-cage.dialog"
import { FileDropDialog } from "../../../../ui/dialog/file-drop-dialog/file-drop-dialog"
import { Basket, MergeRoomData, RoomDataEntity } from '../module/basket'
import { ColorDarken } from '../../../../../lib/color-utile';

export type UseCageReturnType = {
  TopComponent: () => JSX.Element,
  model: Basket | null,
  onSelectRoom: (roomData: RoomDataEntity) => void,
  roomStyle: (roomData: RoomDataEntity) => React.SVGProps<SVGPathElement>,
  mergeRoomStyle: (roomData: MergeRoomData) => React.SVGProps<SVGPathElement>,
  onChangeInput?: (room: RoomDataEntity, e: React.ChangeEvent<HTMLInputElement>) => void,
  onClickSaveData?: () => void,
  preview?: {
    value: boolean,
    set: (v: boolean) => void,
  }
}

export const useMspCage = (): UseCageReturnType => {
  const { dialog, msp } = RootStore;
  const { articleCode } = useParams();
  const { getCage, getStatus } = useMsp();
  const errDialog = useApiErrorDialog();
  const load = useLoadingDialog();
  const [model, setModel] = useState<Basket | null>(null);
  const [selectedRooms, setSelectedRooms] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);

  const fileLabel = useMemo(() => {
    if (!msp.cageData.cage_drawing_data) return 'かご図データファイルを選択してください';
    return file?.name ?? '';
  }, [file, msp.cageData.cage_drawing_data]);  
  const onClickEdit = useCallback(() => {
    if (!model) return;
    const rooms = selectedRooms.map((v) => model.getViewDataRoom(v)!).filter((v) => v);
    dialog.push({
      title: '住戸編集',
      children: (
        <EditCageDialog
          rooms={rooms}
          isNonUnitRoom={rooms.every((v) => v.isNonUnitRoom)}
          callback={(param) => {
            setModel(model.edit(selectedRooms, param));
            setSelectedRooms([]);
            dialog.pop();
          }}
        />
      )
    })
  }, [dialog, model, selectedRooms]);
  const onClickFileLoad = useCallback(() => {
    RootStore.dialog.push({
      title: 'ファイル読み込み',
      children: (
        <FileDropDialog
          file={{ accept: '.isar4h', isar4h: true }}
          postCallback={async(file) => {
            if (!articleCode) return;
            try {
              await Api.connect().properties(articleCode).cageDrawingData().add({ file });
              await getStatus(articleCode);
              setFile(file);
              RootStore.dialog.pushMessage({
                title: '完了',
                messages: ['かご図データファイルの登録が完了しました'],
                buttons: [{ label: 'OK', callback: () => RootStore.dialog.clear() }],
              });
            } catch(e: any) {
              errDialog.push(e);
              return Promise.reject(e);
            }

          }}
          callback={() => {
            if (articleCode) {
              getCage(articleCode).catch((e) => errDialog.push(e));
            }
          }}
        />
      ),
    })
  }, [articleCode, getCage, errDialog, getStatus]);
  const TopComponent = () => {
    return (
      <Flex w="100%" justifyContent="space-between">
        <Flex gap="1rem" alignItems="center">
          <CustomButton
            onClick={onClickFileLoad}
          >
            ファイル読み込み
          </CustomButton>
          <Box fontSize="xl">{fileLabel}</Box>
        </Flex>
        {!!msp.cageData.cage_drawing_data && (
          <Flex gap="1rem">
            <CustomButton
              minW="fit-content"
              tabIndex={-1}
              onClick={() => onClickEdit()}
              leftIcon={<Img src={iconEdit} alt="icon_edit" mb="2px" />}
              isDisabled={!selectedRooms.length}
            >編集</CustomButton>
            <PreviewButton onClick={() => onTogglePreview(true)} />
          </Flex>
        )}
      </Flex>
    )
  }

  const roomStyle = useCallback<(roomData: RoomDataEntity) => React.SVGProps<SVGPathElement>>((roomData: RoomDataEntity) => {
    if (roomData.editableData.disabled && !roomData.isNonUnitRoom) {
      const fill =  RootStore.msp.colors?.cage_drawing_sharing_department_display_color ?? '#D9D9D9';
      return {
        fill: selectedRooms.includes(roomData.parentKey ?? roomData.key) 
          ? ColorDarken(fill, 5)
          : fill,
        stroke: '#F7FAFC'
      }  
    }
    if (roomData.isMerge) {
      return {
        fill: 'transparent',
      }
    }
    if (roomData.isNonUnitRoom) {
      const fill =  RootStore.msp.colors?.cage_drawing_sharing_department_display_color ?? '#D9D9D9';
      return {
        fill: selectedRooms.includes(roomData.parentKey ?? roomData.key) 
          ? ColorDarken(fill, 5)
          : fill,
      stroke: '#F7FAFC'
      }
    }
    const fill = RootStore.msp.colors?.cage_drawing_dwelling_unit_display_color ?? '#D9D9D9';
    const selectFill = RootStore.msp.colors?.cage_drawing_dwelling_unit_selection_color ?? '#A6A6A6';
    return {
      fill: selectedRooms.includes(roomData.parentKey ?? roomData.key) ? selectFill : fill,
      stroke: '#F7FAFC'
    }
  }, [selectedRooms]);
  const mergeRoomStyle = useCallback<(roomData: MergeRoomData) => React.SVGProps<SVGPathElement>>((roomData: MergeRoomData) => {
    if (roomData.isNonUnitRoom) {
      const fill =  RootStore.msp.colors?.cage_drawing_sharing_department_display_color ?? '#D9D9D9';
      return {
        fill: selectedRooms.includes(roomData.key) 
          ? ColorDarken(fill, 5)
          : fill,
        stroke: '#F7FAFC'
      }
    }
    const fill = RootStore.msp.colors?.cage_drawing_dwelling_unit_display_color ?? '#D9D9D9';
    const selectFill = RootStore.msp.colors?.cage_drawing_dwelling_unit_selection_color ?? '#A6A6A6';

    return {
      fill: selectedRooms.includes(roomData.key) ? selectFill : fill,
      stroke: '#F7FAFC'
    }
  }, [selectedRooms]);

  const onSelectRoom = useCallback((roomData: RoomDataEntity) => {
    const findIndex = selectedRooms.findIndex((v) => v === (roomData.parentKey ?? roomData.key));
    if (findIndex > -1) {
      selectedRooms.splice(findIndex, 1);
    } else {
      selectedRooms.push(roomData.parentKey ?? roomData.key);
    }
    setSelectedRooms([...selectedRooms]);
  }, [selectedRooms]);


  // 上のInputArea onChange
  const onChangeInput = useCallback((room: RoomDataEntity, e: React.ChangeEvent<HTMLInputElement>) => {
    const data = model?.editArticleLabel(room.parentKey ?? room.key, e);
    if (data) setModel(data);
  }, [model]);

  const onClickSaveData = useCallback(async() => {
    if (!model || !articleCode) return;
    load.push();
    try {
      const result = {
        cage_drawing_data: model?.exportJson(),
        cage_drawing_comment: msp.cageData.cage_drawing_comment ?? '',
      }
      await Api.connect().properties(articleCode).cageDrawingData().update(result);
      await getStatus(articleCode);
      await getCage(articleCode);
      RootStore.dialog.pushMessage({
        title: '完了',
        messages: ['かご図データファイルの更新が完了しました'],
        buttons: [{ label: 'OK', callback: () => RootStore.dialog.clear() }],
      });
    } catch(e: any) {
      errDialog.push(e);
    } finally {
      load.pop();
    }
  }, [model, msp, load, errDialog, articleCode, getCage, getStatus]);

  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const onTogglePreview = useCallback((bool: boolean) => {
    if (!RootStore.msp.cageData.cage_drawing_data) {
      setIsOpenPreview(false);
      return;
    };
    setIsOpenPreview(bool);
  }, []);


  useEffect(() => {
    if (msp.cageData.cage_drawing_data) {
      const cage = new Basket(msp.cageData.cage_drawing_data);
      setModel(cage.create());
    }
  }, [msp.cageData]);

  return {
    TopComponent,
    model,
    onSelectRoom,
    roomStyle,
    mergeRoomStyle,
    onChangeInput,
    onClickSaveData,
    preview: {
      value: isOpenPreview,
      set: onTogglePreview,
    }
  }
}