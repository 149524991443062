import { makeObservable, observable, action } from 'mobx';
import { CageDataResponse } from '../module/api/routes/properties/cage-drawing-data';
import { EnergyDataEntity } from '../module/api/routes/properties/energy-saving-data';
import { ArticleDetailDataEntity } from '../module/api/routes/properties/index';
import { MSPColorSettingDataEntity } from '../module/api/routes/properties/colors/index';
import { TemplatesResponseData } from '../module/api/routes/properties/templates';
import { EnergySavingLabelsGetResponse } from '../module/api/routes/properties/energy-saving-labels';

export class MspStore {
  isInit = false;
  status = 0;
  article: ArticleDetailDataEntity | null = null;
  energyData: EnergyDataEntity[] | null = null;
  cageData: CageDataResponse = {
    cage_drawing_data: null,
    cage_drawing_comment: '',
  };
  templates: TemplatesResponseData = {
    template_id: '',
    name: 'テンプレートA',
    setting_items: {
      template: 1,
      items: [],
    }
  };
  colors: MSPColorSettingDataEntity | null = null
  labels: EnergySavingLabelsGetResponse | null = null;
  
  constructor() {
    makeObservable(this, {
      isInit: observable,
      status: observable,
      article: observable,
      energyData: observable,
      cageData: observable,
      templates: observable,
      colors: observable,
      labels: observable,
      initialize: action,
      destroy: action,
      setArticle: action,
      setStatus: action,
      setEnergy: action,
      setCage: action,
      setCageComment: action,
      setTemplates: action,
      setColors: action,
      debug: action,
    })
  }

  initialize() {
    this.isInit = true;
  }
  destroy() {
    this.isInit = false;
    this.status = 0;
    this.energyData = null;
    this.article = null;
    this.cageData = {
      cage_drawing_comment: '',
      cage_drawing_data: null,
    }
    this.templates = {
      template_id: '',
      name: 'テンプレートA',
      setting_items: {
        items: [],
        template: 1,
      },
    };
    this.colors = null;
  }
  setStatus(payload: number) {
    this.status = payload;
  }
  debug(status: number) {
    this.status = status;
  }
  setArticle(payload: ArticleDetailDataEntity) {
    this.article = payload;
  }
  setEnergy(payload: EnergyDataEntity[] | null) {
    this.energyData = payload;
  }
  setCage(payload: any) {
    this.cageData = payload;
  }
  setCageComment(payload: string) {
    this.cageData.cage_drawing_comment = payload;
  }
  setTemplates(payload: TemplatesResponseData) {
    this.templates = payload;
  }
  setColors(payload: MSPColorSettingDataEntity) {
    this.colors = payload;
  }
  setLabels(payload: EnergySavingLabelsGetResponse) {
    this.labels = payload;
  }
}