import { Box } from "@chakra-ui/react";
import { useMemo } from "react";

type Props = {
  count: number | null,
  page?: {
    from: number | null,
    to: number | null,
  }
}

export const DataCount = (props: Props) => {
  const { count, page } = props;
  const from = useMemo(() => {
    if (!page?.to) return 0;
    return page.from;
  }, [page]);
  if (page) {
    return (
      <Box>
        <Box as={'span'}>{count?.toLocaleString() ?? '-'}件中</Box>
        <Box as={'span'} ml="0.5rem">
          {from?.toLocaleString() ?? '-'}〜{page.to?.toLocaleString() ?? '-'}件を表示
        </Box>
      </Box>
    )
  } else {
    return <Box>{count?.toLocaleString() ?? '-'}件を表示</Box>
  }
}