import API from "../../..";
import { fullUrl } from '../../../utile/utile.api';

export type TemplateKeys = 'energy_saving_label'
  | 'utility_costs'
  | 'energy_consumption_rate'
  | 'insulation_performance'
  | 'co2_reduction_rate';
export type TemplatesResponseData = {
  template_id: string,
  id?: string,
  name: string,
  setting_items: {
    items: {
      key: TemplateKeys,
      order: number,
      value: number,
      children?: {
        key: string,
        value: number,
      }[],
    }[],
    template: number,
  }
}

type TemplatesUpdateRequestData = Omit<TemplatesResponseData, 'name'>;

type TemplatesResponse = {
  template: TemplatesResponseData,
}

const path = '/templates';

const templates = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    get: () => API.con.get<{}, TemplatesResponse>({ url: full, data: {} }),
    update: (param: TemplatesUpdateRequestData) => API.con.put<TemplatesUpdateRequestData, {}>({ url: full, data: param }),
  });
}

export default templates;
