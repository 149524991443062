import { makeObservable, observable, action } from 'mobx';
import { UserData } from '../module/api/routes/is-login/is-login';

export class UserStore {
  name = '';
  system_admin_flag = false;

  constructor() {
    makeObservable(this, {
      name: observable,
      system_admin_flag: observable,
      setUser: action,
      destroy: action,
    })
  }

  setUser(data: UserData) {
    this.name = data.company.name;
    this.system_admin_flag = data.company.system_admin_flag;
  }
  destroy() {
    this.name = '';
    this.system_admin_flag = false;
  }
}