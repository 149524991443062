import { Box, Flex, Text } from "@chakra-ui/react";
import bTemplate1 from '../../../../../assets/template/template_b_type1_img.png';
import bTemplate2 from '../../../../../assets/template/template_b_type2_img.png';
import { useMemo } from "react";
import { EnergyDataTable, EnergyTableB, UaTableB } from "../component/ua-table.b";
import 'chart.js/auto';
import { BarChart } from "../component/template.b.chart";
import { PieGraph } from "../component/pie-graph";
import { TemplateBUtilityCosts } from "../component/template.b.utility-costs";
import RootStore from "../../../../../store/root.store";
import { SiteEnergySavingBDataEntity } from "../../../../../module/api/routes/site/energy-saving";
import { TemplateBImgFormatData, useTemplateBImg } from "./hooks/use-template-b-img";

const OFFSET_WIDTH = 540;
const OFFSET_HEIGHT = 200;
const SCALE = 1.12;

const OuterPerformance = (props: { data: TemplateBImgFormatData, type2?: boolean }) => {
  const {
    data,
    type2,
  } = props;

  const outWall = useMemo(() => {
    return data?.outWall;
  }, [data]);

  const bTemplate = useMemo(() => {
    return type2 ? bTemplate2 : bTemplate1;
  }, [type2])

  return (
    <>
      {/* 画像群 */}
      <g transform="translate(0 48)">
        {/* 固定画像 */}
        <image href={bTemplate} width="500px"></image>
        {/* 外壁 */}
        <g transform="translate(54 11)" visibility={outWall?.outer_wall.title ? undefined : 'hidden'}>
          <line x1="8" y1="0" x2="-35" y2="-20" strokeWidth="1" stroke="#000" />
          <circle cx="8" cy="0" r="2" fill="#000" />
        </g>
        {/* 屋根 */}
        <g transform="translate(212 2)" visibility={outWall?.roof.title ? undefined : 'hidden'}>
          <line x1="2" y1="2" x2="-18" y2="-12" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="2" r="2" fill="#000" />
        </g>
        {/* 天井 */}
        <g transform="translate(347 4)" visibility={outWall?.ceiling.title ? undefined : 'hidden'}>
          <line x1="2" y1="0" x2="12" y2="-30" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="0" r="2" fill="#000" />
        </g>
        {/* 窓 */}
        <g transform="translate(55 53)" visibility={outWall?.window.title ? undefined : 'hidden'}>
          <line x1="6" y1="2" x2="-40" y2="60" strokeWidth="1" stroke="#000" />
          <circle cx="6" cy="2" r="2" fill="#000" />
        </g>
        {/* 床 */}
        <g transform="translate(270 91)" visibility={outWall?.floor.title ? undefined : 'hidden'}>
          <line x1="2" y1="2" x2="20" y2="22" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="2" r="2" fill="#000" />
        </g>
      </g>
      {/* テキスト群 */}
      <g transform="translate(0 15)">
        <g transform="translate(0 0)">
          <text fontWeight="bold" fontSize="0.6rem">{outWall?.outer_wall.title ?? ''}</text>
          <text y="10px" fontSize="0.5rem">
            {outWall?.outer_wall.info1 ?? ''}&nbsp;{outWall?.outer_wall.thickness1 ?? ''}{outWall?.outer_wall.unit1 ?? ''}
          </text>
          <text y="20px" fontSize="0.5rem">
            {outWall?.outer_wall.info2 ?? ''}&nbsp;{outWall?.outer_wall.thickness2 ?? ''}{outWall?.outer_wall.unit2 ?? ''}
          </text>
        </g>
        <g transform="translate(185 0)">
          <text fontWeight="bold" fontSize="0.6rem">{outWall?.roof?.title ?? ''}</text>
          <text y="10px" fontSize="0.5rem">
            {outWall?.roof.info1 ?? ''}&nbsp;{outWall?.roof.thickness1 ?? ''}{outWall?.roof.unit1 ?? ''}
          </text>
          <text y="20px" fontSize="0.5rem">
            {outWall?.roof.info2 ?? ''}&nbsp;{outWall?.roof.thickness2 ?? ''}{outWall?.roof.unit2 ?? ''}
          </text>
        </g>
        <g transform="translate(364 0)">
          <text fontWeight="bold" fontSize="0.6rem">{outWall?.ceiling.title ?? ''}</text>
          <text y="10px" fontSize="0.5rem">
            {outWall?.ceiling.info1 ?? ''}&nbsp;{outWall?.ceiling.thickness1 ?? ''}{outWall?.ceiling.unit1 ?? ''}
          </text>
          <text y="20px" fontSize="0.5rem">
            {outWall?.ceiling.info2 ?? ''}&nbsp;{outWall?.ceiling.thickness2 ?? ''}{outWall?.ceiling.unit2 ?? ''}
          </text>
        </g>
        <g transform="translate(7 158)">
          <text fontWeight="bold" fontSize="0.6rem">{outWall?.window.title ?? ''}</text>
          <g transform="translate(0 10)">
            <text fontSize="0.5rem">
              {outWall?.window.info1 ?? ''}&nbsp;{outWall?.window.thickness1 ?? ''}{outWall?.window.unit1 ?? ''}
            </text>
            <text y="10px" fontSize="0.5rem">
              {outWall?.window.info2 ?? ''}&nbsp;{outWall?.window.thickness2 ?? ''}{outWall?.window.unit2 ?? ''}
            </text>
          </g>
        </g>
        <g transform="translate(263 158)">
          <text fontWeight="bold" fontSize="0.6rem">{outWall?.floor.title ?? ''}</text>
          <g transform="translate(0 10)">
            <text fontSize="0.5rem">
              {outWall?.floor.info1 ?? ''}&nbsp;{outWall?.floor.thickness1 ?? ''}{outWall?.floor.unit1 ?? ''}
            </text>
            <text y="10px" fontSize="0.5rem">
              {outWall?.floor.info2 ?? ''}&nbsp;{outWall?.floor.thickness2 ?? ''}{outWall?.floor.unit2 ?? ''}
            </text>
          </g>
        </g>
      </g>
    </>
  )
}

const FirstEnergy = (props: { data: TemplateBImgFormatData, type2?: boolean }) => {
  const { data, type2 } = props;
  const firstEnergy = useMemo(() => {
    return data?.firstEnergy;
  }, [data]);

  const bTemplate = useMemo(() => {
    return type2 ? bTemplate2 : bTemplate1;
  }, [type2])

  return (
    <>
      {/* 画像群 */}
      <g transform="translate(0 48)">
        {/* 固定画像 */}
        <image href={bTemplate} width="500px"></image>
        {/* 高効率ルームエアコン */}
        <g
          transform="translate(102 17)"
          visibility={firstEnergy?.cool_eqp.title ? undefined : 'hidden'}
        >
          <line x1="2" y1="2" x2="-70" y2="-30" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="2" r="2" fill="#000" />
        </g>
        {/* 24時間換気 */}
        <g
          transform="translate(245 5)"
          visibility={firstEnergy?.vent_eqp.title ? undefined : 'hidden'}
        >
          <line x1="2" y1="2" x2="-70" y2="-21" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="2" r="2" fill="#000" />
        </g>
        {/* シャワー水栓 */}
        <g
          transform="translate(265 27)"
          visibility={firstEnergy?.hot_water_eqp_bath_shower_faucet.title ? undefined : 'hidden'}
        >
          <line x1="2" y1="13" x2="-20" y2="-43" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="13" r="2" fill="#000" />
        </g>
        {/* LED照明 */}
        <g
          transform="translate(365 10)"
          visibility={firstEnergy?.lighting_eqp_non_living.title ? undefined : 'hidden'}
        >
          <line x1="2" y1="2" x2="40" y2="-47" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="2" r="2" fill="#000" />
        </g>
        {/* LED照明 人感センサー */}
        <g
          transform="translate(421 7)"
          visibility={firstEnergy?.lighting_eqp_non_living.title ? undefined : 'hidden'}
        >
          <line x1="2" y1="5" x2="30" y2="-23" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="5" r="2" fill="#000" />
        </g>
        {/* 温水床暖房 */}
        <g
          transform="translate(100 80)"
          visibility={firstEnergy?.heat_eqp.title ? undefined : 'hidden'}
        >
          <line x1="2" y1="2" x2="-30" y2="44" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="2" r="2" fill="#000" />
        </g>
        {/* 台所水栓 */}
        <g
          transform="translate(141 51)"
          visibility={firstEnergy?.hot_water_eqp_kitchen_faucet.title ? undefined : 'hidden'}
        >
          <line x1="2" y1="2" x2="-20" y2="54" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="2" r="2" fill="#000" />
        </g>
        {/* 洗面水栓 */}
        <g
          transform="translate(199 51)"
          visibility={firstEnergy?.hot_water_eqp_face_wash_faucet.title ? undefined : 'hidden'}
        >
          <line x1="2" y1="2" x2="24" y2="54" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="2" r="2" fill="#000" />
        </g>
        {/* 高断熱浴槽 */}
        <g
          transform="translate(245 68)"
          visibility={firstEnergy?.hot_water_eqp_insulated_bathtub.title ? undefined : 'hidden'}
        >
          <line x1="2" y1="2" x2="46" y2="56" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="2" r="2" fill="#000" />
        </g>
        {/* 高断熱浴槽 */}
        <g
          transform="translate(442 50)"
          visibility={firstEnergy?.hot_water_eqp_heat_source.title ? undefined : 'hidden'}
        >
          <line x1="2" y1="2" x2="10" y2="60" strokeWidth="1" stroke="#000" />
          <circle cx="2" cy="2" r="2" fill="#000" />
        </g>
      </g>
      {/* テキスト群 */}
      <g transform="translate(0 18)">
        <g transform="translate(0 0)">
          <text fontWeight="bold" fontSize="0.5rem">
            {firstEnergy?.cool_eqp.title ?? ''}
          </text>
          <text y="8px" fontSize="0.4rem">
            {firstEnergy?.cool_eqp.name ?? ''}
          </text>
        </g>
        <g transform="translate(135 0)">
          <text fontWeight="bold" fontSize="0.5rem">
            {firstEnergy?.vent_eqp.title ?? ''}
          </text>
          <text y="8px" fontSize="0.5rem">
            {firstEnergy?.vent_eqp.name ?? ''}
          </text>
        </g>
        <g transform="translate(234 0)">
          <text fontWeight="bold" fontSize="0.5rem">
            {firstEnergy?.hot_water_eqp_bath_shower_faucet.title ?? ''}
          </text>
          <text y="8px" fontSize="0.4rem">
            {firstEnergy?.hot_water_eqp_bath_shower_faucet.name ?? ''}
          </text>
        </g>
        <g transform="translate(387 -12)">
          <text fontWeight="bold" fontSize="0.5rem">
            {firstEnergy?.lighting_eqp_non_living.title ?? ''}
          </text>
        </g>
        <g transform="translate(418 0)">
          <text fontWeight="bold" fontSize="0.5rem">
            {firstEnergy?.lighting_eqp_non_living.title ?? ''}
          </text>
          <text y="8px" fontSize="0.4rem">
            {firstEnergy?.lighting_eqp_non_living.name ?? ''}
          </text>
        </g>
        <g transform="translate(24 167)">
          <text fontWeight="bold" fontSize="0.5rem">
            {firstEnergy?.heat_eqp.title ?? ''}
          </text>
          <text y="8px" fontSize="0.4rem">
            {firstEnergy?.heat_eqp.name ?? ''}
          </text>
        </g>
        <g transform="translate(105 147)">
          <text fontWeight="bold" fontSize="0.5rem">
            {firstEnergy?.hot_water_eqp_kitchen_faucet.title ?? ''}
          </text>
          <text y="8px" fontSize="0.4rem">
            {firstEnergy?.hot_water_eqp_kitchen_faucet.name ?? ''}
          </text>
        </g>
        <g transform="translate(210 147)">
          <text fontWeight="bold" fontSize="0.5rem">
            {firstEnergy?.hot_water_eqp_face_wash_faucet.title ?? ''}
          </text>
          <text y="8px" fontSize="0.4rem">
            {firstEnergy?.hot_water_eqp_face_wash_faucet.name ?? ''}
          </text>
        </g>
        <g transform="translate(250 167)">
          <text fontWeight="bold" fontSize="0.5rem">
            {firstEnergy?.hot_water_eqp_insulated_bathtub.title ?? ''}
          </text>
          <text y="8px" fontSize="0.4rem">
            {firstEnergy?.hot_water_eqp_insulated_bathtub.name ?? ''}
          </text>
        </g>
        <g transform="translate(420 147)">
          <text fontWeight="bold" fontSize="0.5rem">
            {firstEnergy?.hot_water_eqp_heat_source.title ?? ''}
          </text>
          <text y="8px" fontSize="0.4rem">
            {firstEnergy?.hot_water_eqp_heat_source.name ?? ''}
          </text>
        </g>
      </g>
    </>
  )
}

export const TemplateBComponent = (props: { data: SiteEnergySavingBDataEntity | null, }) => {
  const {
    data,
  } = props;
  const {
    energyBData,
  } = RootStore.site;
  const { info } = useTemplateBImg({ data });

  const viewBox = useMemo(() => {
    return `0 0 ${OFFSET_WIDTH} ${OFFSET_HEIGHT}`;
  }, []);

  return (
    <Box bg="#fff" w="calc(1200px + 3rem)" pb="0.4rem">
      <Flex borderTop="1px" w="100%" py="0.4rem" px="1rem" justifyContent="space-between">
        <Box w="600px">
          <Flex w="100%" bg="#D8CCC6" p="0.25rem 0.75rem" alignItems="center" gap="0.5rem" mb="0.25rem">
            <Text fontSize="24px" fontWeight="bold">外皮性能</Text>
            <Text fontSize="20px">( {data?.dwelling_unit_number ?? ''} 号室)</Text>
          </Flex>
          <svg
            x="0"
            y="0"
            width={`${OFFSET_WIDTH * SCALE}px`}
            height={`${OFFSET_HEIGHT * SCALE}px`}
            // ref={ref}
            // onClick={debug}
            viewBox={viewBox}
          >
            <OuterPerformance data={info} type2={!data?.air_conditioner_flag} />
          </svg>
          <Box
            w="calc(100%)"
          >
            <Box
              borderRadius="8px"
              border="1px dashed #000"
              w="100%"
              p="0.25rem 0.5rem"
              h="330px"
            >
              <Box fontSize="lg" fontWeight="bold" mb="0.25rem">あなたの家の外皮性能</Box>
              <Box px="1rem">
                <UaTableB data={data} />
                <Box h="180px" pl="36px">
                  <BarChart data={data} />
                </Box>
                <Box textAlign="right" fontSize="2xs" mt="0.25rem">
                  外皮平均熱貫流率 UA値[W/(m2･K)]
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box w="600px">
          <Flex w="100%" bg="#D8CCC6" p="0.25rem 0.75rem" alignItems="center" gap="0.5rem" mb="0.25rem">
            <Text fontSize="24px" fontWeight="bold">一次エネルギー消費量</Text>
            <Text fontSize="20px">( {data?.dwelling_unit_number ?? ''} 号室)</Text>
            </Flex>
            <svg
              x="0"
              y="0"
              width={`${OFFSET_WIDTH * SCALE}px`}
              height={`${OFFSET_HEIGHT * SCALE}px`}
              // ref={ref2}
              // onClick={debug2}
              viewBox={viewBox}
            >
              <FirstEnergy data={info} type2={!data?.air_conditioner_flag} />
            </svg>
            <Box
              w="calc(100%)"
            >
              <Box
                borderRadius="8px"
                border="1px dashed #000"
                w="100%"
                h="330px"
                p="0.25rem 0.5rem"
              >              
                <Box fontSize="lg" fontWeight="bold" mb="0.5rem">あなたの家の一次エネルギー消費量</Box>
                <Box px="1rem">
                  <EnergyTableB data={data} />
                  <Flex alignItems="center" justifyContent="space-between">
                    <Box>
                      <EnergyDataTable data={data} />
                    </Box>
                    <Box h="190px" w="190px">
                      <PieGraph data={data} />
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      <Box w="100%" px="1rem">
        <Flex borderRadius="8px" border="1px dashed" px="0.5rem" pb="0.25rem">
          <TemplateBUtilityCosts data={data} />
        </Flex>
      </Box>
    </Box>
  )
}