import { Validation } from './validation';

export const SUBDOMAIN_RESERVATION_LIST = [
  'ftp',
  'smtp',
  'pop',
  'mx',
  'www',
  'imap',
];

export type ValidationPattern =
  'require'
  | 'requireEdit'
  | 'length20'
  | 'length30'
  | 'length35'
  | 'length40'
  | 'length50'
  | 'length60'
  | 'length63'
  | 'length80'
  | 'length100'
  | 'length128'
  | 'length255'
  | 'length256'
  | 'length1024'
  | 'length2048'
  | 'lengthOver8'
  | 'lengthJust3'
  | 'lengthNum5'
  | 'kana'
  | 'password'
  | 'postCode'
  | 'phoneNumber'
  | 'suspensionDate'
  | 'mail'
  | 'subdomain'
  | 'subdomain_reservation'
  | 'default'
export const ValidationFactory = (
  name: ValidationPattern
) => {
  switch (name) {
    case 'require':
      return new Validation({
        valid: false,
        test: (v) => v.length > 0,
        errorMessages: ['必須項目です'],
      });
    case 'requireEdit':
      return new Validation({
        test: (v) => v.length > 0,
        errorMessages: ['必須項目です'],
      });
    case 'length20':
      return new Validation({
        test: (v) => v.length <= 20,
        errorMessages: ['20文字以下で入力して下さい'],
      });
    case 'length30':
      return new Validation({
        test: (v) => v.length <= 30,
        errorMessages: ['30文字以下で入力して下さい'],
      });
    case 'length35':
      return new Validation({
        test: (v) => v.length <= 35,
        errorMessages: ['35文字以下で入力して下さい'],
      });
    case 'length40':
      return new Validation({
        test: (v) => v.length <= 40,
        errorMessages: ['40文字以下で入力して下さい'],
      });
    case 'length50':
      return new Validation({
        test: (v) => v.length <= 50,
        errorMessages: ['50文字以下で入力して下さい'],
      });
    case 'length60':
      return new Validation({
        test: (v) => v.length <= 60,
        errorMessages: ['60文字以下で入力して下さい'],
      });
    case 'length63':
      return new Validation({
        test: (v) => v.length <= 63,
        errorMessages: ['63文字以下で入力して下さい'],
      });
    case 'length80':
      return new Validation({
        test: (v) => v.length <= 80,
        errorMessages: ['80文字以下で入力して下さい'],
      });
    case 'length100':
      return new Validation({
        test: (v) => v.length <= 100,
        errorMessages: ['100文字以下で入力して下さい'],
      });
    case 'length128':
      return new Validation({
        test: (v) => v.length <= 128,
        errorMessages: ['128文字以下で入力して下さい'],
      });
    case 'length255':
      return new Validation({
        test: (v) => v.length <= 255,
        errorMessages: ['255文字以下で入力して下さい'],
      });
    case 'length256':
      return new Validation({
        test: (v) => v.length <= 256,
        errorMessages: ['256文字以下で入力して下さい'],
      });
    case 'length1024':
      return new Validation({
        test: (v) => v.length <= 1024,
        errorMessages: ['1024文字以下で入力して下さい'],
      });
    case 'length2048':
      return new Validation({
        test: (v) => v.length <= 2048,
        errorMessages: ['2048文字以下で入力して下さい'],
      });
    case 'lengthOver8':
      return new Validation({
        test: (v) => v.length >= 8,
        errorMessages: ['8文字以上で入力して下さい'],
      });
    case 'lengthJust3':
      return new Validation({
        test: (v) => v.length === 3,
        errorMessages: ['3桁で入力して下さい'],
      });
    case 'lengthNum5':
      return new Validation({
        test: (v) => v.length <= 5,
        errorMessages: ['0~99999までの数字で入力して下さい'],
      });
    case 'password':
      return new Validation({
        test: (v) => !!v.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/),
        errorMessages: ['半角英数を両方含む8文字以上で入力してください']
      })  
    case 'kana':
      return new Validation({
        test: (v) => {
          if (!v) return true;
          return (/^([ァ-ン]|ー)+$/).test(v) === true;
        },
        errorMessages: ['全角カナで入力して下さい'],
      });
    case 'postCode':
      return new Validation({
        test: (v) => (/^[0-9]{3}-[0-9]{4}$/).test(v) === true,
        errorMessages: ['入力内容が正しくありません'],
      });
    case 'phoneNumber':
      return new Validation({
        test: (v) => {
          if (!v) return true;
          return ((/^0\d{1,4}-\d{1,4}-\d{3,4}$/).test(v) === true || !v) && ((v ?? '').length < 14)
      },
        errorMessages: ['入力内容が正しくありません'],
      });
    case 'suspensionDate':
      return new Validation({
        valid: false,
        test: (v) => v.length > 0,
        errorMessages: ['利用停止日を設定してください'],
      });
    case 'mail':
      return new Validation({
        test: (v) => {
          if (!v) return true;
          return /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(v);
        },
        errorMessages: ['入力内容が正しくありません'],
      });
    case 'subdomain':
      return new Validation({
        test: (v) => {
          if (!v) return true;
          return /^(?!-)(?!.*--)[a-zA-Z0-9-]{1,63}(?<!-)$/.test(v);
        },
        errorMessages: ['入力内容が正しくありません'],
      });
    case 'subdomain_reservation':
      return new Validation({
        test: (v) => {
          if (!v) return true;
          return !SUBDOMAIN_RESERVATION_LIST.includes(v);
        },
        errorMessages: [SUBDOMAIN_RESERVATION_LIST.map((v) => `「${v}」`)
        .join(''), 'は使用できません'],
      });
    case 'default':
      return new Validation({
        test: (v) => v.length >= 0,
        errorMessages: ['入力内容が正しくありません'],
      });
    default:
      throw new Error('未実装');
  }
};
