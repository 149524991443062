import { Route, Routes } from "react-router-dom"
import { SiteCage } from "../component/pages/site-cage/site-cage"
import { SiteComparison } from "../component/pages/site-comparison/site-comparison"
import { NotFoundPage } from "../component/ui/404/not-found"
import { SiteRoutingPath } from "./routing-path"

export const SiteRouteConfig = () => {
  return (
    <Routes>
      <Route path={SiteRoutingPath.cage} element={<SiteCage />} />
      <Route path={SiteRoutingPath.comparison} element={<SiteComparison />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}