import API from "../../..";
import { fullUrl } from "../../../utile/utile.api";

export type CompaniesHeaderItemDataEntity = {
  id: string,
  name: string,
  order: number,
}

type CompaniesHeaderItemsResponse = {
  items: CompaniesHeaderItemDataEntity[],
}

const path = '/items';

const items = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    /** 企業管理項目一覧取得 */
    get: () => API.con.get<{}, CompaniesHeaderItemsResponse>({ url: full, data: {} }),
  })
}

export default items;