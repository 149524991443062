import { useDidMount } from '../../hooks/utile/use-didmount';
import { Api } from '../../module/api';
import RootStore from '../../store/root.store';

type Props = {
  onInitialize: (isSubdomain: boolean) => void,
}

export type EnvData = {
  original_domain: string,
  link?: {
    manual?: string,
    support?: string,
    rules?: string,
    support_form?: string,
  }
}

export const Initializer = (props: Props) => {
  const {
    onInitialize,
  } = props;
  useDidMount(() => {
    const version = `v${process.env.REACT_APP_VERSION}`;
    const title = document.title;
    if (!title.includes(version)) {
      document.title = `${title} ${version}`;
    }
    fetch('/server-env/env.json').then((res) => res.json())
    .then((res: EnvData) => {
      const originalDomain = res.original_domain;
      const { hostname: domain } = window.location;
      const isSubDomain = domain !== originalDomain;
      // サブドメインの登録
      if (isSubDomain) {
        const subdomain = domain.replace(`.${originalDomain}`, '');
        RootStore.site.setSubdomain(subdomain);
        RootStore.env.setLink(res.link);
        // env情報からAPIドメインをセットする
        Api.setApiHost(originalDomain);
      }
      onInitialize(isSubDomain);
    })
  });
  return <></>;
}