import { Box, Flex } from "@chakra-ui/react";
import { useMemo } from "react";

type Props ={
  per: number | null,
}
export const CircleGraph = (props: Props) => {
  const {
    per,
  } = props;
  const percentage = useMemo(() => {
    if ((per ?? 0) > 100) {
      return 100;
    } else {
      return per ?? 0;
    }
  }, [per]);
  const getCoordinatesForPercent = (percent: any) => {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);
    return [x, y];
  };

  const [startX, startY] = getCoordinatesForPercent(0);
  const [endX, endY] = getCoordinatesForPercent(percentage / 100);
  const largeArcFlag = percentage > 50 ? 1 : 0;

  const textLeft = useMemo(() => {
    const result = 81 + ((percentage / 100 * 5) * 15);
    return result > 96 ? '96px' : `${result}px`;
  }, [percentage]);
  const textTop = useMemo(() => {
    if (per === null) return 'calc(50% - 38px)';
    if (percentage >= 50) return 'calc(50% - 38px)';
    if (percentage <= 30) return `calc(30% - 38px)`;
    return `calc(${percentage}% - 38px)`;
  }, [percentage, per]);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      w="fit-content"
    >
      <Box fontSize="2xl" mb="2px" w="fit-content" color="#666666">
        省エネ基準の家より
      </Box>
      <Box pos="relative" overflow="visible" w="fit-content" ml="2rem">
        <svg width="150" height="150" viewBox="-1 -1 2 2">
          <g style={{ transform: "rotate(-0.25turn)" }}>
            <circle cx="0" cy="0" r="1" fill="#D7904A" />
            <path
              d={`M ${startX} ${startY} A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY} L 0 0`}
              fill="#ECD17E"
            />
          </g>
        </svg>
        <Box
          pos="absolute"
          top={textTop}
          left={textLeft}
          textAlign="center"
          lineHeight="2.25rem"
        >
        <Box
          fontWeight="bold"
          fontSize="2.5rem"
          color="#555"
        >
          {per ?? ''}%
        </Box>
        <Box
          fontSize="1.5rem"
          color="#555"
        >
          削減
        </Box>
        </Box>
      </Box>
    </Flex>
  );
}