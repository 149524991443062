import API from "../..";
import { fullUrl } from "../../utile/utile.api";

const path = '/logout';

const logout = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    post: () => API.con.post<{}, {}>({ url: full }),
  })
}

export default logout;