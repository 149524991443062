import { Flex, Tooltip, Text, Link, PlacementWithLogical } from '@chakra-ui/react';
import { MouseEvent, useCallback, useState } from 'react';
import { CopyIcon } from '@chakra-ui/icons';
import { COLOR } from '../../../../styles/const';
import { useDebounce } from '../../../../hooks/utile/use-debounce';

type Props = {
  href: string,
  target?: React.HTMLAttributeAnchorTarget,
  /** default true */
  tooltipPos?: PlacementWithLogical,
  isPublic?: boolean,
  disabled?: boolean,
}
export const CopyLink = (props: Props) => {
  const { href, target = '_blank', isPublic = true, disabled, tooltipPos } = props;
  const [isCopy, setIsCopy] = useState(false);
  const debounce = useDebounce();
  const onClickCopy = useCallback((e: MouseEvent<SVGElement>) => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(href);
    setIsCopy(true);
    debounce(() => setIsCopy(false))
  }, [href, debounce]);
  return (
    <Flex gap="0.5rem" alignItems="center">
      <Tooltip hasArrow label="Copied!" fontSize="small" isOpen={!disabled && isCopy} placement={tooltipPos ?? 'auto'} bg="blue.500" color="#fff">
        <CopyIcon onClick={onClickCopy} _hover={{ opacity: '0.8' }} cursor="pointer" />
      </Tooltip>
      {isPublic ? (
        <Link href={href} target={target} onClick={(e) => {
          e.stopPropagation();
        }}>
          <Text
            _hover={{
              textDecoration: 'underline',
            }}
            color={COLOR.bg.link}
          >
            {href}
          </Text>
        </Link>
      ) : (
        <>{href}</>
      )}
    </Flex>
  )
}