import { QueueItem } from './queue-item';
import EventEmitter from 'eventemitter3';
import { AxiosError } from 'axios';
import { deleteToken } from '../index';

type QueueManagerEvent = {
  'on-change:count:queue': (count: number) => void,
  'on-add:running-queue': (count: number) => void,
  'on-did-run': (itemId: string) => void,
};

export class QueueManager extends EventEmitter<QueueManagerEvent> {

  private static readonly PRIORITY_COUNT = 5;
  private static readonly RUNNING_QUEUE_LIMIT = 5;

  private queue: QueueItem[][] = [...new Array(QueueManager.PRIORITY_COUNT)].map(() => []);
  private runningQueue: QueueItem[] = [];
  private running = false;

  get length(): number {
    return this.queue.flat().length;
  }

  constructor() {
    super();
  }

  start() {
    if (this.running) {
      return;
    }
    this.running = true;
    this.on('on-change:count:queue', this.onChangeCountQueue);
    this.on('on-add:running-queue', this.onChangeCountRunningQueue);
    this.on('on-did-run', this.onDidRun);
  }

  stop() {
    this.off('on-change:count:queue', this.onChangeCountQueue);
    this.off('on-add:running-queue', this.onChangeCountRunningQueue);
    this.off('on-did-run', this.onDidRun);
  }

  enqueue(queueItem: QueueItem) {
    if (!this.queue[queueItem.priority]) {
      throw new RangeError(`priority:${queueItem.priority} is out of range !!`);
    }
    this.queue[queueItem.priority].push(queueItem);
    this.emit('on-change:count:queue', this.length);
  }

  dequeue() {
    const targetQueueItem = this.getTargetQueueItem();
    if (targetQueueItem) {
      this.delete(targetQueueItem.id);
    }
  }

  delete(id: string) {
    this.queue = this.queue.map(list => list.filter(item => item.id !== id));
    this.emit('on-change:count:queue', this.length);
  }

  getQueueItem(id: string): QueueItem | undefined {
    return this.queue.flat().find(item => item.id === id);
  }

  private enqueueRunningQueue() {
    const targetQueueItem = this.getTargetQueueItem();
    if (!targetQueueItem) {
      return;
    }
    if (this.runningQueue.length >= QueueManager.RUNNING_QUEUE_LIMIT) {
      return;
    }
    this.runningQueue.push(targetQueueItem);
    this.delete(targetQueueItem.id);
    this.emit('on-add:running-queue', this.runningQueue.length);
  }

  private async run() {
    if (!this.runningQueue.filter(v => !v.running).length) {
      this.running = false;
      return;
    }
    this.running = true;
    const targetQueueItem = this.runningQueue.filter(v => !v.running)[0];
    targetQueueItem.running = true;
    try {
      const result = await targetQueueItem.func();
      targetQueueItem.onSuccess(result);
    } catch (err) {
      targetQueueItem.onError(err);
    }
    const targetQueueItemIndex = this.runningQueue.findIndex(item => item.id === targetQueueItem.id);
    this.runningQueue.splice(targetQueueItemIndex, 1);
    this.running = false;
    this.emit('on-did-run', targetQueueItem.id);
  }

  private getTargetQueueItem(): QueueItem | undefined {
    const targetQueueItemList = this.queue.slice().reverse().find(list => list.length);
    if (!targetQueueItemList || !targetQueueItemList.length) {
      return undefined;
    }
    return targetQueueItemList[0];
  }

  private onChangeCountQueue(count: number) {
    this.enqueueRunningQueue();
  }

  private onChangeCountRunningQueue(count: number) {
    this.run()
      .then(() => {});
  }

  private onDidRun() {
    this.debug({ title: 'onDidRun' });
    this.enqueueRunningQueue();
  }

  private debug(props: { title: string }) {
    // const zeroPadding = (num: number) => num.toString().padStart(2, '0');
    // console.log(
    //   `[${zeroPadding(this.length)}] [${zeroPadding(this.runningQueue.length)}] : ${props.title}`,
    // );
  }

}

