import { useCallback } from "react";
import { ValidationFactory, ValidationPattern } from '../../module/validation/validation-factory';

export type CustomValidationPattern = {
  message: string,
  result: [boolean, ...boolean[]]
}

type Pattern = ValidationPattern | CustomValidationPattern

export const useValidation = () => {
  const valid = useCallback((value: any, pattern: ValidationPattern) => {
    const validation = ValidationFactory(pattern);
    const result = validation.test(`${value}`);
    return !result ? validation.errorMessages : [];
  }, []);

  const validAll = useCallback((value: any, ...patterns: [Pattern, ...Pattern[]]) => {
    const result: string[][] = [];
    patterns.forEach((pattern) => {
      if ((pattern as any)?.['message']) {
        const customPattern = pattern as any as CustomValidationPattern;
        if (!customPattern.result.every((v) => v)) result.push([customPattern.message]);
      } else {
        const validation = ValidationFactory(pattern as any);
        const testResult = validation.test(`${value ?? ''}`);
        if (!testResult) result.push(validation.errorMessages);
      }
    });
    return result.flat();
  }, []);

  return {
    valid,
    validAll,
  }
}
