import { Text, Flex, FlexProps } from "@chakra-ui/react"
import { Fragment, useCallback } from "react";
import { useMemo } from "react";
import { COLOR } from '../../../styles/const';

const VIEW_LIMIT = 9;
type PageData = {
  label: string,
  data?: number,
}

const extendStyle = {
  _hover: {
    bg: COLOR.bg.sub,
    border: '1px solid',
    borderColor: COLOR.bg.theme,
  },
  _active: {
    bg: COLOR.bg.theme,
    color: COLOR.bg.sub,
  },
}

const defaultStyle: FlexProps = {
  alignItems: 'center',
  justifyContent: 'center',
  minW: '24px',
  bg: '#E6E6E6',
  h: '24px',
  textAlign: 'center',
  borderRadius: '3px',
  fontSize: 'small',
  lineHeight: '4px',
  cursor: 'pointer',
  userSelect: 'none',
}

const disabledStyle: FlexProps = {
  opacity: '0.3',
  cursor: 'no-drop',
  _hover: {
    bg: '#E6E6E6',
    border: 'initial',
  },
  _active: {
    bg: '#E6E6E6',
    color: '#000',
  }
}

type Props = {
  totalCount: number | null,
  offset: number | null,
  step?: number,
  onChange?: (offset: number) => void,
}

export const Pager = (props: Props) => {
  const {
    totalCount,
    offset = 1,
    step = 20,
    onChange,
  } = props;

  const totalPage = useMemo(() => {
    if (!totalCount) return 1;
    return Math.ceil(totalCount / step)
  }, [totalCount, step]);
  const isDisabled = useMemo(() => {
    const disabled = !totalCount;
    return {
      prev: disabled || offset === 1,
      next: disabled || totalPage === offset,
    }
  }, [totalCount, totalPage, offset]);

  const disableStyles = useMemo(() => {
    return {
      prev: isDisabled.prev ? disabledStyle : {},
      next: isDisabled.next ? disabledStyle : {},
    }
  }, [isDisabled]);

  const pageDataList: PageData[] = useMemo(() => {
    const createIndexData = (index: number) => ({ label: `${index}`, data: index});
    const dotLabel = () => ({ label: '...' })
    if (VIEW_LIMIT >= totalPage) {
      const result =  Array.from({ length: totalPage }, (_, index) => createIndexData(index + 1));
      return result;
    }
    const _offset = offset ?? 1;
    if ((_offset ?? 1) <= (VIEW_LIMIT - 4)) {
      const result: PageData[] = Array.from({ length: 7 }, (_, index) => createIndexData(index + 1));
      result.push(dotLabel(), createIndexData(totalPage));
      return result;
    }
    if ((_offset + 4) >= totalPage) {
      const result: PageData[] = [createIndexData(1), dotLabel()];
      for (let i = 0; i < (VIEW_LIMIT - 2); i++) {
        result.push(createIndexData(totalPage - i));
        result.sort((a, b) => a.data! - b.data!);
      }
      return result;
    }
    const result: PageData[] = [createIndexData(1), dotLabel()];
    const startCount = _offset - 2;
    for (let i = 0; i < 5; i++) {
      result.push(createIndexData(startCount + i));
    }
    result.push(dotLabel(), createIndexData(totalPage));
    return result;
  }, [totalPage, offset]);

  const onChangeOffset = useCallback((data?: number) => {
    if (!data) return;
    if ((offset ?? 1) === data) return;
    if (((data ?? 1)  < 1) || ((data ?? 1) > totalPage)) {
      return;
    }
    onChange?.(data);
  }, [offset, totalPage, onChange]);

  const pageButtonProps = useCallback<(data?: number) => FlexProps>((data?: number) => {
    if (!data) return { cursor: 'initial', pointerEvents: 'none' };
    if (data === (offset ?? 1)) {
      return {
        bg: COLOR.bg.theme,
        color: COLOR.bg.sub,
        pointerEvents: 'none',
      }
    }
    return {
      ...extendStyle,
      onClick: () => onChangeOffset(data),
    };
  }, [offset, onChangeOffset]);

  return (
    <Flex gap="5px">
      <Flex
        {...defaultStyle}
        {...extendStyle}
        {...disableStyles.prev}
        lineHeight="4px"
        onClick={() => onChangeOffset(1)}
      >
        <Text as="span" userSelect="none">{'<<'}</Text>
      </Flex>
      <Flex
        {...defaultStyle}
        {...extendStyle}
        {...disableStyles.prev}
        lineHeight="4px"
        onClick={() => onChangeOffset((offset ?? 1) - 1)}
      >
        <Text as="span" userSelect="none">{'<'}</Text>
      </Flex>
      { pageDataList.map((pageData, i) => (
        <Flex
          key={`${pageData.label}_${i}`}
          {...defaultStyle}
          {...pageButtonProps(pageData.data)}
          lineHeight="4px"
        >
          <Text as="span" userSelect="none">{pageData.label}</Text>
        </Flex>
      ))}
      <Flex
        {...defaultStyle}
        {...extendStyle}
        {...disableStyles.next}
        lineHeight="4px"
        onClick={() => onChangeOffset((offset ?? 1) + 1)}
      >
        <Text as="span" userSelect="none">{'>'}</Text>
      </Flex>
      <Flex
        {...defaultStyle}
        {...extendStyle}
        {...disableStyles.next}
        lineHeight="4px"
        onClick={() => onChangeOffset(totalPage)}
      >
        <Text as="span" userSelect="none">{'>>'}</Text>
      </Flex>
    </Flex>
  )
}