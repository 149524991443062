import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Center, GridItem } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { Api } from "../../../module/api";
import RootStore from '../../../store/root.store';
import { Loading } from "../../ui/loading/loading";
import { ComparisonCard } from "./component/comparison-card/comparison-card";
import { ComparisonBCard } from "./component/comparison-card/comparison-b-card";

export const SiteComparison = observer(() => {
  const {
    site,
  } = RootStore;
  const {
    templates,
  } = RootStore.site;
  const [searchParams] = useSearchParams();
  const [init, setInit] = useState(false);
  const params = useMemo(() => {
    const getData = searchParams.get('k');
    return (decodeURIComponent(getData ?? '')).split(',').filter((v) => v);
  }, [searchParams]);

  const calcWidth = useMemo(() => {
    return templates.setting_items.template === 1
    ? '554px'
    : '1248px';
  }, [templates]);

  const shadow = useMemo(() => {
    return site.templates.setting_items.template === 1
      ? {
        boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.25)',
        bg: "blackAlpha.200",
      } : {
        filter: 'drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.25))'
      }
  }, [site]);

  const initialize = useCallback(() => {
    if (!params?.length) {
      setInit(true);
      return;
    };
    Api.connect().site().energySaving().get({
      dwelling_unit_numbers: params,
    }).then((res) => {
      RootStore.site.setEnergyData(res.data);
      setInit(true);
    })
  }, [params]);
  const padding = useMemo(() => {
    if (templates.setting_items?.template === 1) {
      return { p: '1rem' }
    } else {
      return { p: '0.25rem' }
    }
  }, [templates]);
  useEffect(() => {
    initialize();
  }, [params]);

  if (!init) {
    return <Loading />;
  }
  if (!params?.length) {
    return (
      <Center h="100vh" flexDirection="column" gap="1rem">
        <Alert
          status='warning'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          w="fit-content"
          px="10rem"
          height='200px'
        >
          <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              住戸が選択されていません
            </AlertTitle>
          <AlertDescription maxWidth='sm'>
            省エネ表示する住戸は１件以上選択してください
          </AlertDescription>
        </Alert>
      </Center>
    )
  }
  return (
    <Box w="100%" p="0.5rem" minW={`calc(${calcWidth} + 3rem)`}>
      <Box
        display="grid"
        gridTemplateColumns={`repeat(auto-fit, ${calcWidth})`}
        minW={calcWidth}
        gap="2rem"
        justifyContent="center"
        py={templates.setting_items.template !== 2 ? '1rem' : undefined}
      >
        {templates.setting_items.template !== 2 && (
          <>
            {site.energyData?.map((v, i) => (
              <GridItem
                key={`${i}_${v.dwelling_unit_number}`}
                justifyContent="center"
                display="flex" w="fit-content"
                {...shadow}
                {...padding}
              >
                <ComparisonCard data={v} />
              </GridItem>
            ))}
          </>
        )}
        {templates.setting_items.template === 2 && (
          <>
            {site.energyBData?.map((v, i) => (
              <GridItem
                key={`${i}_${v.dwelling_unit_number}`}
                justifyContent="center"
                display="flex" w="fit-content"
                {...shadow}
                {...padding}
              >
                <ComparisonBCard data={v} />
              </GridItem>
            ))}
          </>
        )}
      </Box>
    </Box>
  )
});