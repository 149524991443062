import { Spinner } from "@chakra-ui/react";
import { useCallback } from "react";
import { DialogBody } from "../../component/ui/dialog/dialog";
import RootStore from "../../store/root.store";
import { COLOR } from "../../styles/const";

export const LOADING_DIALOG_ID = 'loading-spinner';

export const useLoadingDialog = () => {
  const { dialog } = RootStore;
  const push = useCallback(() => {
    dialog.push({
      id: LOADING_DIALOG_ID,
      children: (
        <DialogBody bodyProps={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: '16px',
        }}>
          <Spinner color={COLOR.bg.theme} size="lg" />
        </DialogBody>
      )
    });
  }, [dialog]);
  const pop = useCallback(() => {
    dialog.pop(LOADING_DIALOG_ID);
  }, [dialog]);
  return {
    push,
    pop,
  }
}