import { Box, Flex, Text } from "@chakra-ui/react"
import { CircleGraph } from "../circle-graph"
import { SiteTemplateProps } from "../comparison-template/comparison-template";

export const ComparisonConsumptionEnergy = (props: SiteTemplateProps) => {
  const {
    param,
    data,
  } = props;
  if (!param?.energy_consumption_rate) return <></>;
  return (
    <Box bg="#FCF8EA" p="0.5rem 1rem" pb="1.5rem">
      <Box lineHeight="10px" mb="0.25rem">
        <Text fontSize="lg" fontWeight="bold">一次エネルギー消費量</Text>
        <br />
        <Text fontSize="sm">(再エネ含む削減率)</Text>
      </Box>
      <Flex justifyContent="center" alignItems="center">
        <Box fontSize="4xl" lineHeight="32px">
          {(data?.zeh_name ?? '').split(' ').map((v) => (
            <Box key={v}>{v}</Box>
          ))}
        </Box>
        <CircleGraph per={data?.reducing_rate ?? null} />
      </Flex>
    </Box>
  )
}