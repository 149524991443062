// HEXをRGBに変換する関数
function hexToRgb(hex: string): { r: number; g: number; b: number } {
  let r = 0, g = 0, b = 0;
  // 3文字の場合は各文字を2回繰り返して6文字にする
  if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
  }
  // 6文字の場合は通常通り処理
  else if (hex.length === 7) {
      r = parseInt(hex.substring(1, 3), 16);
      g = parseInt(hex.substring(3, 5), 16);
      b = parseInt(hex.substring(5, 7), 16);
  }
  return { r, g, b };
}

// RGBをHEXに変換する関数
function rgbToHex(r: number, g: number, b: number): string {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

// 色を濃くする関数
export function ColorDarken(hex: string, percent: number): string {
  const { r, g, b } = hexToRgb(hex);
  const factor = (100 - percent) / 100;
  const newR = Math.round(r * factor);
  const newG = Math.round(g * factor);
  const newB = Math.round(b * factor);
  return rgbToHex(newR, newG, newB);
}

// 色を薄くする関数
export function ColorLighten(hex: string, percent: number): string {
  const { r, g, b } = hexToRgb(hex);
  const factor = percent / 100;
  const newR = Math.round(Math.min(255, r + (255 - r) * factor));
  const newG = Math.round(Math.min(255, g + (255 - g) * factor));
  const newB = Math.round(Math.min(255, b + (255 - b) * factor));
  return rgbToHex(newR, newG, newB);
}