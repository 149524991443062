import API from "../..";
import { fullUrl } from "../../utile/utile.api";

export type LoginRequestDataEntity = {
  login_id: string,
  password: string,
}

export type LoginResponseDataEntity = {
  token: string,
}

const path = '/login';

const login = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    post: (param: LoginRequestDataEntity) => API.con
      .post<LoginRequestDataEntity, LoginResponseDataEntity>({ url: full, data: param }),
  })
}

export default login;