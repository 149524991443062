import { AxiosError } from "axios";
import { useCallback } from "react";
import RootStore from "../../store/root.store";
import { useAuth } from "../auth/use-auth";

type ErrorDialogProps = {
  customMessage?: string[],
  callback?: () => void,
  internalErrorCallback?: () => void,
}

export const useApiErrorDialog = () => {
  const { dialog } = RootStore;
  const { checkLogin } = useAuth();
  const push = useCallback((err: AxiosError<any, any>, option?: ErrorDialogProps) => {
    if (!(err instanceof AxiosError)) {
      console.error('error in error hooks !!', err);
      const callback = option?.internalErrorCallback ?? (() => dialog.pop());
      dialog.pushMessage({
        title: 'エラー',
        messages: ['エラーが発生しました。'],
        contentsPadding: '1rem 0.5rem',
        headerProps: {
          css: {
            '.modal_header_wrapper': {
              fontSize: '24px'
            }
          }
        },
        buttons: [
          { label: 'OK', callback },
        ]
      });
      return;
    }
    const is401 = err?.response?.status === 401 && err.response?.data?.message === 'Unauthenticated.';
    if (is401) {
      checkLogin(true);
      return;
    }
    const {
      customMessage = [err.response?.data?.message ?? 'ネットワークエラーが発生しました。'],
      callback = () => dialog.pop(),
    } = option ?? {};
    dialog.pushMessage({
      title: `エラー(CODE: ${err.response?.status ?? 500})`,
      messages: customMessage,
      contentsPadding: '1rem 0.5rem',
      headerProps: {
        css: {
          '.modal_header_wrapper': {
            fontSize: '24px'
          }
        }
      },
      buttons: [
        { label: 'OK', callback },
      ]
    });
  }, [dialog, checkLogin]);
  return {
    push,
  }
}