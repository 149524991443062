import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Dialogs } from './component/ui/dialog/dialogs';
import { RouteConfig } from './routes/route-config';
import { Initializer } from './component/function/initializer';
import { useState } from 'react';
import { SwitchRouter } from './routes/switch-router';

function App() {
  const [isSubDomain, setIsSubdomain] = useState<null | boolean>(null);
  return (
    <>
      <Initializer onInitialize={setIsSubdomain} />
      <SwitchRouter isSubDomain={isSubDomain} />
    </>
  );
}

export default App;
