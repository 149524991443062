import { useCallback, useRef } from "react"

export const useDebounce = (count: number = 1500) => {
  const timer = useRef(0);
  return useCallback((callback: () => void) => {
    if (timer.current) window.clearTimeout(timer.current);
    timer.current = window.setTimeout(() => {
      callback();
    }, count)
  }, [count]);
}