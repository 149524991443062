import { Input } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import RootStore from "../../../../../../store/root.store";
import { UseCageReturnType } from "../../hooks/use-msp-basket"
import { MergeRoomData, RoomDataEntity } from "../../module/basket";
import { SVGRoomArea } from "./svg-text-area";

type Props = Omit<UseCageReturnType, 'TopComponent'>

export const px2pt = (num: number) => num * 1.33333;

export const Cage = (props: Props) => {
  const {
    model,
    onSelectRoom,
    mergeRoomStyle,
    roomStyle,
  } = props;
  const createY = useCallback((index: number) => {
    if (!model) return 0;
    const filter = model.viewData.filter((v, i) => i < index);
    return px2pt(filter.reduce((a, b) => a + b.height, 0));
  }, [model]);

  const createRectPath = useCallback((room: RoomDataEntity, _height: number) => {
    const width = px2pt(room.width);
    const height = px2pt(_height);
    return `M 0,0 L${width},0 L${width},${height} L0,${height} Z`
    // return `M 4,0
    // h ${width - 8}
    // a 4,4 0 0 1 4,4
    // v ${height - 8}
    // a 4,4 0 0 1 -4,4
    // h -${width - 8}
    // a 4,4 0 0 1 -4,-4
    // v -${height - 8}
    // a 4,4 0 0 1 4,-4
    // Z`;
  }, []);

  const createMergePath = useCallback((data: MergeRoomData) => {
    const pathStart = data.pos.map((pos, i) => {
      const firstIndex = !pos.rowIndex ? 0 : pos.rowIndex;
      if (!i) return `${px2pt(pos.start)},${createY(firstIndex)} L${px2pt(pos.end)},${createY(pos.rowIndex)} L${px2pt(pos.end)},${createY(pos.rowIndex + 1)}`;
      const middlePath = ` L${px2pt(pos.end)},${createY(pos.rowIndex)} L${px2pt(pos.end)},${createY(pos.rowIndex + 1)}`;
      if (i === (data.pos.length - 1)) return `${middlePath} L${px2pt(pos.start)},${createY(pos.rowIndex + 1)} L${px2pt(pos.start)},${createY(pos.rowIndex)}`;
      return middlePath;
    });
    const pathEnd = [...data.pos].reverse().map((pos, i) => {
      if (i === (data.pos.length - 1)) return ' Z';
      if (!i) return '';
      return ` L${px2pt(pos.start)},${createY(pos.rowIndex)} L${px2pt(pos.start)},${createY(pos.rowIndex)}`;
    });
    return `M${pathStart.join('')}${pathEnd.join('')}`;
  }, [createY]);

  const textColor = useMemo(() => {
    return RootStore.msp.colors?.cage_drawing_text_color ?? '#000';
  }, [RootStore.msp]);


  if (!model) {
    return <></>;
  }
  return (
    <svg
      width={`${model.floorWidth + 62}pt`}
      height={`${model.totalHeight + 2}pt`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(0 1)"
      >
        {model.mergeRoomData.map((mergeData, i) => (
          <g key={`merge_${i}`} transform="translate(60, 0)">
            <path
              d={createMergePath(mergeData)}
              stroke="#F7FAFC"
              strokeWidth="4"
              {...mergeRoomStyle(mergeData)}
            />
          </g>
        ))}
        {model.viewData.map((floor, i) => (
          <g
            height={px2pt(floor.height)}
            transform={`translate(0, ${createY(i)})`}
            key={`g_floor_${i}`}
          >
            <g transform={`translate(0, ${px2pt(floor.height) - 31})`}>
              <rect
                width="50px"
                height="30px"
                fill="#E2E8F0"
                stroke="#E2E8F0"
              />
              <text
                fontSize="14"
                x="25"
                y="15"
                textAnchor="middle"
                dominantBaseline="middle"
                style={{ pointerEvents: 'none', userSelect: 'none' }}
              >
                {floor.floorLabel}
              </text>
            </g>
            <g transform="translate(60, 0)">
              {floor.rooms.map((room, _i) => (
                <g key={`room_${i}_${_i}`} transform={`translate(${px2pt(room.xPos.start)}, 0)`} id={`${i}_${_i}`}>
                  {!room.isEmpty && (
                    <SVGRoomArea
                      roomData={room}
                      onSelectRoom={onSelectRoom}
                      rectPath={createRectPath(room, floor.height)}
                      roomStyle={roomStyle}
                      height={floor.height}
                      model={model}
                      textColor={textColor}
                    />
                  )}
                </g>
              ))}
            </g>
          </g>
        ))}
      </g>
    </svg>
  )
}