import { DeleteIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, Box, Button, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { useDidMount } from "../../../../hooks/utile/use-didmount";
import { Api } from "../../../../module/api";
import RootStore from "../../../../store/root.store";
import { Layout } from "../../../layout/layout"
import { MspLayout } from "../../../layout/msp-layout"
import { CustomButton } from "../../../ui/button/button";
import { Checkbox } from "../../../ui/checkbox/checkbox";
import { EcoLabelTable, EcoTableBodyDataEntity } from './component/eco-label-table';
import { useEcoLabel } from "./hooks/use-eco-label";

export const EcoLabel = observer(() => {
  const {
    onClickSendFiles,
    fileZoneProps,
    checkboxProps,
    tableProps,
    deleteButtonProps,
  } = useEcoLabel();
  const {
    labels,
  } = RootStore.msp;
  return (
    <Layout isMsp>
      <MspLayout>
        <Box
          pt="0.25rem"
          w="100%"
        >
          <Flex
            border="1px dashed"
            justifyContent="center"
            alignItems="center"
            bg="#fff"
            {...fileZoneProps}
            maxW="100%"
            h="10rem"
            color="gray.500"
            mb="1rem"
          >
            ファイルをドラッグ＆ドロップしてください
          </Flex>
          <Flex gap="0.5rem" alignItems="center">
            <Button
              border="1px solid"
              bg="blackAlpha.50"
              onClick={() => onClickSendFiles()}
            >
              ファイル選択
            </Button>
            <Box>
              省エネラベルを読み込んでください
            </Box>
          </Flex>
        </Box>
        {labels ? (
          <>
            <Box textAlign="left" w="100%">{labels.total.toLocaleString()}件登録済み</Box>
            <Box
              w="100%"
              h="100%"
              overflow="hidden"
              borderRadius="8px"
              filter="drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.1))"
            >
              <Box
                pos="sticky"
                top="0"
                bg="#fff"
                pt="0.5rem"
                w="100%"
                h="fit-content"
              >
                <Flex
                  mt="1rem"
                  mx="1rem"
                  border="1px solid"
                  borderColor="gray.200"
                  p="0.5rem 1rem"
                  justifyContent="space-between"
                >
                  <Flex alignItems="center" gap="2rem" fontSize="lg">
                    <Text>登録ファイル</Text>
                    <Checkbox
                      css={{ span: { fontSize: '1.1125rem' } }}
                      size="xl"
                      {...checkboxProps}
                    >
                      一括選択
                    </Checkbox>
                  </Flex>
                  <CustomButton
                    minW="fit-content"
                    tabIndex={-1}
                    colorType="warning"
                    leftIcon={<DeleteIcon w="20px" h="20px" mb="2px" />}
                    {...deleteButtonProps}
                  >
                    削除
                  </CustomButton>
                </Flex>
              </Box>
              <EcoLabelTable
                {...tableProps}
              />
            </Box>
          </>
        ) : (
          RootStore.msp.isInit && (<Box w="100%">
            <Alert status="warning">
              <AlertIcon />
              省エネラベルがアップロードされていません
            </Alert>
          </Box>)
        )}
      </MspLayout>
    </Layout>
  )
});