import { Box, Flex, Img } from "@chakra-ui/react";
import { useCallback } from "react";
import template2 from '../../../../../assets/template/template_02.png';
import template3 from '../../../../../assets/template/template_03.png';
import template5 from '../../../../../assets/template/template_05.png';
import { Checkbox } from "../../../../ui/checkbox/checkbox";
import { Radio } from "../../../../ui/radio/radio";
import { MspFormatTemplateData } from "../../color-setting/collection/template-color.collection";
import { EcoLabelTemplate } from "./echo-label-template";
import { InsulationTemplate } from "./Insulation.template";
import bTemplate from '../../../../../assets/template/template_b_dummy.png';

type Props = {
  param: MspFormatTemplateData,
  selectTemplate: number,
  setRequest: (req: Partial<MspFormatTemplateData>) => void,
}

export const TemplateSelect = (props: Props) => {
  const {
    param,
    setRequest: setParam,
    selectTemplate,
  } = props;
  const displayValue = useCallback((value: boolean) => {
    return value ? 'inline-flex' : 'none';
  }, []);
  const onChangeEcoLabel = useCallback((value: 1 | 2 | 3) => {
    const isIgnoreEnergyCosts = value === 1;
    if (isIgnoreEnergyCosts) {
      setParam({ energy_saving_label: value, utility_costs: false });
    } else {
      setParam({ energy_saving_label: value });
    }
  }, [param, setParam]);

  const onChangeCheck = useCallback((key: keyof MspFormatTemplateData) => {
    if (key === 'insulation_performance') return;
    const prev = param[key];
    setParam({ [key]: !prev });
  }, [param, setParam]);

  const onChangeInsulation = useCallback(() => {
      const prev = param.insulation_performance.value;
      setParam({ insulation_performance: { value: !prev, ua_value: false } });
  }, [param, setParam]);

  const onChangeUa = useCallback(() => {
    const prev = param.insulation_performance.ua_value;
    if (!param.insulation_performance.value) return;
    setParam({ insulation_performance: { value: true, ua_value: !prev } });
  }, [param, setParam]);

  return (
    <Flex gap="2rem" position="relative">
      {selectTemplate === 1 && (
        <Box
          position="sticky"
          top="0"
          p="1.5rem 1rem"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="5px"
          minW="fit-content"
          whiteSpace="nowrap"
          h="fit-content"
        >
          <Radio<1 | 2 | 3>
            flexDirection="column"
            option={[
              { label: '省エネラベル(光熱費あり)', value: 1 },
              { label: '省エネラベル(光熱費なし)', value: 2 },
              { label: '省エネラベル非表示', value: 3 },
            ]}
            value={param.energy_saving_label as 1 | 2 | 3}
            onChange={onChangeEcoLabel}
          />
          <Flex marginInlineStart="2rem" mt="0.5rem" flexDirection="column" gap="0.5rem">
            <Checkbox
              isChecked={param.energy_saving_label === 1 ? false : param.utility_costs}
              onChange={() => onChangeCheck('utility_costs')}
              display="block"
              isDisabled={param.energy_saving_label === 1}
            >
              光熱費
            </Checkbox>
            <Checkbox
              isChecked={param.energy_consumption_rate}
              onChange={() => onChangeCheck('energy_consumption_rate')}
              display="block"
            >
              エネルギー消費率
            </Checkbox>
            <Checkbox
              isChecked={param.insulation_performance.value}
              onChange={() => onChangeInsulation()}
              display="block"
            >
              断熱性能(等級)
            </Checkbox>
            <Checkbox
              isChecked={param.insulation_performance.ua_value}
              onChange={() => onChangeUa()}
              display="block"
              ml="2.25rem"
              isDisabled={!param.insulation_performance.value}
            >
              (UA値)
            </Checkbox>
            <Checkbox
              isChecked={param.co2_reduction_rate}
              onChange={() => onChangeCheck('co2_reduction_rate')}
              display="block"
            >
              CO2削減量
            </Checkbox>
          </Flex>
        </Box>
      )}
      <Box w="100%" maxW="calc(900px + 3rem)">
        {selectTemplate === 1 ? (
          <Flex wrap="wrap" align="flex-start" gap="1rem">
            <EcoLabelTemplate isNone={param.energy_saving_label === 2} display={param.energy_saving_label !== 3} />
            <Flex w="300px" display={displayValue(param.utility_costs)}>
              <Img w="300px" src={template2} />
            </Flex>
            <Flex w="300px" display={displayValue(param.energy_consumption_rate)}>
              <Img w="300px" src={template3} />
            </Flex>
            <InsulationTemplate
              display={displayValue(param.insulation_performance.value)}
              isUa={param.insulation_performance.ua_value}
            />
            <Flex w="300px" display={displayValue(param.co2_reduction_rate)}>
              <Img w="300px" src={template5} />
            </Flex>
          </Flex>
        ) : (
          // FIXME 一旦ダミー
          <Box>
            <Img src={bTemplate} alt="template_b" mt="-16px" w="600px" minW="600px" />
          </Box>
        )}
      </Box>
    </Flex>
  )
}