import { Box, Divider, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { BASE_FONT } from "../../../../../model/font";
import lightIcon from '../../../../../assets/icon/light.svg';
import { SiteTemplateProps } from "../comparison-template/comparison-template";

const svgFont = "serif"

const GRAPH_WIDTH = 80;
const BASE_GRAPH_HEIGHT = 160;
const ANIMATION_TIME = 1.5;
const TEXT_HEIGHT = 95.15;
const DUMMY = {
  // id: 1,
  // floorNumber: '109',
  // type: '3-08',
  // type2: 'J1',
  // floorSort: 9,
  // floor: 1,
  // towerType: 'B',
  // areaMeters: '88.08',
  // areaTsubo: '26.64',
  // floorPlan: '3LDK+WIC+ﾏﾙﾁWIC',
  electricityNormal: 257656,
  electricityZEH: 294895,
  energyZEH: -56908,
  electricitySoldZEH: -51180,
  totalZEH: 186807,
  // labelType: '01847',
  // displayType: 'J1',
};
const Arrow = (props: { size?: number } & React.SVGProps<SVGGElement>) => {
  const { size = 1, children, ...defaultProps } = props;
  const rect = useMemo(() => {
    return {
      width: 10 * size,
      height: 5 * size,
      y: (5 * size) / 2,
    }
  }, [size]);

  const triangleD = useMemo(() => {
    return (
      `
        M ${rect.width - 1} 0
        L ${17 * size} ${rect.height}
        L ${rect.width - 1} ${rect.height * 2}
        z
      `
    )
  }, [size, rect]);
  return (
    <g {...defaultProps}>
      <rect y={rect.y} width={rect.width} height={rect.height} fill="rgb(221, 124, 137)" />
      <path
        d={triangleD}
        fill="rgb(221, 124, 137)"
      />
      {children}
    </g>
  )
}

export const ComparisonEcoGraph = (props: SiteTemplateProps) => {
  const {
    param,
    data: templateData,
  } = props;
  const [isLoad, setIsLoad] = useState(false);
  const info = useMemo(() => {
    const {
      utility_costs_general = 0,
      utility_costs_primary = 0,
    } = templateData ?? {};
    return {
      old: {
        total: utility_costs_general,
      },
      new: {
        total: utility_costs_primary,
      }
    }
  }, []);
  const newContainer = useRef<SVGRectElement>(null);
  const oldContainer = useRef<SVGRectElement>(null);
  const [endAnimation, setEndAnimation] = useState(false);

  const scaleAnimation = useMemo(() => endAnimation ? '1' : '0' , [endAnimation]);
  const zehHeight = ((Math.round(info.new.total) / Math.round(info.old.total)) * BASE_GRAPH_HEIGHT) || (BASE_GRAPH_HEIGHT);
  const textPadding = ((zehHeight - TEXT_HEIGHT) / 2) || 0;
  const isNull = useMemo(() => {
    return templateData?.utility_costs_general === undefined
      || templateData?.utility_costs_general === null;
  }, [templateData]);
  const zehTextPos = () => 187 - zehHeight + textPadding;

  const calcMinus = useCallback((costs?: number) => {
    if (typeof costs === 'number') {
      if (!costs) return '0';
      return (costs * -1).toLocaleString();
    }
    return '';
  }, []);

  const OpacityAnimation = () => (
    <animate
      attributeName="opacity"
      begin={`${ANIMATION_TIME}s`}
      dur="0.3s" 
      from="0"
      to={'1'}
      fill="freeze"
      repeatCount="1"
    />
  );
  useEffect(() => {
    window.setTimeout(() => {
      setIsLoad(true);
    }, 300);
    
    window.setTimeout(() => {
      setEndAnimation(true);
    }, 1500)
  }, []);

  if (!param?.utility_costs) return <></>;
  return (
    <Box p="0.25rem" fontFamily={BASE_FONT} transformOrigin="0% 0%">
      <Flex w="96%" flexDirection="column" alignItems="center" px="1rem">
        <Box w="full" color="rgb(11, 70, 114)" fontWeight="bold" fontSize="lg">光熱費の比較</Box>
        <Box p="0.5rem" />
        <Box position="relative" w="full">
          <svg x="0" y="0" viewBox="0 0 400 220" fontFamily={svgFont}>
            <g>
              <rect ref={oldContainer} x="-110px" cy="100%" width={GRAPH_WIDTH} height={isLoad ? BASE_GRAPH_HEIGHT : 0} fill="rgb(75, 77, 77)" y="-180" transform="rotate(180)">
                <animate
                  attributeName="height"
                  dur={`${ANIMATION_TIME}s`} 
                  from="0"
                  to={BASE_GRAPH_HEIGHT}
                  repeatCount="1"
                />
              </rect>
              <g opacity="0">
                <g transform={`translate(70 97.5)`}>
                  <text fontSize="14" textAnchor="middle" fill="#FFF">
                    {/* <OpacityAnimation /> */}
                    光熱費
                  </text>
                  <text y={20} fontSize="7" textAnchor="middle" fill="#FFF">
                    {/* <OpacityAnimation /> */}
                    (電気料金 + ガス料金)
                  </text>
                </g>
                <line
                  x1={110}
                  x2={175}
                  y1={180 - BASE_GRAPH_HEIGHT}
                  y2={180 - zehHeight}
                  strokeDasharray="2px"
                  stroke="rgb(11, 70, 114)"
                  visibility={isNull ? 'hidden' : undefined}
                />
                <Arrow size={1.5} transform={`translate(130 ${172 - ((zehHeight ?? 0) / 2)})`} />
                <OpacityAnimation />
              </g>
            </g>
            <g opacity="0">
              <text x={72} y={180 - BASE_GRAPH_HEIGHT - 5} width={GRAPH_WIDTH} fontSize="16" textAnchor="middle">
                {(info.old.total)?.toLocaleString?.()}<tspan fontSize="12"> 円</tspan>
              </text>
              <text x={215} y={172 - (zehHeight ?? 0)} fontSize="19" fill="rgb(221, 124, 137)" textAnchor="middle" fontWeight="bold" style={{ visibility: !isNull ? undefined : 'hidden' }}>
                {(info.new.total)?.toLocaleString?.()}
                <tspan fontSize="12"> 円</tspan>
              </text>
              <OpacityAnimation />
            </g>
            <g visibility={isNull ? 'hidden' : undefined}>
              <rect ref={newContainer} x="-255px" cy="100%" width={GRAPH_WIDTH} height={isLoad ? zehHeight : 0} fill="rgb(32, 75, 132)" y="-180" transform="rotate(180)">
                <animate
                  attributeName="height"
                  dur={`${ANIMATION_TIME * ((zehHeight ?? 0) / BASE_GRAPH_HEIGHT)}s`}
                  from="0"
                  to={zehHeight}
                  repeatCount="1"
                />
              </rect>
              <g transform={`translate(215 ${zehTextPos() ?? 0})`} textAnchor="middle" opacity="0">
                <g>
                  <text fontSize="10" fill="#FFF">
                    光熱費
                  </text>
                  <text y={10} fontSize="7" fill="#FFF">
                    (電気料金+ガス料金)
                  </text>
                  <text y={22} fontSize="10" fill="#FFF">
                    {templateData?.utility_costs?.toLocaleString?.() ?? ''}
                    <tspan fontSize="9">円</tspan>
                  </text>
                </g>
                <g transform={`translate(0 32)`}>
                  <text fontSize="9" textAnchor="middle" fill="#FFF">
                    +
                  </text>
                </g>
                <g transform={`translate(0 43)`} textAnchor="middle">
                  <text fontSize="9"fill="#FFF">
                    創エネ
                  </text>
                  <text y={12} fontSize="10" textAnchor="middle" fill="#FFF">
                    {calcMinus(templateData?.energy_creation)}
                    <tspan fontSize="9">円</tspan>
                  </text>
                </g>
                <g transform={`translate(0 64)`}>
                  <text fontSize="9" textAnchor="middle" fill="#FFF">
                    +
                  </text>
                </g>
                <g transform={`translate(0 75)`}>
                  <text fontSize="9" textAnchor="middle" fill="#FFF">
                    売電
                  </text>
                  <text y={14} fontSize="10" textAnchor="middle" fill="#FFF">
                    {calcMinus(templateData?.power_selling)}
                    <tspan fontSize="9">円</tspan>
                  </text>
                </g>
                <OpacityAnimation />
              </g>
            </g>
            <g transform="translate(0 180)">
              <line x1="0" x2="450" stroke="#000" />
              <text fontSize="12" y="-4" x="400" textAnchor="end">（円 / 年間）</text>
              <g transform="translate(70 19)">
                <text y="0" fontSize="13" textAnchor="middle">省エネ基準の家</text>

                <text x="145" fontSize="13" textAnchor="middle">本物件</text>
              </g>
            </g>
          </svg>
          <Box  transitionDuration="0.4s" transform={`scale(${scaleAnimation})`} position="absolute" top="0" left="0" w="100%" h="100%" transformOrigin="65% 75%">
          <Box
            position="absolute"
            right="calc(7rem - 20px)"
            top="5rem"
            w="0"
            h="0"
            borderTop="0px solid transparent"
            borderBottom="3.45rem solid rgb(143, 136, 131)"
            borderLeft="1.5rem solid transparent"
            transform="rotate(213deg)"
          />
          <Box bg="rgb(143, 136, 131)" h="5.5rem" w="calc(10.5rem - 20px)" borderRadius="5%" position="absolute" right="-1.5rem" top="0.5rem">
            <Box
              top="-2px"
              left="-2px"
              position="absolute"
            >
              <Box
                h="5.5rem"
                w="calc(10.5rem - 20px)"
                borderRadius="5%"                  
                bg="#FFF"
                position="relative"
              >
                <Box
                  position="absolute"
                  top="4.5rem"
                  left="0.5rem"
                  w="0"
                  h="0"
                  borderTop="0px solid transparent"
                  borderBottom="3.5rem solid #fff"
                  borderLeft="1.5rem solid transparent"
                  transform="rotate(210deg)"
                />
                <Box position="absolute" w="full" h="full" p="0.2rem">
                  <Box w="full" h="full" border="1px solid rgb(221, 124, 137)" borderRadius="5%" position="relative">
                    <Image position="absolute" alt="light.svg" src={lightIcon} w="35px" h="35px" right="-5" top="-4" />
                  </Box>
                </Box>
              </Box>
              <Box position="absolute" top="0" w="full" fontWeight="bold">
                <Flex flexDirection="column" p="0.5rem" alignItems="center">
                  <Text color="rgb(11, 70, 114)" fontSize="sm">光熱費&nbsp;年間</Text>
                  <Divider borderColor="rgb(11, 70, 114)" />
                  <Flex alignItems="end" pt="0.25rem">
                    <Box fontSize="sm">約</Box>
                    <Box color="rgb(221, 124, 137)" textAlign="right" fontSize="26px" style={{fontVariant: 'tabular-nums'}} minW="5.5rem" lineHeight="25px" px="0.25rem">
                      {` ${templateData?.utility_costs_annual_difference?.toLocaleString?.() ?? ''} `}
                    </Box>
                    <Box fontSize="sm">円</Box>
                  </Flex>
                  <Box w="full">
                    <Text textAlign="right" color="rgb(11, 70, 114)">お得！</Text>
                  </Box>
                </Flex>
              </Box>
            </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}
