import { LabeledComponentGroup, LabeledComponent } from '../../labeled-component/labeled-component';
import { DialogBody } from '../dialog';
import { useArticleEdit } from './hooks/use-article-edit';
import { InputDate } from '../../input/input-date';
import { useMemo } from 'react';
import { Input } from '../../input/input';
import RootStore from '../../../../store/root.store';

type Props = {
  id: string,
  callback: () => void,
  isDelete: boolean,
}

export const ArticleEditDialog = (props: Props) => {
  const {
    id,
    callback,
    isDelete,
  } = props;

  const {
    info,
    request,
    setRequest,
    Footer,
    RadioArea,
    validation,
    onChangeEndDate,
  } = useArticleEdit(id, isDelete, callback);

  const {
    user,
  } = RootStore;

  const siteUrl = useMemo(() => {
    const {
      protocol,
      host,
    } = document.location;
    return `${protocol}//${request.subdomain}.${host}`;
  }, [request]);

  return (
    <DialogBody
      bodyProps={{ w: '620px', maxW: '100%', minW: '200px' }}
      footer={<Footer />}
    >
      <LabeledComponentGroup labelWidth='210px' minW="calc(350px + 1rem)">
        <LabeledComponent label="企業ID" invisible={!user.system_admin_flag}>
          {info?.company_login_id ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="企業名" invisible={!user.system_admin_flag}>
          {info?.company_name ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="物件No">
          {info?.number ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="物件名" require>
          <Input
            value={request.name}
            onChange={(e) => setRequest({ name: e.target.value })}
            isDisabled={isDelete}
            validations={validation.name}
          />
        </LabeledComponent>
        <LabeledComponent label="作成日">
          {info?.registration_date?.replaceAll('-', '/') ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="最終作業日">
          {info?.last_update_date?.replaceAll('-', '/') ?? '-'}
        </LabeledComponent>
        <LabeledComponent label="公開設定">
          <RadioArea />
        </LabeledComponent>
        <LabeledComponent label="公開終了日" require invisible={!user.system_admin_flag}>
          <InputDate
            value={request.site_release_end_expected_date}
            onChange={(e) => onChangeEndDate(e)}
            isDisabled={isDelete}
            validations={validation.site_release_end_expected_date}
          />
        </LabeledComponent>
        <LabeledComponent label="サブドメイン名" require>
          <Input
            value={request.subdomain}
            onChange={(e) => setRequest({ subdomain: e.target.value })}
            isDisabled={isDelete}
            validations={validation.subdomain}
          />
        </LabeledComponent>
        <LabeledComponent label="サイトURL">
          {siteUrl}
        </LabeledComponent>
      </LabeledComponentGroup>
    </DialogBody>
  )  
}