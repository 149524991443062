import { Box, Center, Heading } from "@chakra-ui/react";
import { CustomButton } from "../../ui/button/button";
import { Input } from "../../ui/input/input";
import { LoginFrame } from "./component/login-frame";
import { useLogin } from './hooks/use-login';


export const Login = () => {
  const {
    idData,
    passData,
    loginButton,
  } = useLogin();

  return (
    <Center h="100vh">
      <LoginFrame>
        <Heading
          as="h1"
          fontSize="70px"
          fontWeight="normal"
          my="1.5rem"
          textShadow="0 2px 2px rgba(0, 0, 0, 0.25)"
        >
          MSPシステム
        </Heading>
        <Input {...idData} />
        <Input {...passData} />
        <CustomButton {...loginButton} w="100%">
          ログイン
        </CustomButton>
      </LoginFrame>
    </Center>
  )
}