import { useCallback, useMemo, useState, useEffect, } from "react"
import { AlignData, TableBodyDataEntity } from '../../../ui/table/table';
import RootStore from '../../../../store/root.store';
import { AddCompanyDialog } from "../../../ui/dialog/company-add/add-company.dialog";
import { DetailCompanyDialog } from "../../../ui/dialog/company-detail/detail-company.dialog";
import { CustomButton } from "../../../ui/button/button";
import { Img } from "@chakra-ui/react";
import iconEdit from '../../../../assets/icon/icon_edit.svg';
import { EditCompanyDialog } from "../../../ui/dialog/company-edit/edit-company.dialog";
import { CompanyListSettingDialog } from "../../../ui/dialog/company-list-setting.dialog/company-list-setting.dialog";
import { Api } from "../../../../module/api";
import { CompanyListDataEntity, CompanyListRequestParam } from '../../../../module/api/routes/companies/index';
import { useSort } from "../../../../hooks/utile/use-sort";
import { useApiErrorDialog } from '../../../../hooks/utile/use-error-dialog';
import { findPrefectureLabel } from '../../../../model/prefecture-list';

const companyAlign: AlignData<CompanyListDataEntity>[] = [
  { key: 'id', align: 'center' },
  { key: 'industry', align: 'center' },
  { key: 'prefecture', align: 'center' },
  { key: 'registration_date', align: 'center' },
  { key: 'suspend_date', align: 'center' },
  { key: 'suspend_flag', align: 'center' },
];

export const useCompaniesPage = () => {
  const { dialog } = RootStore;
  const { displayCompanyHeaders } = RootStore.common;
  const errDialog = useApiErrorDialog();
  const { sort, setSort } = useSort<CompanyListRequestParam>({
    initialSort: {
      keyword: '',
      suspend_flag: 0,
    }
  });
  
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [companyList, setCompanyList] = useState<CompanyListDataEntity[]>([]);
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const getList = useCallback((req?: typeof sort) => {
    setError(false);
    const request = req ?? sort;
    Api.connect().companies().getList(request).then((res) => {
      const { companies } = res.data;
      setCompanyList(companies);
      setTotalCount(companies.length);
      setLoad(true);
    }).catch((e) => {
      errDialog.push(e);
      setError(true);
    }).finally(() => {
      setLoad(true);
    })
  }, [sort, errDialog]);

  const setRequest = useCallback((req: Partial<typeof sort>) => {
    const request = { ...sort, ...req };
    setSort({ ...request });
    getList(request);
  }, [sort, getList, setSort]);

  const TableModel = useMemo(() => {
    const bodyList: TableBodyDataEntity<CompanyListDataEntity>[] = companyList.map((companyData) => {
      const result = displayCompanyHeaders.map((headerData) => {
        const align = companyAlign.find((v) => v.key === headerData.key)?.align;
        switch(headerData.key) {
          case 'prefecture':
            return {
              elm: findPrefectureLabel(companyData[headerData.key]) ?? '',
              align,
            }
          case 'suspend_date':
          case 'registration_date': {
            return {
              elm: companyData[headerData.key]?.replaceAll('-', '/') ?? '',
              align,
            }
          }
          case 'suspend_flag':
            return {
              elm: companyData[headerData.key] ? '利用停止' : '利用中',
              align,
            }
          default: 
            return {
              elm: companyData[headerData.key] ?? '',
              align,
            };
        }
      });
      return {
        data: companyData,
        list: result,
        deleted: companyData.suspend_flag,
      };
    });
    return {
      body: bodyList,
      header: displayCompanyHeaders,
      isLoading: load,
      isError: error,
    };
  }, [displayCompanyHeaders, companyList, load, error]);

  const onClickTableRow = useCallback((rowData: CompanyListDataEntity) => {
    const { id, system_admin_flag } = rowData;
    const openEdit = () => {
      dialog.pop();
      dialog.push({
        title: '企業編集',
        children: <EditCompanyDialog
          id={id}
          getList={getList}
          system_admin_flag={system_admin_flag}
        />
      })
    }
    dialog.push({
      title: '企業詳細',
      headerElm: <CustomButton
        minW="fit-content"
        tabIndex={-1}
        onClick={openEdit}
        leftIcon={<Img src={iconEdit} alt="icon_edit" mb="2px" />}
      >
          編集
        </CustomButton>,
      children: <DetailCompanyDialog id={id} />
    })
  }, [dialog, getList]);

  const onClickAddCompany = useCallback(() => {
    dialog.push({
      title: '企業新規登録',
      children: <AddCompanyDialog getList={getList} />,
    })
  }, [dialog, getList]);

  const onClickListSetting = useCallback(() => {
    dialog.push({
      title: 'リスト表示設定',
      children: <CompanyListSettingDialog />
    })
  }, [dialog]);

  useEffect(() => {
    getList();
  }, []);
  return {
    TableModel,
    totalCount,
    onClickAddCompany,
    onClickTableRow,
    onClickListSetting,
    sort, setSort,
    setRequest,
  }
}