import { useEffect, useMemo, useRef, useState } from "react";
import { UseCageReturnType } from "../../hooks/use-msp-basket";
import { Basket, RoomDataEntity } from "../../module/basket"
import { px2pt } from "./cage";

type Props = {
  height: number,
  rectPath: string,
  roomData: RoomDataEntity,
  model: Basket,
  textColor: string,
  ignoreSelect?: boolean;
} & Omit<UseCageReturnType, 'TopComponent' | 'model' | 'mergeRoomStyle'>;

export const SVGRoomArea = (props: Props) => {
  const {
    height,
    onSelectRoom,
    roomStyle,
    rectPath,
    roomData: room,
    textColor,
    ignoreSelect,
  } = props;
  const textGroup = useRef<SVGGElement>(null);
  const pathElm = useRef<SVGPathElement>(null);
  const prevTextWidth = useRef(0);
  const [scale, setScale] = useState<number | null>(null);
  const transform = useMemo(() => {
    if (!scale) {
      return `translate(${px2pt(room.width / 2)} ${px2pt(height / (room.isBottom ? 1 : 2))})`
    } else {
      return `translate(${px2pt(room.width / 2)} ${px2pt(height / (room.isBottom ? 1 : 2))}) scale(${scale} ${scale})`
    }
  }, [room, height, scale]);
  
  const resize = () => {
    if (!textGroup.current || !pathElm.current) return;
    const _scale = scale ?? 1;
    const textRect = textGroup.current.getClientRects();
    const textWidth = (textRect.item(0)?.width ?? 0) / _scale;
    const pathRect = pathElm.current.getClientRects();
    const pathWidth = (pathRect.item(0)?.width ?? 0);
    prevTextWidth.current = textWidth;
    if (textWidth > pathWidth) {
      const magnification = (Math.floor(((pathWidth - 4) / textWidth) * 100)) / 100;
      if (!magnification) {
        setScale(0.01);
      } else {
        setScale(magnification);
      }
    } else {
      setScale(1);
    }
  }
  useEffect(() => {
    if (!textGroup.current || !pathElm.current) return;
    resize();
  }, [textGroup, pathElm, room]);
  if (room.isNonUnitRoom) {
    return (
      <>
        <path
          ref={pathElm}
          d={rectPath}
          strokeWidth="4"
          cursor={ignoreSelect ? undefined : 'pointer'}
          onClick={() => onSelectRoom(room)}
          {...roomStyle(room)}
        />
        {!room.ignoreLabel && <g transform={transform} ref={textGroup}>
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="12px"
            style={{ pointerEvents: 'none', userSelect: 'none' }}
            fill={textColor}
          >
            {room.editableData.label}
          </text>
        </g>}
      </>
    );
  };

  return (
    <>
      <path
        ref={pathElm}
        d={rectPath}
        strokeWidth="4"
        cursor={ignoreSelect ? undefined : 'pointer'}
        onClick={() => {
          if (ignoreSelect) return;
          onSelectRoom(room);
        }}
        {...roomStyle(room)}
      />
      <g transform={transform} ref={textGroup}>
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="12px"
          style={{ pointerEvents: 'none', userSelect: 'none' }}
          dy="-8px"
          fill={textColor}
        >
          {room.editableData.type}
        </text>
        {!room.ignoreLabel && (
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="12px"
            style={{ pointerEvents: 'none', userSelect: 'none' }}
            dy="8px"
            fill={textColor}
          >
          {room.editableData.label}
          </text>
        )}
      </g>
    </>
  )
}