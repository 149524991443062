import { IConnection } from "./connection/connection-base";
import { ConnectionForClient } from './connection/connection';
import login from "./routes/login";
import isLogin from "./routes/is-login/is-login";
import logout from "./routes/logout/logout";
import companies from "./routes/companies";
import properties from "./routes/properties";
import site from "./routes/site";
import RootStore from "../../store/root.store";

export type Mode = 'local' | 'develop' | 'stage' | 'production';

type Param = {
  mode: Mode,
};


export default class API {
  static con: IConnection = ConnectionForClient;
  private host: string;
  private readonly apiVersion = 'v1';
  constructor() {
    if (this.isLocal()) {
      this.host = 'https://msp-system.marietta.dev';
    } else {
      this.host = '.';
    }
  }

  private isLocal = () => {
    return window.location.hostname.includes('localhost') && !!window.location.port
  };
  private hosts = () => {
    return `${this.host}/api/${this.apiVersion}`;
  }
  public connect = () => ({
    login: login(this.hosts()),
    isLogin: isLogin(this.hosts()),
    logout: logout(this.hosts()),
    companies: companies(this.hosts()),
    properties: properties(this.hosts()),
    site: site(this.hosts()),
  });
  public setApiHost = (domain: string) => {
    if (this.isLocal()) return;
    this.host = `${window.location.protocol}//${domain}`;
  }
  
}

export const getSessionTokenFromLocalStorage = ():string => {
  localStorage.getItem('session_token');
  return localStorage.getItem('session_token') ?? '';
}


export const saveTokenToLocalStorage = (session: string) => {
  if (session) {
    localStorage.setItem('session_token', `Bearer ${session}`);
  }
}

export const deleteToken = () => {
  localStorage.removeItem('session_token');

}

export const createGetHeaders = () => {
  return { 
    'Authorization': getSessionTokenFromLocalStorage(),
 }
}


export const Api = new API()