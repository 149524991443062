import API from "../../..";
import { fullUrl } from '../../../utile/utile.api';
import { MSPColorSettingDataEntity } from "../../properties/colors";
import { EnergyDataEntity } from "../../properties/energy-saving-data";
import { TemplatesResponseData } from "../../properties/templates";

const path = '/energy-saving';

export type SiteEnergySavingColorData = {
  /** ○	省エネ文字色 */
  energy_saving_text_color: string,
  /** ○	省エネ帯色 */
  energy_saving_band_color: string,
}

export type SiteEnergySavingDataEntity = {
  /** ○	①タイプ名 */
  dwelling_unit_type: string,
  /** ○	②号室 */
  dwelling_unit_number: number,
  /** ○	①省エネ基準の家の光熱費 */
  utility_costs_general: number,
  /** ○	②本物件の総光熱費 */
  utility_costs_primary: number,
  /** ○	③光熱費 */
  utility_costs: number,
  /** ○	④創エネ */
  energy_creation: number,
  /** ○	⑤売電 */
  power_selling: number,
  /** ○	⑥光熱費年間差額 */
  utility_costs_annual_difference: number,
  /** ○	①ZEH名の表示 */
  zeh_name: string,
  /** ○	②削減率（％） */
  reducing_rate: number,
  /** 	①断熱性能等級 */
  grade: number,
  /** 	②UA値：省エネ基準 */
  ua_energy_saving: number,
  /** 	③UA値：ZEH基準の家 */
  ua_zeh: number,
  /** ○	④UA値：あなたの家 */
  ua: number,
  /** ○	①本数 */
  count: number,
  /** ○	②Kg */
  kg: number,
  /** 省エネラベルURL */
  label_url: string,
}

export type SiteEnergySavingBDataEntity = {
  /** ○	①タイプ名 */
  dwelling_unit_type: string;
  /** ○	②号室 */
  dwelling_unit_number: number;
  /** ○	①省エネ基準の家の光熱費 */
  utility_costs_general: number;
  /** ○	②本物件の総光熱費 */
  utility_costs_primary: number;
  /** ○	③光熱費 */
  utility_costs: number;
  /** ○	④創エネ */
  energy_creation: number;
  /** ○	⑤売電 */
  power_selling: number;
  /** ○	⑥光熱費年間差額 */
  utility_costs_annual_difference: number;
  /** ○	①一次エネルギー消費量（省エネ基準） */
  primary_energy_standard: number;
  /** ○	②一次エネルギー消費量（設計） */
  primary_energy_design: number;
  /** ○	③一次エネルギー消費量削減率（再エネ除く） */
  energy_reduction_no_renewable: number;
  /** ○	④一次エネルギー消費量削減率（再エネ含む） */
  energy_reduction_with_renewable: number;
  /** ○	④暖房設備（一次エネ設計全体の内構成率） */
  heating_percentage: number;
  /** ○	⑤冷房設備（一次エネ設計全体の内構成率） */
  cooling_percentage: number;
  /** ○	⑥換気設備（一次エネ設計全体の内構成率） */
  ventilation_percentage: number;
  /** ○	⑦給湯設備（一次エネ設計全体の内構成率） */
  hot_water_percentage: number;
  /** ○	⑧照明設備（一次エネ設計全体の内構成率） */
  lighting_percentage: number;
  /** ○	⑨その他の設備（一次エネ設計全体の内構成率） */
  other_percentage: number;
  /** ○	④暖房設備（MJ/年） */
  heating_equipment: number;
  /** ○	⑤冷房設備（MJ/年） */
  cooling_equipment: number;
  /** ○	⑥換気設備（MJ/年） */
  ventilation_equipment: number;
  /** ○	⑦給湯設備（MJ/年） */
  hot_water_supply_equipment: number;
  /** ○	⑧照明設備（MJ/年） */
  lighting_equipment: number;
  /** ○	⑨その他の設備（MJ/年） */
  other_equipment: number;
  /** ①断熱性能等級 */
  grade: number;
  /** 	②UA値：省エネ基準 */
  ua_energy_saving: number;
  /** 	③UA値：ZEH基準の家 */
  ua_zeh: number;
    /** ○	④UA値：あなたの家 */
  ua: number;
  /** ○	①本数 */
  count: number;
  /** ○	②Kg */
  kg: number;
  /** ○	燃料単価　電気 */
  fuel_price_electricity: number;
  /** ○	燃料単価　ガス（基準） */
  fuel_price_gas_standard: number;
  /** ○	燃料単価　ガス（設計） */
  fuel_price_gas_design: number;
  /** ○	燃料単価　灯油 */
  fuel_price_kerosene: number;
  /** ○	燃料単価　売電（コージェネレーション） */
  fuel_price_selling_cogeneration: number;
  /** ○	燃料単価　売電（太陽光発電） */
  fuel_price_selling_solar: number;
  /** ○	設計二次エネルギー消費量　電気 */
  design_secondary_energy_electricity: number;
  /** ○	設計二次エネルギー消費量　ガス */
  design_secondary_energy_gas: number;
  /** ○	設計二次エネルギー消費量　灯油 */
  design_secondary_energy_kerosene: number;
  /** ○	設計二次エネルギー消費量　自家発電 */
  design_secondary_energy_in_house_power: number;
  /** ○	設計二次エネルギー消費量　売電（コージェネレーション） */
  design_secondary_energy_power_selling_cogeneration: number;
  /** ○	設計二次エネルギー消費量　売電（太陽光発電） */
  design_secondary_energy_power_selling_solar_power: number;
  /** エアコン有無 false:無し true:あり */
  air_conditioner_flag?: boolean;
} & TemplateBImgOutWallData & TemplateBImgFirstEnergyData;


/* 外皮性能イラスト情報 */
export type TemplateBImgOutWallData = {
  /** 外壁_タイトル */
  outer_wall_title?: string;
  /** 外壁_断熱材情報１ */
  outer_wall_insulating_material_info_1?: string;
  /** 外壁_厚さ１ */
  outer_wall_thickness_1?: number;
  /** 外壁_単位１ */
  outer_wall_unit_1?: string;
  /** 外壁_断熱材情報２ */
  outer_wall_insulating_material_info_2?: string;
  /** 外壁_厚さ２ */
  outer_wall_thickness_2?: number;
  /** 外壁_単位２ */
  outer_wall_unit_2?: string;
  /** 屋根_タイトル */
  roof_title?: string;
  /** 屋根_断熱材情報１ */
  roof_insulating_material_info_1?: string;
  /** 屋根_厚さ１ */
  roof_thickness_1?: number;
  /** 屋根_単位１ */
  roof_unit_1?: string;
  /** 屋根_断熱材情報２ */
  roof_insulating_material_info_2?: string;
  /** 屋根_厚さ２ */
  roof_thickness_2?: number;
  /** 屋根_単位２ */
  roof_unit_2?: string;
  /** 天井_タイトル */
  ceiling_title?: string;
  /** 天井_断熱材情報１ */
  ceiling_insulating_material_info_1?: string;
  /** 天井_厚さ１ */
  ceiling_thickness_1?: number;
  /** 天井_単位１ */
  ceiling_unit_1?: string;
  /** 天井_断熱材情報２ */
  ceiling_insulating_material_info_2?: string;
  /** 天井_厚さ２ */
  ceiling_thickness_2?: number;
  /** 天井_単位２ */
  ceiling_unit_2?: string;
  /** 窓_タイトル */
  window_title?: string;
  /** 窓_断熱材情報１ */
  window_insulating_material_info_1?: string;
  /** 窓_厚さ１ */
  window_thickness_1?: number;
  /** 窓_単位１ */
  window_unit_1?: string;
  /** 窓_断熱材情報２ */
  window_insulating_material_info_2?: string;
  /** 窓_厚さ２ */
  window_thickness_2?: number;
  /** 窓_単位２ */
  window_unit_2?: string;
  /** 床_タイトル */
  floor_title?: string;
  /** 床_断熱材情報１ */
  floor_insulating_material_info_1?: string;
  /** 床_厚さ１ */
  floor_thickness_1?: number;
  /** 床_単位１ */
  floor_unit_1?: string;
  /** 床_断熱材情報２ */
  floor_insulating_material_info_2?: string;
  /** 床_厚さ２ */
  floor_thickness_2?: number;
  /** 床_単位２ */
  floor_unit_2?: string;
}

export type TemplateBImgOutWallKey = 'outer_wall' | 'roof' | 'ceiling' | 'window' | 'floor'

/* 一次エネルギーイラスト情報 */
export type TemplateBImgFirstEnergyData = {
  /** 暖房設備_タイトル */
  heat_eqp_title?: string;
  /** 暖房設備_機器名称 */
  heat_eqp_name?: string;
  /** 換気設備_タイトル */
  vent_eqp_title?: string;
  /** 換気設備_機器名称 */
  vent_eqp_name?: string;
  /** 給湯設備_浴室シャワー水栓_タイトル */
  hot_water_eqp_bath_shower_faucet_title?: string;
  /** 給湯設備_浴室シャワー水栓_機器名称 */
  hot_water_eqp_bath_shower_faucet_name?: string;
  /** 照明設備 非居室_タイトル */
  lighting_eqp_non_living_title?: string;
  /** 照明設備 非居室_機器名称 */
  lighting_eqp_non_living_name?: string;
  /** 冷房設備_タイトル */
  cool_eqp_title?: string;
  /** 冷房設備_機器名称 */
  cool_eqp_name?: string;
  /** 給湯設備_台所水栓_タイトル */
  hot_water_eqp_kitchen_faucet_title?: string;
  /** 給湯設備_台所水栓_機器名称 */
  hot_water_eqp_kitchen_faucet_name?: string;
  /** 給湯設備_洗面水栓_タイトル */
  hot_water_eqp_face_wash_faucet_title?: string;
  /** 給湯設備_洗面水栓_機器名称 */
  hot_water_eqp_face_wash_faucet_name?: string;
  /** 給湯設備_高断熱浴槽_タイトル */
  hot_water_eqp_insulated_bathtub_title?: string;
  /** 給湯設備_高断熱浴槽_機器名称 */
  hot_water_eqp_insulated_bathtub_name?: string;
  /** 給湯設備_熱源機_タイトル */
  hot_water_eqp_heat_source_title?: string;
  /** 給湯設備_熱源機_機器名称 */
  hot_water_eqp_heat_source_name?: string;
}

export type TemplateBImgFirstEnergyKey = 'heat_eqp'
  | 'vent_eqp'
  | 'hot_water_eqp_bath_shower_faucet'
  | 'lighting_eqp_non_living'
  | 'cool_eqp'
  | 'hot_water_eqp_kitchen_faucet'
  | 'hot_water_eqp_face_wash_faucet'
  | 'hot_water_eqp_insulated_bathtub'
  | 'hot_water_eqp_heat_source'

export type SiteEnergySavingData = {
  template_A_value: SiteEnergySavingDataEntity[],
  template_B_value: SiteEnergySavingBDataEntity[],
  template: TemplatesResponseData,
  color: SiteEnergySavingColorData,
}

export type RequestSiteEnergySavingData = {
  dwelling_unit_numbers: string[],
}

const energySaving = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    get: (param: RequestSiteEnergySavingData) => API.con.get<{}, SiteEnergySavingData>({ url: full, data: param }),
  });
}

export default energySaving;
