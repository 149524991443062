import { Flex, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Text, IconButton, Img, Link as ChakraLink } from '@chakra-ui/react';
import { useCallback, useMemo } from "react";
import RootStore from "../../../store/root.store";
import { CustomButton } from "../../ui/button/button"
import { useNavigate } from 'react-router-dom';
import { RoutingPath } from '../../../routes/routing-path';
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Api } from "../../../module/api";
import { deleteToken } from '../../../module/api/index';
import iconHelp from '../../../assets/icon/icon_help.svg';
import iconRules from '../../../assets/icon/icon_rules.svg';
import iconSupport from '../../../assets/icon/icon_total_support_site.svg';
import iconManual from '../../../assets/icon/icon_manual.svg';

export const Header = observer(() => {
  const { dialog, user } = RootStore;
  const navigator = useNavigate();
  const topPath = useMemo(() => {
    return user.system_admin_flag ? RoutingPath.companies : RoutingPath.articles;
  }, [user.system_admin_flag])
  const logout = useCallback(async() => {
    // ログアウト処理→ログインページに遷移
    try {
      await Api.connect().logout().post();
    } catch(e) {
    } finally {
      RootStore.user.destroy();
      deleteToken();
      navigator(RoutingPath.login);
    }
  }, [navigator]);


  const onClickLogout = useCallback(() => {
    dialog.pushMessage({
      bodyProps: { paddingTop: '3.5rem' },
      messages: ['ログアウトします。', 'よろしいですか？'],
      buttons: [
        { label: 'キャンセル', color: 'secondary', callback: () => dialog.pop() },
        { label: 'OK', asyncCallback: logout },
      ]
    });
  }, [logout, dialog]);

  return (
    <Flex
      w="100%"
      h="100%"
      alignItems="center"
      justifyContent="space-between"
      pl="1.5rem"
      pr="0"
    >
      {/* TODO ロゴに変更予定 */}
      <Link to={topPath}>
        <Text
          color="#fff"
          fontSize="2xl"
          fontWeight="bold"
          textShadow="0 4px 4px rgba(0, 0, 0, 0.25)"
          mb="2px"
        >
          MSPシステム
        </Text>
      </Link>
      <Flex gap="1rem" pr="1rem">
        <Menu>
          <MenuButton
            fontSize="large"
            fontWeight="bold"
            color="#fff"
          >
            {RootStore.user.name}
          </MenuButton>
          <MenuList px="1rem" minW="none" mt="-4px" mr="-4px">
            <MenuGroup title={RootStore.user.name} color="#000" fontWeight="normal" fontSize="sm" textAlign="center">
              <MenuDivider px="1rem" borderColor="#000" />
              <MenuItem
                p="0.5rem 1rem"
                color="#000"
                _hover={{
                  bg: 'transparent',
                }}
                justifyContent="center"
                fontSize="sm"
                _focus={{
                  bg: 'transparent',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClickLogout();
                }}
              >
                ログアウト
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            as={IconButton}
            fontSize="large"
            fontWeight="bold"
            color="#fff"
            pr="0"
            icon={<Img src={iconHelp} alt="help" />}
            borderRadius="100vh"
            bg="transparent"
            _focusVisible={{}}
            _active={{
              bg: 'transparent',
            }}
          />
          <MenuList px="1rem" minW="none" mt="-4px" mr="-4px">
            <MenuGroup color="#000" fontWeight="normal" fontSize="sm">
              <MenuItem
                p="0.5rem 0"
                color="#000"
                cursor="initial"
                _hover={{ bg: 'transparent' }}
                fontSize="sm"
                _focus={{ bg: 'transparent' }}
              >
                <ChakraLink href={RootStore.env.rules} target="_blank" display="flex" alignItems="center">
                  <Img src={iconRules} mr="0.25rem" />
                  利用規約
                </ChakraLink>
              </MenuItem>
              <MenuItem
                p="0.5rem 0"
                color="#000"
                cursor="initial"
                _hover={{ bg: 'transparent' }}
                fontSize="sm"
                _focus={{ bg: 'transparent' }}
              >
                <ChakraLink href={RootStore.env.manual} target="_blank" display="flex" alignItems="center">
                  <Img src={iconManual} mr="0.25rem" />
                  マニュアル
                </ChakraLink>
              </MenuItem>
              <MenuItem
                p="0.5rem 0"
                color="#000"
                cursor="initial"
                _hover={{ bg: 'transparent' }}
                fontSize="sm"
                _focus={{ bg: 'transparent' }}
              >
                <ChakraLink href={RootStore.env.support} target="_blank" display="flex" alignItems="center">
                  <Img src={iconSupport} mr="0.25rem" />
                  サポートお問い合わせ
                </ChakraLink>
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  )
});