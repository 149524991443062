import { Text, GridItem, VStack, Grid, StackProps } from '@chakra-ui/react';
import React, { ReactNode, useEffect } from "react"
import { Require } from '../require-label/require-label';

type Props = {
  label: string,
  children?: ReactNode,
  w?: string,
  require?: boolean,
  invisible?: boolean,
}

export const LabeledComponent = (props: Props) => {
  const { label, children, w, require, invisible } = props;
  if (invisible) {
    return <></>;
  }
  return (
    <Grid
      templateColumns={`${w ?? 0} 1fr`}
      gap="1rem"
      w="100%"
    >
      <GridItem w={w} justifyContent="space-between" alignItems="center" display="flex">
        <Text display="block">{label}</Text>
        {!!require && <Require />}
      </GridItem>
      <GridItem w="100%">
        {children}
      </GridItem>
    </Grid>
  );
}

LabeledComponent.displayName = 'child_labeled_component';

export const LabeledComponentGroup = (props: { labelWidth: string, children: ReactNode } & StackProps) => {
  const {
    labelWidth,
    children,
    ...defaultProps
  } = props;
  const enchancedChildren = React.Children.map(children, (v) => {
    if (
      React.isValidElement(v) 
        && (v?.type as any)?.displayName === 'child_labeled_component'
      ) {
        const childProps = (v.props ?? {}) as Props;
        const prop = {
          ...childProps,
          w: labelWidth,
        }
        return React.cloneElement(v, prop);
    }
    return v;
  });
  console.groupEnd();

  useEffect(() => {

  }, [props.labelWidth])
  return (
    <VStack gap="1.5rem" {...defaultProps}>
      {enchancedChildren}
    </VStack>
  )
}