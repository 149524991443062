import { Flex } from "@chakra-ui/react"

type Props = {
  children: React.ReactNode,
  gap?: string,
}

export const LeftBox = (props: Props) => {
  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      w="100%"
      gap={props.gap}
    >
      {props.children}
    </Flex>
  )
}