import { CustomButton } from "../../ui/button/button";
import { Checkbox } from "../../ui/checkbox/checkbox";
import { SearchInput } from "../../ui/input/search-input";
import { LeftBox } from "../../ui/left-box/left-box";
import { RightBox } from "../../ui/right-box/right-box";
import { Flex, Box, Img } from '@chakra-ui/react';
import { DataCount } from "../../ui/table/data-count";
import { Table } from "../../ui/table/table";
import { useArticlesPage } from "./hooks/use-articles";
import { useState } from "react";
import plusIcon from '../../../assets/icon/icon_plus.svg';
import { Pager } from "../../ui/pager/pager";
import { Layout } from "../../layout/layout";

export const Articles = () => {
  const {
    TableModel,
    listInfo,
    onClickAddArticle,
    onClickTableRow,
    dataCountInfo,
    sort, setRequest,
  }  = useArticlesPage();

  return (
    <Layout>
      <RightBox>
        <CustomButton
         leftIcon={<Img src={plusIcon} />}
         onClick={onClickAddArticle}
        >
         物件新規登録 
        </CustomButton>
      </RightBox>
      <LeftBox gap="1.5rem">
        <SearchInput
          placeholder="キーワードを入力"
          maxW="380px"
          onSearch={(v) => setRequest({ keyword: v ?? '', page: 1 })}
        />
        <Checkbox
          size="xl"
          isChecked={!!sort.delete_flag}
          onChange={() => setRequest({ delete_flag: sort.delete_flag ? 0 : 1, page: 1 })}
        >
          削除物件を表示
        </Checkbox>
      </LeftBox>
      <Flex justifyContent="space-between" w="100%" alignItems="flex-end">
        <DataCount count={listInfo?.total ?? null} page={{ to: dataCountInfo?.to ?? null, from: dataCountInfo?.from ?? null }} />
        <Pager offset={sort.page ?? null} totalCount={listInfo?.total ?? null} onChange={(offset) => setRequest({ page: offset })} />
      </Flex>
      <Box
        h="fit-content"
        overflow="hidden"
        borderRadius="8px"
        w="100%"
        filter="drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.1))"
      >
        <Table
          {...TableModel}
          rowDbClick={onClickTableRow}
          onClickSort={(sort) => setRequest({...sort, page: 1})}
          sort={sort}
        />
      </Box>
    </Layout>
  )
}