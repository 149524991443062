import { ModalBodyProps, ModalContentProps, ModalHeaderProps } from "@chakra-ui/react";
import { action, makeObservable, observable } from "mobx";
import { ReactNode } from "react";
import { v4 as uuid} from 'uuid';
import { ButtonColor } from '../component/ui/button/button';
import { MessageDialog } from '../component/ui/dialog/dialog';

export type DialogState = {
  id?: string,
  children: ReactNode,
  title?: string,
  headerElm?: ReactNode,
  ignoreClose?: boolean,
  headerProps?: ModalHeaderProps,
  contentsPadding?: ModalContentProps['padding'],
}

type MessageDialogButtonsProps = {
  label: string,
  callback?: () => void,
  asyncCallback?: () => Promise<void>,
  color?: ButtonColor,
}

export type MessageDialogState = {
  id?: string,
  title?: string,
  messages: string[],
  headerProps?: ModalHeaderProps,
  bodyProps?: ModalBodyProps,
  contentsPadding?: ModalContentProps['padding'],
  buttons: MessageDialogButtonsProps[],
}

type PushState = Omit<DialogState, 'headerElm' | 'children'> & {
  children: ((id: string) => ReactNode) | ReactNode,
  headerElm?: ((id: string) => ReactNode) | ReactNode,
}
export class DialogStore {
  // rootStore: typeof this.rootStore;
  dialogs: DialogState[] = [];
  constructor() {
    makeObservable(this, {
      dialogs: observable,
      push: action,
      pushMessage: action,
      pop: action,
      clear: action,
    });

  }
  push(payload: PushState) {
    const id = payload.id ?? uuid();
    const _payload: DialogState = {
      ...payload,
      id,
      children: typeof payload.children === 'function'
        ? payload.children(id)
        : payload.children,
      headerElm: null,
      headerProps: payload.headerProps,
    }
    if (payload.headerElm) {
      _payload.headerElm = typeof payload.headerElm === 'function'
      ? payload.headerElm(id)
      : payload.headerElm;
    };
    if (!this.dialogs.find((v) => v.id === id)) {
      this.dialogs = [...this.dialogs, {..._payload}];
    }
  }

  pushMessage(payload: MessageDialogState) {
    const id = payload.id ?? uuid();
    // const messageDialog = MessageDialog({ ...payload });
    const _payload: DialogState = {
      id,
      title: payload.title,
      children: <MessageDialog {...payload} />,
      ignoreClose: true,
      headerProps: payload.headerProps,
      contentsPadding: payload.contentsPadding,
    }
    if (this.dialogs.find((v) => v.id === id)) {
      return;
    }
    this.dialogs = [...this.dialogs, _payload];
  }

  pop(id?: string) {
    if (!id) {
      this.dialogs.pop();
      this.dialogs = [...this.dialogs];
    } else {
      const target = this.dialogs.filter((v) => v.id !== id);
      this.dialogs = target;
    }
  }

  clear() {
    this.dialogs = [];
  }

  includesId(id: string) {
    return !!this.dialogs.find((v) => v.id === id);
  }
}