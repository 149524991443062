export const fullUrl = (origin: string, path: string) => `${origin}${path}`;

export const requireId = <T>(connection: () => T, id?: string): T => {
  if (!id) throw new Error('IDが設定されていません');
  return connection();
}

export const joinPath = (...path: (string | number)[]) => {
  return path.join('');
}

/* ファイル送る時用 */
export const json2FormForObject = <T extends {[key in string]: (Blob | File)}>(param: T): FormData => {
  const keys = Object.keys(param) as unknown as (keyof typeof param)[];
  const form = new FormData();
  for (const key of keys) {
    form.append(String(key), param[key])
  }

  return form;
}
export const json2FormForArray = <T extends {[key in string]: (Blob | File)[]}>(param: T): FormData => {
  const keys = (Object.keys(param) as unknown as (keyof typeof param)[]);
  const form = new FormData();
  for (const key of keys) {
    const dataArr = param[key];
    dataArr.forEach((file, index) => {
      form.append(`${String(key)}[${index}]`, file);
    })
  }
  return form;
}