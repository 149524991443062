import { cloneDeep } from 'lodash';
import { CageType, MergedItems } from "../model/cage.type";

export type EditableCageParam = {
  label: string,
  type: string,
  disabled: boolean,
}

export type RoomDataEntity = {
  width: number,
  isEmpty: boolean,
  label: string,
  ignoreLabel: boolean,
  editableData: EditableCageParam,
  type: string,
  xPos: { start: number, end: number },
  isNonUnitRoom: boolean,
  key: string,
  isBottom: boolean,
  isMerge: boolean,
  parentKey?: string,
  headerText: string,
}

export type BasketDataEntity = {
  height: number,
  floorLabel: string,
  rooms: RoomDataEntity[],
};

// export type XmlMergeData = {
//   DrawingItems: {
//       Item: {
//           "@_RowIndex": string;
//           "@_ColIndex": string;
//       }[];
//   };
// }[];

export type MergeRoomData = {
  pos: {
    start: number,
    end: number,
    rowIndex: number,
  }[],
  isNonUnitRoom: boolean,
  key: string,
}

export class Basket {
  // 描画項目範囲の幅　単位:pt
  floorWidth: number = 0;
  totalHeight: number = 0;

  viewData: BasketDataEntity[] = [];
  mergeRoomData: MergeRoomData[] = [];
  constructor(
    private originData: CageType['cage_drawing_data']
  ) {

  }
  exportJson() {
    return {
      residence_data_type: this.originData.residence_data_type,
      dwelling_unit_configuration_diagram: this.originData.dwelling_unit_configuration_diagram,
      extendsData: {
        floorWidth: this.floorWidth,
        totalHeight: this.totalHeight,
        mergeRoomData: this.mergeRoomData,
        viewData: this.viewData,
      }
    }
  }

  create() {
    const getCurrentItem = <T>(data: T | T[], index: number): T => {
      if (!Array.isArray(data)) {
        return data;
      } else {
        return data[index];
      }
    }
    if (this.originData.extendsData) {
      const {
        floorWidth,
        totalHeight,
        viewData,
        mergeRoomData,
      } = this.originData.extendsData;
      this.floorWidth = floorWidth;
      this.totalHeight = totalHeight;
      this.viewData = viewData;
      this.mergeRoomData  = mergeRoomData;
      return cloneDeep(this);
    }
    // 階情報作成
    this.floorWidth = Number(this.originData.dwelling_unit_configuration_diagram.DrawingPage["@attributes"].AllItemRangeWidth);
    this.totalHeight = this.originData.dwelling_unit_configuration_diagram.DrawingPage.RowItems.Item.reduce((a, b) => a + Number(b["@attributes"].Height), 0);

    // 結合部屋情報作成
    const mergeDataOrigin = (() => {
      const item = this.originData.dwelling_unit_configuration_diagram.DrawingPage?.MergedItems?.Item;
      if (!Array.isArray(item)) {
        if (item) return [item];
        return []
      };
      return item;
    })();
    this.mergeRoomData = mergeDataOrigin.map((item) => {
      const data = item.DrawingItems.Item.map((margeRoom) => {
        const col = Number(margeRoom["@attributes"].ColIndex);
        const row = Number(margeRoom["@attributes"].RowIndex);
        const posData = (() => {
          const items = this.originData.dwelling_unit_configuration_diagram.DrawingPage.RowItems.Item[row]?.DrawingItems.Item;
          const item = getCurrentItem(items, col);
          const nextItem = getCurrentItem(items, col + 1);
          return {
            start: Number(item["@attributes"].Location),
            end: nextItem?.["@attributes"].Location ? Number(nextItem["@attributes"].Location) : this.floorWidth,
            rowIndex: row,
          }
        })();
        const rowItem = this.originData.dwelling_unit_configuration_diagram.Rooms.Item.find((v) => {
          const current = getCurrentItem(v.DrawingItems.Item, 0);
          return current["@attributes"].ColIndex === `${col}` && current["@attributes"].RowIndex === `${row}`;
        });
        return {
          pos: posData,
          isNonUnitRoom: !!(!rowItem?.["@attributes"].UnitNumber && (rowItem?.["@attributes"].NonUnitRoomName)),
          key: rowItem?.["@attributes"].Key ?? '',
        };
      });

      return {
        pos: data.map((v) => v.pos),
        isNonUnitRoom: !!data.find((v) => v.isNonUnitRoom),
        key: data[0].key ?? '',
      }
    });
    // ルーム情報作成
    this.viewData = this.originData.dwelling_unit_configuration_diagram.DrawingPage.RowItems.Item.map((item, floorIndex) => {
      const floorGlobalData = {
        height: Number(item["@attributes"].Height),
      }
      const findFloor = this.originData.dwelling_unit_configuration_diagram.Floors.Item.find((v) => v["@attributes"].RowIndex === `${floorIndex}`);
      
      const floorData = {
        // floorLabel: findFloor?.["@_Text"] ?? '',
        floorLabel: findFloor?.["@attributes"].Text ?? '',
      }
      if (!Array.isArray(item.DrawingItems.Item)) {
        item.DrawingItems.Item = [item.DrawingItems.Item]
      }
      const rooms = item.DrawingItems.Item.map((roomData, roomIndex) => {
        if (!Array.isArray(item.DrawingItems.Item)) {
          item.DrawingItems.Item = [item.DrawingItems.Item]
        }
  
        const nextRoom = item.DrawingItems.Item[roomIndex + 1];
        const width = Number(nextRoom?.["@attributes"].Location ?? this.floorWidth) - Number(roomData["@attributes"].Location);
        const xPos = {
          start: Number(roomData["@attributes"].Location),
          end: Number(nextRoom?.["@attributes"].Location ?? this.floorWidth),
        }
        const rowData = this.findRoom(`${floorIndex}`, `${roomIndex}`);
        const isNonUnitRoom = !!(!rowData?.["@attributes"].UnitNumber && (rowData?.["@attributes"].NonUnitRoomName));
        // const label = isNonUnitRoom ? (() => {
        //   if (typeof roomData.Texts === 'string') {
        //     return roomData.Texts;
        //   }
        //   return roomData.Texts.Item["@_Text"];
        // })() : (rowData?.["@_UnitNumber"] ?? '');
        const label = isNonUnitRoom ? (rowData?.["@attributes"].NonUnitRoomName ?? '') : (rowData?.["@attributes"].UnitNumber ?? '');
        const type  = this.findType(rowData?.["@attributes"].UnitTypeKey ?? '');
        const key = rowData?.["@attributes"].Key ?? '';
        const parentMergeData = this.findMargeData(mergeDataOrigin, floorIndex, roomIndex)?.DrawingItems?.Item[0];
        const parentRoomData = this.originData.dwelling_unit_configuration_diagram.Rooms.Item.find((room) => {
          const current = getCurrentItem(room.DrawingItems.Item, 0);
          return (current?.["@attributes"].ColIndex === parentMergeData?.["@attributes"].ColIndex)
            && (current?.["@attributes"].RowIndex === parentMergeData?.["@attributes"].RowIndex)
        });
        const margeDataLength = this.findMargeData(mergeDataOrigin, floorIndex, roomIndex)?.DrawingItems?.Item.length ?? 0;
        const ignoreLabel = !(() => {
          if (typeof roomData.Texts === 'string') {
            return roomData.Texts;
          }
          return (roomData.Texts as any).Item?.["@attributes"].Text ?? '';
        })();
        const editableData = {
          label,
          type,
          disabled: false,
        }
        const data = {
          label,
          ignoreLabel,
          type,
          isNonUnitRoom,
          key,
          isBottom: !!(parentMergeData && (margeDataLength % 2 === 0)),
          isMerge: !!parentMergeData,
          parentKey: parentRoomData?.["@attributes"].Key ?? undefined,
        }

        return {
          width,
          xPos,
          isEmpty: roomData["@attributes"].IsEmpty === 'True',
          editableData,
          headerText: '',
          ...data,
        }
      })
      
      return {
        ...floorGlobalData,
        ...floorData,
        rooms,
      }
    });
    return cloneDeep(this);
  }

  edit(keys: string[], param: Partial<EditableCageParam>) {
    for (const key of keys) {
      const targetList = this.getViewDataRooms(key);
      for (const target of targetList) {
        target.editableData = {...target.editableData, ...param };
      }
    }
    return cloneDeep(this);
  }

  editArticleLabel(key: string, e: React.ChangeEvent<HTMLInputElement>) {
    const target = this.getViewDataRoom(key);
    if (target) target.headerText = e.target.value;
    return cloneDeep(this);
  }

  getViewDataRooms(key: string) {
    const rooms = this.viewData.map((v) => v.rooms).flat();
    return rooms.filter((v) => key === (v.parentKey ?? v.key));
  }
  getViewDataRoom(key: string) {
    const rooms = this.viewData.map((v) => v.rooms).flat();
    return rooms.find((v) => key === v.key);
  }

  private findRoom(floorIndex: string, rowIndex: string) {
    return this.originData.dwelling_unit_configuration_diagram.Rooms.Item.find((room, i) => {
      
      const item = (() => {
        if (Array.isArray(room.DrawingItems.Item)) {
          return room.DrawingItems.Item.find((v) => {
            return (v["@attributes"].ColIndex === rowIndex) && (v["@attributes"].RowIndex === floorIndex);
          })
        } else {
          return room.DrawingItems.Item;
        }
      })();
      return (item?.["@attributes"].ColIndex === rowIndex) && (item?.["@attributes"].RowIndex === floorIndex);
    });
  }

  private findType(type: string) {
    return this.originData.residence_data_type.Items.Item.find((typeRow) => {
      return typeRow["@attributes"].Key === type;
    })?.["@attributes"].Text ?? '';
  }

  private findMargeData(margeData: MergedItems['Item'], row: number, col: number) {
    return margeData.find((v) => {
      return v.DrawingItems.Item.find((v2) => {
        const colData = Number(v2["@attributes"].ColIndex);
        const rowData = Number(v2["@attributes"].RowIndex);
        return (col === colData) && (row === rowData);
      })
    })
  }

}