import { Flex, Img } from "@chakra-ui/react"
import template1 from '../../../../../assets/template/template_01.png';
import template1_none from '../../../../../assets/template/image38.jpg';
import { useMemo } from "react";

type Props = {
  isNone?: boolean,
  isSite?: boolean,
  display?: boolean,
  src?: string,
}

export const EcoLabelTemplate = (props: Props) => {
  const siteStyle = useMemo(() => {
    if (props.isSite) {
      return {
        w: '538px',
        h: 'initial'
      }
    }
    return {};
  }, [props.isSite]);

  const src = useMemo(() => {
    if (!props.isSite) {
      return !props.isNone ? template1 : template1_none;
    }
    return props.src ?? '';
  }, [props]);
  return (
    <Flex
      w="300px"
      display={props.display ? 'inline-flex' : 'none'}
      pos="relative"
      h="225px"
      {...siteStyle}
    >
      <Img
        w="300px"
        src={src}
        display={props.isNone ? 'none' : 'block'}
        {...siteStyle}
      />
      <Img
        w="300px"
        src={src}
        display={!props.isNone ? 'none' : 'block'}
        {...siteStyle}
      />
    </Flex>
  )
}