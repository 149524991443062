import API from "../../..";
import { fullUrl, json2FormForArray } from '../../../utile/utile.api';

export type EnergySavingLabelsResponseDataEntity = {
  id: string,
  file_name: string,
}

export type EnergySavingLabelsGetResponse = {
  energy_saving_labels: EnergySavingLabelsResponseDataEntity[],
  total: number,
}

type EnergySavingLabelsPostRequestType = {
  files: File[],
}

type EnergySavingLabelsDeleteRequestType = {
  label_ids: string[],
}

const path = '/energy-saving-labels';

const energySavingLabels = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    get: () => API.con.get<{}, EnergySavingLabelsGetResponse>({ url: full, data: {} }),
    post: (param: EnergySavingLabelsPostRequestType) => API.con.post<FormData, {}>({ url: full, data:  json2FormForArray(param)}),
    delete: (
      param: EnergySavingLabelsDeleteRequestType,
    ) => API.con.delete<EnergySavingLabelsDeleteRequestType, {}>({
      url: full,
      data: param,
    }),
  })
}

export default energySavingLabels;
