import { InputProps, Input as ChakraInput, FormControl, Box } from "@chakra-ui/react"
import { useCallback, MouseEvent, useState, useMemo, useEffect, useRef } from 'react';
import { COLOR } from "../../../styles/const";
import { Tooltip } from '@mui/material';

type Props = {
  validations?: string[],
} & InputProps;

export const Input = (props: Props) => {
  const {
    validations,
    onMouseOver,
    onMouseLeave,
    onFocus,
    onBlur,
    onChange,
    ...defaultProps
  } = props;
  const inputCnt = useRef<HTMLInputElement>(null);
  const [init, setInit] = useState(false);
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inViewport, setInViewPort] = useState(false);
  const viewValidation = useMemo(() => {
    if (!init || !validations?.length || !inViewport) return false;
    return hover || focus;
  }, [init, focus, hover, inViewport, validations]);
  const focusVisible = useMemo(() => {
    if (validations?.length && init) {
      return {
        borderColor: COLOR.bg.warning,
        boxShadow: `0 0 0 1px ${COLOR.bg.warning}`,
      }
    } else {
      return undefined
    }
  }, [validations, init]);

  const _onMouseOver = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    onMouseOver?.(e);
    setHover(true);
  }, [onMouseOver]);
  const _onMouseLeave = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    onMouseOver?.(e);
    setHover(false);
  }, [onMouseLeave]);

  const _onFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(e);
    setFocus(true);
  }, [onFocus]);
  const _onBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setInit(true);
    onBlur?.(e);
    setFocus(false);
  }, [onBlur]);

  const _onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInit(true);
    onChange?.(e);
  }, [onChange]);

  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      if (e.some((v) => v.isIntersecting)) {
        setInViewPort(true);
      } else {
        setInViewPort(false);
      }
    });
    if (inputCnt.current) {
      observer.observe(inputCnt.current);
    }
    return (() => {
      observer.disconnect();
    })
  }, [inputCnt.current]);
  return (
    <FormControl isInvalid={!!focusVisible} pos="relative">
      <Tooltip
        arrow
        title={(
          <>
            {validations?.map((text, i) => (
              <Box key={`validation_label_${i}`}>{text}</Box>
            ))}
          </>

        )}
        placement="top"
        open={viewValidation}
        // open
        componentsProps={{
          tooltip: {
            sx: {
              background: '#fff',
              border: '1px solid',
              borderColor: COLOR.bg.warning,
              color: COLOR.bg.warning,
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          arrow: {
            sx: {
              ':before': {
                background: '#fff',
                border: '1px solid',
                borderColor: COLOR.bg.warning,
              },
            },
          },
        }}
      >
        <ChakraInput
          {...defaultProps}
          ref={inputCnt}
          _disabled={{
            bg: 'blackAlpha.200',
          }}
          value={defaultProps.value ?? ''}
          autoFocus={false}
          _focusVisible={focusVisible}
          onMouseOver={_onMouseOver}
          onMouseLeave={_onMouseLeave}
          onFocus={_onFocus}
          onBlur={_onBlur}
          onChange={_onChange}
        />
        </Tooltip>
      {/* </Tooltip> */}
    </FormControl>
  )
}