import { useDidMount } from "../../../../hooks/utile/use-didmount";
import { Api } from "../../../../module/api";
import { useApiErrorDialog } from '../../../../hooks/utile/use-error-dialog';
import RootStore from '../../../../store/root.store';
import { useState } from "react";
import { CompanyDetailDataEntity } from "../../../../module/api/routes/companies";

type Props = {
  id: string,
}

export const useCompanyDetail = (props: Props) => {
  const { id } = props;
  const { dialog } = RootStore;
  const errDialog = useApiErrorDialog();
  const [info, setInfo] = useState<null | CompanyDetailDataEntity>(null);
  useDidMount(() => {
    Api.connect().companies(id).getDetail().then((res) => {
      setInfo(res.data.company);
    }).catch((e) => errDialog.push(e, {
      callback: () => dialog.clear(),
    }));
  });
  return {
    info,
  }
}