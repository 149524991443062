import { Box, Center, Divider, Flex, Text, VStack } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { Layout } from "../../../layout/layout"
import { MspLayout } from "../../../layout/msp-layout"
import { CustomButton } from "../../../ui/button/button"
import { Select } from "../../../ui/select/select"
import { TemplateSelect } from "./component/template-select"
import { useMspTemplates } from "./hooks/use-msp-templates"
import { TemplateList } from "./model/template.collection"

export const MspTemplate = observer(() => {
  const {
    templateParam,
    setRequest,
    selectTemplate, setSelectTemplate,
    onClickSaveData,
  } = useMspTemplates();

  return (
    <Layout isMsp
      bodyProps={{
        alignItems: 'start',
      }}
    >
      <MspLayout>
        <Flex
          flexDirection="column"
          overflow="hidden"
          maxW="100%"
          pl="2px"
        >
          <VStack
            // h="100%"
            width="1305px"
            maxW="100%"
            bg="#fff"
            overflow="auto"
            borderRadius="8px"
            filter="drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.1))"
            p="1.5rem 2rem"
            gap="3rem"
          >
            <Box w="100%">
              <Text fontSize="lg" fontWeight="bold">テンプレートを選択してください</Text>
              <Divider borderColor="gray.300" my="1rem" />
              <Select
                value={selectTemplate}
                onChange={setSelectTemplate}
                w="14rem"
                minW="fit-content"
                option={TemplateList}
              />
            </Box>
            <Box w="100%">
              <Text fontSize="lg" fontWeight="bold">表示するラベルを選択してください</Text>
              <Divider borderColor="gray.300" my="1rem" />
              <TemplateSelect
                param={templateParam}
                setRequest={setRequest}
                selectTemplate={selectTemplate}
              />
            </Box>
          </VStack>
          <Center w="100%" mt="1rem">
            <CustomButton
              onClick={onClickSaveData}
            >設定反映</CustomButton>
          </Center>
        </Flex>
      </MspLayout>
    </Layout>
  )
});