import { RadioGroupProps, Radio as ChakraRadio, RadioGroup } from "@chakra-ui/react"
import { useCallback } from "react";
import { SelectOptionData } from "../select/select";

type Props<T extends (string | number)> = {
  onChange?: (value: T) => void,
  option?: SelectOptionData<T>[],
  value: T,
} & Omit<RadioGroupProps, 'children' | 'value' | 'onChange'>;

export function Radio<T extends (string | number)>(
  props: Props<T>
) {
  const {
    onChange,
    option,
    value,
    ...defaultProps
  } = props;
  const onClick = useCallback((selectValue: string) => {
    if (!option) return;
    const target = option.find((v) => `${v.value}` === selectValue);
    if (!target) {
      throw new Error('不正な値です。');
    } else {
      onChange?.(target.value);
    }
  }, [option, onChange]);
  return (
    <RadioGroup
      display="flex"
      gap="1rem"
      id="gomi"
      value={`${value}`}
      onChange={(v) => onClick(v)}
      css={{
        '>label': {
          '>span': {
            '&.chakra-radio__control': {
              width: '24px',
              height: '24px',
              '&[data-checked]': {
                ':before': {
                  width: '70%',
                  height: '70%',
                }
              }
            }
          }
        }
      }}
      {...defaultProps}
    >
      {(option ?? []).map((data) => (
        <ChakraRadio
          value={`${data.value}`}
          key={`radio_${data.value}`}
          size="lg"
          css={{
            '>span': {
              '&__control': {
                borderColor: 'red',
              }
            }
          }}
        >
          {data.label}
        </ChakraRadio>
      ))}
    </RadioGroup>
  )
}
