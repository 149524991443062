import { Box, Center, Flex, Grid, GridItem, Img, Text } from '@chakra-ui/react';
import treeImg from '../../../../../assets/template/co2-tree-img.png';
import { SiteTemplateProps } from '../../component/comparison-template/comparison-template';

export const ComparisonCo2 = (props: SiteTemplateProps) => {
  const {
    param,
    data,
  } = props;
  if (!param?.co2_reduction_rate) return <></>;
  return (
    <Box bg="#fff" p="0.5rem 1rem" w="100%" pb="2rem">
      <Box lineHeight="1.5rem" fontSize="large" fontWeight="bold">
        CO2削減効果
      </Box>
      <Grid
        gridTemplateColumns={'40% 60%'}
        mt="1rem"
      >
        <GridItem>
          <Center>
            <Img src={treeImg} alt="tree.png" />
          </Center>
        </GridItem>
        <GridItem>
          <Center h="100%">
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              border="2px solid"
              borderRadius="100vh"
              color="#62AF57"
              fontWeight="bold"
              w="95%"
              py="1rem"
              lineHeight="1"
              gap="0.5rem"
            >
              <Box fontSize="3xl">杉の木</Box>
              <Box color="#2b2b2b">省エネ基準に比べてひと月</Box>
              <Box>
                <Text as="span" fontSize="5xl">{data?.count ?? ''}本</Text>
                <Text as="span" fontSize="3xl">分</Text>
              </Box>
              <Box color="#2b2b2b">(年間{data?.kg?.toLocaleString() ?? ''}kgの削減)</Box>
            </Flex>
          </Center>
        </GridItem>
      </Grid>
    </Box>
  )
}