import API from "../../..";
import { fullUrl } from "../../../utile/utile.api";
import { CompaniesHeaderItemDataEntity } from "../items";

type CompaniesHeaderItemsResponse = {
  display_items: CompaniesHeaderItemDataEntity[],
}

export type CompaniesHeaderItemsRequestDataEntity = {
  id: string,
  order: number,
}

type CompaniesHeaderItemsRequestData = {
  display_items: CompaniesHeaderItemsRequestDataEntity[],
}

const path = '/display-items';

const displayItems = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    /** 企業表示項目一覧取得 */
    get: () => API.con.get<{}, CompaniesHeaderItemsResponse>({ url: full, data: {} }),
    /** 企業表示項目一覧更新 */
    put: (param: CompaniesHeaderItemsRequestData) => API.con.put<CompaniesHeaderItemsRequestData, {}>({ url: full, data: param }),
  })
}

export default displayItems;