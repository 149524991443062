import { CloseIcon } from "@chakra-ui/icons";
import { Box, Center, Flex, Modal, ModalBody, ModalBodyProps, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { observer } from 'mobx-react-lite';
import { ReactNode, useCallback, useState } from "react";
import { DialogState, MessageDialogState } from '../../../store/dialog.store';
import RootStore from '../../../store/root.store';
import { CustomButton } from "../button/button";

type Props = {
  children?: ReactNode,
  footer?: ReactNode,
  w?: string,
  maxW?: string,
  bodyProps?: ModalBodyProps,
}
export const DialogBody = (props: Props) => {
  const {
    children,
    footer,
    bodyProps,
  } = props;
  return (
    <>
      <ModalBody px="2.5rem" pb={!footer ? '24px' : undefined} overflow="auto" {...bodyProps} autoFocus={false}>
        {children}
      </ModalBody>
      {!!footer && (
        <ModalFooter>
          <Flex
            w="100%"
            justifyContent="center"
            gap="1rem"
            borderTop="1px solid"
            borderColor="gray.200"  
            pt="1rem"
          >
            {footer}
          </Flex>
        </ModalFooter>
      )}
    </>
  )

}
export const Dialog = observer((props: DialogState) => {
  const {
    title,
    children,
    id,
    headerElm,
    headerProps,
    ignoreClose,
    contentsPadding = '0',
  } = props;
  const onClose = useCallback(() => {
    RootStore.dialog.pop(id);
  }, [id]);
  return (
    <Modal
      isOpen
      isCentered
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        maxH="100vh"
        w="fit-content"
        maxW="80%"
        minW="200px"
        autoFocus={false}
        p={contentsPadding}
      >
        {!!title && (
          <ModalHeader {...headerProps}>
            <Box
              borderBottom="1px solid"
              borderColor="gray.200"
              pb="0.5rem"
            >
              <Flex w="100%" justifyContent="space-between" className="modal_header_wrapper">
                <Box>
                  {title}
                </Box>
                <Flex gap="1rem">
                  {headerElm}
                  {!ignoreClose && <Flex alignItems="center">
                    <CloseIcon
                      cursor="pointer"
                      onClick={onClose}
                    />
                  </Flex>}
                </Flex>
              </Flex>
            </Box>
          </ModalHeader>
        )}
        {children}
      </ModalContent>
    </Modal>
  );
});

export const MessageDialog = (props: MessageDialogState) => {
  const {
    bodyProps,
    messages,
    buttons,
  } = props;
  const [load, setLoad] = useState(false);

  const onClickButton = useCallback((
    callback?: () => void,
    asyncCallback?: () => Promise<any>,
  ) => {
    setLoad(true);
    if (asyncCallback) {
      asyncCallback?.().finally(() => {
        callback?.();
        setLoad(false);
      });
    } else {
      callback?.();
    }
  }, []);
  return (
    <DialogBody
      bodyProps={{
        py: '2rem',
        px: '5rem',
        ...bodyProps,
      }}
      footer={(
        <>
          {buttons.map((button, i) => (
            <CustomButton
              key={`message_button_${i}`}
              onClick={() => onClickButton(button.callback, button.asyncCallback)}
              colorType={button.color}
              isDisabled={load}
            >
              {button.label}
            </CustomButton>
          ))}
        </>
      )}
    >
      <Center flexDirection="column" gap="0.75rem">
        { messages.map((message, i) => (
          <Box key={`message_${i}`}>{message}</Box>
        )) }
      </Center>
    </DialogBody>
  )
}
