/* 色定義 */
export const COLOR = {
  bg: {
    primary: 'gray.50',
    sub: '#fff',
    theme: 'blue.500',
    warning: '#E53E3E',
    link: 'blue.600'
  },
  border: {
    sideBar: '#CBD5E0',
  },
}
// ヘッダーの高さ指定
export const HEADER_HEIGHT = '48px';
// side barの幅指定
export const SIDEBAR_WIDTH = '200px';
// ドロップシャドウ
export const DROP_SHADOW = 'drop-shadow(0 2px 1px rgba(0, 0, 0, 0.25))';