import { InputProps, ButtonProps } from '@chakra-ui/react';
import { ChangeEvent, useCallback, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { RoutingPath } from '../../../../routes/routing-path';
import { Api, saveTokenToLocalStorage, getSessionTokenFromLocalStorage, deleteToken } from '../../../../module/api/index';
import RootStore from '../../../../store/root.store';
import { useApiErrorDialog } from '../../../../hooks/utile/use-error-dialog';
import { useDidMount } from '../../../../hooks/utile/use-didmount';

type ReturnType = {
  idData: InputProps,
  passData: InputProps,
  loginButton: ButtonProps,
}

export const useLogin = (): ReturnType => {
  const navigate = useNavigate();
  const errDialog = useApiErrorDialog();
  const [load, setLoad] = useState(false);

  const [id, setId] = useState('');
  const onChangeId = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value);
  }, []);

  const [password, setPassword] = useState('');
  const onChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const onClickLogin = useCallback(async() => {
    // ログイン処理
    // 画面遷移 企業かISPかで判断して遷移先を変更
    setLoad(true);
    try {
      const loginResponse = await Api.connect().login().post({ login_id: id, password });
      const { token } = loginResponse.data;
      saveTokenToLocalStorage(token);
      const isLoginResponse = await Api.connect().isLogin().get();
      RootStore.user.setUser(isLoginResponse.data);
      if (isLoginResponse.data.company.system_admin_flag) {
        //ISPログインの時
        navigate(RoutingPath.companies);
      } else {
        //企業ログインの時
        navigate(RoutingPath.articles);
      }
    } catch(e: any) {
      errDialog.push(e, {
        customMessage: ['メールアドレスもしくはパスワードが一致しません'],
      });
      setLoad(false);
    }
  }, [id, password, errDialog, navigate]);

  // 自動ログイン処理
  useDidMount(async() => {
    const token = getSessionTokenFromLocalStorage();
    try {
      if (token) {
        const isLoginResponse = await Api.connect().isLogin().get();
        RootStore.user.setUser(isLoginResponse.data);
        await RootStore.common.initialize();
        if (isLoginResponse.data.company.system_admin_flag) {
          //ISPログインの時
          navigate(RoutingPath.companies);
        } else {
          //企業ログインの時
          navigate(RoutingPath.articles);
        }
      }
    } catch(e) {
      deleteToken();
    }
  })

  return {
    idData: {
      value: id,
      onChange: onChangeId,
      isDisabled: load,
      placeholder: 'ID',
    },
    passData: {
      value: password,
      onChange: onChangePassword,
      type: 'password',
      isDisabled: load,
      placeholder: 'パスワード',
      onKeyDown: (e) => (e.key === 'Enter') && onClickLogin(),
    },
    loginButton: {
      onClick: onClickLogin,
      isDisabled: load || (!id || !password),
      isLoading: load,
      onKeyDown: (e) => {
      }
    }
  }
}