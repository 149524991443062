import { Box, Button, Flex } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { useCallback, useMemo } from "react";
import { GetSiteStoreData } from "../../../@types/global";
import { BASE_FONT } from "../../../model/font";
import { SiteRoutingPath } from "../../../routes/routing-path";
import RootStore from "../../../store/root.store";
import { Loading } from "../../ui/loading/loading";
import { px2pt } from "../msp/basket/component/cage/cage";
import { SVGRoomArea } from "../msp/basket/component/cage/svg-text-area";
import { Basket, MergeRoomData, RoomDataEntity } from "../msp/basket/module/basket";
import { useSiteCage } from "./hooks/use-site-cage";

export const SiteCage = observer(() => {
  const {
    cageData,
  } = RootStore.site;
  const model = useMemo(() => {
    if (!cageData?.cage_drawing_data) return null;
    const _model = new Basket(cageData.cage_drawing_data);
    _model.create();
    return _model;
  }, [cageData]);
  const {
    onSelectRoom,
    roomStyle,
    mergeRoomStyle,
    selectedRooms,
  } = useSiteCage({ model });

  const createY = useCallback((index: number) => {
    if (!model) return 0;
    const filter = model.viewData.filter((v, i) => i < index);
    return px2pt(filter.reduce((a, b) => a + b.height, 0));
  }, [model]);

  const createMergePath = useCallback((data: MergeRoomData) => {
    const pathStart = data.pos.map((pos, i) => {
      const firstIndex = !pos.rowIndex ? 0 : pos.rowIndex;
      if (!i) return `${px2pt(pos.start)},${createY(firstIndex)} L${px2pt(pos.end)},${createY(pos.rowIndex)} L${px2pt(pos.end)},${createY(pos.rowIndex + 1)}`;
      const middlePath = ` L${px2pt(pos.end)},${createY(pos.rowIndex)} L${px2pt(pos.end)},${createY(pos.rowIndex + 1)}`;
      if (i === (data.pos.length - 1)) return `${middlePath} L${px2pt(pos.start)},${createY(pos.rowIndex + 1)} L${px2pt(pos.start)},${createY(pos.rowIndex)}`;
      return middlePath;
    });
    const pathEnd = [...data.pos].reverse().map((pos, i) => {
      if (i === (data.pos.length - 1)) return ' Z';
      if (!i) return '';
      return ` L${px2pt(pos.start)},${createY(pos.rowIndex)} L${px2pt(pos.start)},${createY(pos.rowIndex)}`;
    });
    return `M${pathStart.join('')}${pathEnd.join('')}`;
  }, [createY]);

  const createRectPath = useCallback((room: RoomDataEntity, _height: number) => {
    const width = px2pt(room.width);
    const height = px2pt(_height);
    return `M 0,0 L${width},0 L${width},${height} L0,${height} Z`
  }, []);

  const textColor = useMemo(() => {
    return RootStore.msp.colors?.cage_drawing_text_color ?? '#000';
  }, [RootStore.msp]);

  const onClickView = useCallback(() => {
    if (!model) return;
    const selectRoomNumbers = selectedRooms.map((v) => model.getViewDataRoom(v)?.label ?? '')
    const query = encodeURIComponent(selectRoomNumbers.filter((v) => v).join(','));
    window.setTimeout(() => {
      window.open(`/#${SiteRoutingPath.comparison}?k=${query}`);
    })
  }, [selectedRooms]);
  if (!model) return <Loading />;
  return (
    <>
      <Flex
        w="100%"
        h="100%"
        bg="#fff"
        fontFamily={BASE_FONT}
        alignItems="center"
        className="wrapper"
      >
        <Flex
          flexDirection="column"
          h="100vh"
          px="2%"
          maxH="100%"
          mx="auto"
          justifyContent="center"
          bg="#fff"
          className="container"
        >
          <Box
            className="main-cnt-wrapper"
            overflowY="auto"
          >
            <Flex
              pl="60px"
              pt="calc(48px + 2rem)"
              whiteSpace="nowrap"
              mb="0.25rem"
              color="rgb(11, 70, 114)"
              overflow="visible"
            >
              {model.viewData[0]?.rooms.map((room, i) => (
                <Box
                  key={`room_title_${room.key}`}
                  fontSize="3xl"
                  pl="2pt"
                  overflow="visible"
                >
                  <Flex
                    w={`${room.width - 2}pt`}
                    justifyContent="center"

                  >
                    {room.headerText}
                  </Flex>
                </Box>
              ))}
            </Flex>
            <svg
              width={`${model.floorWidth + 62}pt`}
              height={`${model.totalHeight + 2}pt`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                transform="translate(0 1)"
              >
                {model.mergeRoomData.map((mergeData, i) => (
                  <g key={`merge_${i}`} transform="translate(60, 0)">
                    <path
                      d={createMergePath(mergeData)}
                      stroke="#F7FAFC"
                      strokeWidth="4"
                      {...mergeRoomStyle(mergeData)}
                    />
                  </g>
                ))}
                {model.viewData.map((floor, i) => (
                  <g
                    height={px2pt(floor.height)}
                    transform={`translate(0, ${createY(i)})`}
                    key={`g_floor_${i}`}
                  >
                    <g transform={`translate(0, ${px2pt(floor.height) - 31})`}>
                      <rect
                        width="50px"
                        height="30px"
                        fill="#E2E8F0"
                        stroke="#E2E8F0"
                      />
                      <text
                        fontSize="14"
                        x="25"
                        y="15"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{ pointerEvents: 'none', userSelect: 'none' }}
                      >
                        {floor.floorLabel}
                      </text>
                    </g>
                    <g transform="translate(60, 0)">
                      {floor.rooms.map((room, _i) => (
                        <g key={`room_${i}_${_i}`} transform={`translate(${px2pt(room.xPos.start)}, 0)`} id={`${i}_${_i}`}>
                          {!room.isEmpty && (
                            <SVGRoomArea
                              roomData={room}
                              onSelectRoom={onSelectRoom}
                              rectPath={createRectPath(room, floor.height)}
                              roomStyle={roomStyle}
                              height={floor.height}
                              model={model}
                              ignoreSelect={room.isNonUnitRoom || room.editableData.disabled}
                              textColor={textColor}
                            />
                          )}
                        </g>
                      ))}
                    </g>
                  </g>
                ))}
              </g>
            </svg>
          </Box>
          <Flex
            h="100px"
            mb="4px"
            pt="8px"
            justifyContent="center"
            w="100%"
          >
            <Flex
              width={`${model.floorWidth + 28}pt`}
              justifyContent="flex-end"  
            >
              <Button
                w="9rem"
                bg="rgb(55, 101, 147)"
                color="#fff"
                mt="1rem"
                px="2rem"
                isDisabled={!selectedRooms.length}
                onClick={() => onClickView()}
              >
                省エネ表示
              </Button>
            </Flex>
          </Flex>
          <Flex
            mt="0.5rem"
            justifyContent="center"
            minH="fit-content"
            pb="2rem"
            fontSize="md"
          >
            <Box>
              {(RootStore.site.cageData?.cage_drawing_comment ?? '').split('\n').map((v, i) => (
                <React.Fragment key={`preview_comment_${i}`}>
                  {!!i && <br /> }
                  {v}
                </React.Fragment>
              ))}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
});